var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./medley.core.js");require("./metabase.lib.common.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.equality.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.aggregation.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var k4,f7,g7,h7;k4=function(a,b,c){var d=$CLJS.W0(a,b);d=$CLJS.Uf(d);d=$CLJS.M.h(d,$CLJS.hQ);d=$CLJS.DW($CLJS.Um.j($CLJS.vi([c]),$CLJS.MH,$CLJS.$d),d);if(!$CLJS.m(d))throw $CLJS.Ej($CLJS.cI("No aggregation with uuid {0}",$CLJS.G([c])),new $CLJS.k(null,3,[$CLJS.Cu,c,$CLJS.QO,a,$CLJS.H0,b],null));return d};f7=new $CLJS.N("metabase.lib.aggregation","unary-aggregation","metabase.lib.aggregation/unary-aggregation",1064133592);
g7=new $CLJS.N("metabase.lib.aggregation","count-aggregation","metabase.lib.aggregation/count-aggregation",-21314018);h7=new $CLJS.N("metabase.lib.aggregation","aggregation","metabase.lib.aggregation/aggregation",1701573815);$CLJS.cra=new $CLJS.N(null,"aggregation-operator","aggregation-operator",-1978700834);$CLJS.B1.o(null,$CLJS.hQ,function(a,b){var c=$CLJS.Vf($CLJS.hQ.g($CLJS.W0(a,b)));return $CLJS.m(c)?$CLJS.e2($CLJS.dI("and"),function(){return function f(e){return new $CLJS.Cf(null,function(){for(;;){var g=$CLJS.A(e);if(g){if($CLJS.re(g)){var l=$CLJS.$c(g),n=$CLJS.E(l),q=$CLJS.Ff(n);a:for(var u=0;;)if(u<n){var v=$CLJS.ce(l,u);v=$CLJS.c1.F(a,b,v,$CLJS.e4);q.add(v);u+=1}else{l=!0;break a}return l?$CLJS.If($CLJS.Kf(q),f($CLJS.ad(g))):$CLJS.If($CLJS.Kf(q),null)}q=$CLJS.C(g);return $CLJS.pf($CLJS.c1.F(a,
b,q,$CLJS.e4),f($CLJS.Ad(g)))}return null}},null,null)}(c)}()):null});$CLJS.a1.o(null,$CLJS.hQ,function(a,b,c){$CLJS.J(c,0,null);var d=$CLJS.J(c,1,null),e=$CLJS.Uf(d);d=$CLJS.M.h(e,$CLJS.bH);e=$CLJS.M.h(e,$CLJS.rk);c=$CLJS.J(c,2,null);c=k4(a,b,c);return $CLJS.pn.l($CLJS.G([$CLJS.b1(a,b,c),new $CLJS.k(null,2,[$CLJS.E0,$CLJS.c4,$CLJS.z4,$CLJS.MH.g($CLJS.$d(c))],null),$CLJS.m(d)?new $CLJS.k(null,1,[$CLJS.bH,d],null):null,$CLJS.m(e)?new $CLJS.k(null,1,[$CLJS.rk,e],null):null]))});
$CLJS.y1.o(null,$CLJS.hQ,function(a,b,c,d){$CLJS.J(c,0,null);$CLJS.J(c,1,null);c=$CLJS.J(c,2,null);return $CLJS.c1.F(a,b,k4(a,b,c),d)});$CLJS.tV(g7,h7);
for(var i7=$CLJS.A(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.VD,$CLJS.hK],null)),j7=null,k7=0,l7=0;;)if(l7<k7){var dra=j7.$(null,l7);$CLJS.tV(dra,g7);l7+=1}else{var n7=$CLJS.A(i7);if(n7){var o7=n7;if($CLJS.re(o7)){var era=$CLJS.$c(o7),fra=$CLJS.ad(o7),gra=era,hra=$CLJS.E(era);i7=fra;j7=gra;k7=hra}else{var lra=$CLJS.C(o7);$CLJS.tV(lra,g7);i7=$CLJS.D(o7);j7=null;k7=0}l7=0}else break}
$CLJS.y1.o(null,g7,function(a,b,c,d){var e=$CLJS.J(c,0,null);$CLJS.J(c,1,null);c=$CLJS.J(c,2,null);if($CLJS.m(c))switch(a=$CLJS.c1.F(a,b,c,d),e=e instanceof $CLJS.N?e.S:null,e){case "count":return $CLJS.cI("Count of {0}",$CLJS.G([a]));case "cum-count":return $CLJS.cI("Cumulative count of {0}",$CLJS.G([a]));default:throw Error(["No matching clause: ",$CLJS.p.g(e)].join(""));}else switch(e=e instanceof $CLJS.N?e.S:null,e){case "count":return $CLJS.dI("Count");case "cum-count":return $CLJS.dI("Cumulative count");
default:throw Error(["No matching clause: ",$CLJS.p.g(e)].join(""));}});$CLJS.z1.o(null,g7,function(a,b,c){a=$CLJS.J(c,0,null);a=a instanceof $CLJS.N?a.S:null;switch(a){case "count":return"count";case "cum-count":return"cum_count";default:throw Error(["No matching clause: ",$CLJS.p.g(a)].join(""));}});$CLJS.a1.o(null,g7,function(a,b,c){var d=$CLJS.U.j,e=$CLJS.yj($CLJS.a1,h7);a=e.j?e.j(a,b,c):e.call(null,a,b,c);return d.call($CLJS.U,a,$CLJS.Ol,$CLJS.nJ)});$CLJS.y1.o(null,$CLJS.uJ,function(){return $CLJS.dI("Case")});
$CLJS.z1.o(null,$CLJS.uJ,function(){return"case"});$CLJS.tV(f7,h7);for(var p7=$CLJS.A(new $CLJS.S(null,9,5,$CLJS.T,[$CLJS.YJ,$CLJS.AL,$CLJS.hJ,$CLJS.em,$CLJS.QK,$CLJS.wk,$CLJS.VK,$CLJS.OK,$CLJS.lJ],null)),q7=null,r7=0,s7=0;;)if(s7<r7){var nra=q7.$(null,s7);$CLJS.tV(nra,f7);s7+=1}else{var rra=$CLJS.A(p7);if(rra){var t7=rra;if($CLJS.re(t7)){var sra=$CLJS.$c(t7),tra=$CLJS.ad(t7),ura=sra,vra=$CLJS.E(sra);p7=tra;q7=ura;r7=vra}else{var xra=$CLJS.C(t7);$CLJS.tV(xra,f7);p7=$CLJS.D(t7);q7=null;r7=0}s7=0}else break}
$CLJS.z1.o(null,f7,function(a,b,c){a=$CLJS.J(c,0,null);$CLJS.J(c,1,null);$CLJS.J(c,2,null);c=a instanceof $CLJS.N?a.S:null;switch(c){case "avg":return"avg";case "cum-sum":return"sum";case "distinct":return"count";case "max":return"max";case "median":return"median";case "min":return"min";case "stddev":return"stddev";case "sum":return"sum";case "var":return"var";default:throw Error(["No matching clause: ",$CLJS.p.g(c)].join(""));}});
$CLJS.y1.o(null,f7,function(a,b,c,d){var e=$CLJS.J(c,0,null);$CLJS.J(c,1,null);c=$CLJS.J(c,2,null);a=$CLJS.c1.F(a,b,c,d);e=e instanceof $CLJS.N?e.S:null;switch(e){case "avg":return $CLJS.cI("Average of {0}",$CLJS.G([a]));case "cum-sum":return $CLJS.cI("Cumulative sum of {0}",$CLJS.G([a]));case "distinct":return $CLJS.cI("Distinct values of {0}",$CLJS.G([a]));case "max":return $CLJS.cI("Max of {0}",$CLJS.G([a]));case "median":return $CLJS.cI("Median of {0}",$CLJS.G([a]));case "min":return $CLJS.cI("Min of {0}",
$CLJS.G([a]));case "stddev":return $CLJS.cI("Standard deviation of {0}",$CLJS.G([a]));case "sum":return $CLJS.cI("Sum of {0}",$CLJS.G([a]));case "var":return $CLJS.cI("Variance of {0}",$CLJS.G([a]));default:throw Error(["No matching clause: ",$CLJS.p.g(e)].join(""));}});$CLJS.y1.o(null,$CLJS.IJ,function(a,b,c,d){$CLJS.J(c,0,null);$CLJS.J(c,1,null);var e=$CLJS.J(c,2,null);c=$CLJS.J(c,3,null);return $CLJS.cI("{0}th percentile of {1}",$CLJS.G([c,$CLJS.c1.F(a,b,e,d)]))});$CLJS.z1.o(null,$CLJS.IJ,function(){return"percentile"});
$CLJS.tV($CLJS.IJ,h7);$CLJS.y1.o(null,$CLJS.xL,function(a,b,c,d){$CLJS.J(c,0,null);$CLJS.J(c,1,null);var e=$CLJS.J(c,2,null);$CLJS.J(c,3,null);return $CLJS.cI("Sum of {0} matching condition",$CLJS.G([$CLJS.c1.F(a,b,e,d)]))});$CLJS.z1.o(null,$CLJS.xL,function(a,b,c){$CLJS.J(c,0,null);$CLJS.J(c,1,null);var d=$CLJS.J(c,2,null);$CLJS.J(c,3,null);return["sum_where_",$CLJS.p.g($CLJS.A1.j(a,b,d))].join("")});$CLJS.tV($CLJS.xL,h7);$CLJS.y1.o(null,$CLJS.tK,function(){return $CLJS.dI("Share of rows matching condition")});
$CLJS.z1.o(null,$CLJS.tK,function(){return"share"});$CLJS.tV($CLJS.tK,h7);$CLJS.y1.o(null,$CLJS.AK,function(){return $CLJS.dI("Count of rows matching condition")});$CLJS.z1.o(null,$CLJS.AK,function(){return"count-where"});$CLJS.tV($CLJS.AK,h7);
$CLJS.a1.o(null,h7,function(a,b,c){$CLJS.J(c,0,null);$CLJS.J(c,1,null);var d=$CLJS.J(c,2,null),e=$CLJS.pn.l;d=$CLJS.m(d)?$CLJS.ri($CLJS.b1(a,b,d),new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.HR],null)):null;var f=$CLJS.yj($CLJS.a1,$CLJS.Uj);a=f.j?f.j(a,b,c):f.call(null,a,b,c);return e.call($CLJS.pn,$CLJS.G([d,a]))});$CLJS.jW.o(null,$CLJS.hQ,function(a){return a});$CLJS.y1.o(null,$CLJS.OW,function(a,b,c){a=$CLJS.Uf(c);a=$CLJS.M.h(a,$CLJS.RW);return $CLJS.oJ.g(a.A?a.A():a.call(null))});
$CLJS.E1.o(null,$CLJS.OW,function(a,b,c){var d=$CLJS.Uf(c);a=$CLJS.M.h(d,$CLJS.iW);b=$CLJS.M.h(d,$CLJS.RW);c=$CLJS.M.h(d,$CLJS.PW);d=$CLJS.M.h(d,$CLJS.M3);a=$CLJS.U.l(b.A?b.A():b.call(null),$CLJS.y4,$CLJS.oG(a),$CLJS.G([$CLJS.x4,c]));return null!=d?$CLJS.U.j(a,$CLJS.n4,d):a});