var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./clojure.walk.js");require("./medley.core.js");require("./metabase.mbql.util.js");require("./metabase.mbql.util.match.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.log.js");
'use strict';var Bra,n6,k_,o6,m6,p6,Cra,Dra,Era,s6,t6,v6,w6,x6,y6,z6,A6,l6,Gra,Hra,D6,Ira,E6,Jra,G6,Kra,Lra,Mra,Nra,Ora;Bra=function(a){var b=l6;return $CLJS.jG(function(c){return function(d,e,f){f=b.g?b.g(f):b.call(null,f);return c.j?c.j(d,e,f):c.call(null,d,e,f)}},a)};n6=function(a){var b=m6;return $CLJS.jG(function(c){return function(d,e,f){e=b.g?b.g(e):b.call(null,e);return c.j?c.j(d,e,f):c.call(null,d,e,f)}},a)};
k_=function(a){if($CLJS.ee(a))return a;throw $CLJS.Ej("Invalid pattern: don't know how to handle symbol.",new $CLJS.k(null,1,[$CLJS.tk,a],null));};o6=function(a){var b=$CLJS.ne(a);return b?(b=!$CLJS.Og(a))?$CLJS.Wm.h($CLJS.rf,$CLJS.Jb)($CLJS.C(a)):b:b};m6=function(a){return $CLJS.m($CLJS.Wm.h($CLJS.rf,$CLJS.Jb)(a))?$CLJS.R_(a):a};p6=function(a,b){var c=o6(b);return $CLJS.m(c)?(b=m6($CLJS.C(b)),$CLJS.ke(a)?(a=$CLJS.yi(a),a.g?a.g(b):a.call(null,b)):$CLJS.F.h(a,b)):c};
Cra=function(a){return $CLJS.M.j(new $CLJS.k(null,3,[$CLJS.Uj,$CLJS.$e,$CLJS.bl,m6,$CLJS.yJ,m6],null),a,function(b){var c=$CLJS.M,d=c.h;b=$CLJS.Tf([a,b]);b=q6.h?q6.h(b,Q0):q6.call(null,b,Q0);return d.call(c,b,a)})};
Dra=function(a){a=$CLJS.uh.j($CLJS.P,$CLJS.xg.g(function(b){var c=$CLJS.J(b,0,null);b=$CLJS.J(b,1,null);c=m6(c);var d=Cra(c);return new $CLJS.S(null,2,5,$CLJS.T,[c,d.g?d.g(b):d.call(null,b)],null)}),a);return $CLJS.F.h($CLJS.bl.g(a),$CLJS.yI)&&$CLJS.Fb($CLJS.yJ.g(a))?$CLJS.U.j(a,$CLJS.yJ,$CLJS.$R):a};
Era=function(a){return $CLJS.uh.j($CLJS.P,$CLJS.xg.g(function(b){var c=$CLJS.J(b,0,null);b=$CLJS.J(b,1,null);c=$CLJS.m_(c);return new $CLJS.S(null,2,5,$CLJS.T,[c,$CLJS.U.j(Dra(b),$CLJS.V,c)],null)}),a)};s6=function(a){return $CLJS.u_.j($CLJS.u_.j($CLJS.Rb(function(b,c){return $CLJS.u_.j(b,c,$CLJS.Ui)},a,new $CLJS.S(null,6,5,$CLJS.T,[$CLJS.PJ,$CLJS.Ifa,$CLJS.XP,roa,$CLJS.UE,$CLJS.bk],null)),$CLJS.PH,$CLJS.Um.h(l6,q6)),$CLJS.zP,$CLJS.d_)};
t6=function(a){return $CLJS.Ae(a)?new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.ZQ,a,null],null):a};v6=function(a){return u6.g(t6(a))};w6=function(a){var b=$CLJS.A(a);a=$CLJS.C(b);b=$CLJS.D(b);return $CLJS.p_($CLJS.uh.h(new $CLJS.S(null,1,5,$CLJS.T,[a],null),$CLJS.xg.h(u6,b)))};x6=function(a){var b=$CLJS.A(a);a=$CLJS.C(b);var c=$CLJS.D(b);b=$CLJS.C(c);c=$CLJS.D(c);return $CLJS.uh.h(new $CLJS.S(null,2,5,$CLJS.T,[a,v6(b)],null),$CLJS.xg.h(u6,c))};
y6=function(a){var b=$CLJS.J(a,0,null);a=$CLJS.J(a,1,null);return $CLJS.m(a)?new $CLJS.S(null,2,5,$CLJS.T,[b,v6(a)],null):new $CLJS.S(null,1,5,$CLJS.T,[b],null)};z6=function(a){var b=$CLJS.J(a,0,null);a=$CLJS.J(a,1,null);return new $CLJS.S(null,2,5,$CLJS.T,[b,v6(a)],null)};A6=function(a){var b=$CLJS.J(a,0,null);a=$CLJS.J(a,1,null);return new $CLJS.S(null,2,5,$CLJS.T,[b,u6.g(a)],null)};
l6=function(a){return B6(function(b){if($CLJS.oe(b))return Bra(b);if($CLJS.Fb(o6(b)))return b;try{return u6.g(b)}catch(f){if(f instanceof Error){var c=f,d=$CLJS.hG($CLJS.Gv);if($CLJS.m($CLJS.mG("metabase.mbql.normalize",d))){var e=$CLJS.dI("Invalid clause:");e instanceof Error?$CLJS.nG("metabase.mbql.normalize",d,$CLJS.HC.l($CLJS.G([b])),e):$CLJS.nG("metabase.mbql.normalize",d,$CLJS.HC.l($CLJS.G([e,b])),null)}throw $CLJS.Dj($CLJS.cI("Invalid MBQL clause: {0}",$CLJS.G([$CLJS.YZ(c)])),new $CLJS.k(null,
1,[$CLJS.pV,b],null),c);}throw f;}},a)};Gra=function(a){return $CLJS.Sg($CLJS.kg(l6,C6(a)))};Hra=function(a){for(;;)if($CLJS.m(o6(a)))a=new $CLJS.S(null,1,5,$CLJS.T,[a],null);else return $CLJS.Vf($CLJS.Ym.h(t6,a))};D6=function(a){a=$CLJS.Uf(a);var b=$CLJS.M.h(a,$CLJS.yI);return $CLJS.m(b)?$CLJS.Zm.j(a,$CLJS.yI,u6):a};
Ira=function(a){return $CLJS.uh.h($CLJS.P,function(){return function d(c){return new $CLJS.Cf(null,function(){for(;;){var e=$CLJS.A(c);if(e){if($CLJS.re(e)){var f=$CLJS.$c(e),g=$CLJS.E(f),l=$CLJS.Ff(g);a:for(var n=0;;)if(n<g){var q=$CLJS.ce(f,n),u=$CLJS.J(q,0,null);q=$CLJS.J(q,1,null);u=new $CLJS.S(null,2,5,$CLJS.T,[u,D6(q)],null);l.add(u);n+=1}else{f=!0;break a}return f?$CLJS.If($CLJS.Kf(l),d($CLJS.ad(e))):$CLJS.If($CLJS.Kf(l),null)}f=$CLJS.C(e);l=$CLJS.J(f,0,null);f=$CLJS.J(f,1,null);return $CLJS.pf(new $CLJS.S(null,
2,5,$CLJS.T,[l,D6(f)],null),d($CLJS.Ad(e)))}return null}},null,null)}(a)}())};E6=function(a){a=$CLJS.Uf(a);var b=$CLJS.M.h(a,$CLJS.DR);return $CLJS.m(b)?$CLJS.Zm.j(a,$CLJS.DR,Ira):a};Jra=function(a){var b=$CLJS.Uf(a);a=$CLJS.M.h(b,$CLJS.oR);b=$CLJS.Fb(a)?F6.g?F6.g(b):F6.call(null,b):b;return $CLJS.m(a)?E6(b):b};G6=function(a){return $CLJS.ke(a)?$CLJS.A(a):null!=a};
Kra=function(a){var b=$CLJS.Uf(a);a=$CLJS.M.h(b,$CLJS.QO);var c=$CLJS.M.h(b,$CLJS.iP),d=$CLJS.M.h(b,$CLJS.iQ),e=$CLJS.M.h(b,$CLJS.oR);try{if($CLJS.m(d)){var f=$CLJS.Uf(b),g=$CLJS.M.h(f,$CLJS.iQ);var l=$CLJS.xv($CLJS.Mm.h(f,$CLJS.iQ),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.QO,$CLJS.iQ],null),g)}else l=b;var n=$CLJS.m(a)?$CLJS.Zm.j(l,$CLJS.QO,F6):l,q=$CLJS.m(c)?$CLJS.Zm.j(n,$CLJS.iP,$CLJS.hg($CLJS.Ym,l6)):n,u=$CLJS.m(e)?$CLJS.Zm.j(q,$CLJS.oR,E6):q;return l6(u)}catch(v){if(v instanceof Error)throw n=v,$CLJS.Dj($CLJS.cI("Error canonicalizing query: {0}",
$CLJS.G([$CLJS.YZ(n)])),new $CLJS.k(null,1,[$CLJS.QO,a],null),n);throw v;}};
Lra=function(a){a=$CLJS.Uf(a);var b=$CLJS.M.h(a,$CLJS.QO),c=$CLJS.Uf(b);b=$CLJS.M.h(c,$CLJS.aQ);c=$CLJS.M.h(c,$CLJS.SO);return $CLJS.A(b)&&$CLJS.A(c)?(b=$CLJS.yi($CLJS.We($CLJS.wg,$CLJS.A($CLJS.Ag($CLJS.Hb,function g(e,f){try{if($CLJS.qe(f)&&3===$CLJS.E(f))try{var l=$CLJS.Ud(f,0);if($CLJS.O(l,$CLJS.ZQ)){var n=$CLJS.Ud(f,1),q=$CLJS.Ud(f,2);return new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[f,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.ZQ,n,$CLJS.Mm.h(q,$CLJS.kR)],null)],null)],null)}throw $CLJS.Z;
}catch(u){if(u instanceof Error){l=u;if(l===$CLJS.Z)throw $CLJS.Z;throw l;}throw u;}else throw $CLJS.Z;}catch(u){if(u instanceof Error){l=u;if(l===$CLJS.Z)return $CLJS.CZ(g,e,f);throw l;}throw u;}}($CLJS.Rg,b))))),$CLJS.x_.j(a,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.QO,$CLJS.SO],null),$CLJS.Um.h($CLJS.Sg,$CLJS.hg($CLJS.Xm,b)))):a};
Mra=function(a){try{return Lra(a)}catch(c){if(c instanceof Error){var b=c;throw $CLJS.Dj($CLJS.dI("Error performing whole query transformations"),new $CLJS.k(null,1,[$CLJS.QO,a],null),b);}throw c;}};
Nra=function(a,b){var c=$CLJS.uh.h($CLJS.be(a),function(){return function f(e){return new $CLJS.Cf(null,function(){for(var g=e;;)if(g=$CLJS.A(g)){if($CLJS.re(g)){var l=$CLJS.$c(g),n=$CLJS.E(l),q=$CLJS.Ff(n);return function(){for(var z=0;;)if(z<n){var B=$CLJS.ce(l,z),H=$CLJS.J(B,0,null);B=$CLJS.J(B,1,null);var I=$CLJS.mf.h(b,H);B=H6.h?H6.h(B,I):H6.call(null,B,I);null!=B&&q.add(new $CLJS.S(null,2,5,$CLJS.T,[H,B],null));z+=1}else return!0}()?$CLJS.If($CLJS.Kf(q),f($CLJS.ad(g))):$CLJS.If($CLJS.Kf(q),
null)}var u=$CLJS.C(g),v=$CLJS.J(u,0,null),x=$CLJS.J(u,1,null);u=function(){var z=x,B=$CLJS.mf.h(b,v);return H6.h?H6.h(z,B):H6.call(null,z,B)}();if(null!=u)return $CLJS.pf(new $CLJS.S(null,2,5,$CLJS.T,[v,u],null),f($CLJS.Ad(g)));g=$CLJS.Ad(g)}else return null},null,null)}(a)}());return $CLJS.A(c)?c:null};Ora=function(a,b){a=$CLJS.Ym.h(function(c){var d=$CLJS.mf.h(b,d1);return H6.h?H6.h(c,d):H6.call(null,c,d)},a);return $CLJS.m($CLJS.ag($CLJS.Hb,a))?a:null};
$CLJS.x_=function x_(a){switch(arguments.length){case 3:return x_.j(arguments[0],arguments[1],arguments[2]);case 4:return x_.F(arguments[0],arguments[1],arguments[2],arguments[3]);case 5:return x_.R(arguments[0],arguments[1],arguments[2],arguments[3],arguments[4]);case 6:return x_.aa(arguments[0],arguments[1],arguments[2],arguments[3],arguments[4],arguments[5]);default:for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return x_.l(arguments[0],arguments[1],arguments[2],
arguments[3],arguments[4],arguments[5],new $CLJS.y(c.slice(6),0,null))}};$CLJS.x_.j=function(a,b,c){var d=$CLJS.A(b);b=$CLJS.C(d);if(d=$CLJS.D(d))a=$CLJS.U.j(a,b,$CLJS.x_.j($CLJS.M.h(a,b),d,c));else{d=$CLJS.U.j;var e=$CLJS.M.h(a,b);c=c.g?c.g(e):c.call(null,e);a=d.call($CLJS.U,a,b,c)}return a};
$CLJS.x_.F=function(a,b,c,d){var e=$CLJS.A(b);b=$CLJS.C(e);if(e=$CLJS.D(e))a=$CLJS.U.j(a,b,$CLJS.x_.F($CLJS.M.h(a,b),e,c,d));else{e=$CLJS.U.j;var f=$CLJS.M.h(a,b);c=c.h?c.h(f,d):c.call(null,f,d);a=e.call($CLJS.U,a,b,c)}return a};$CLJS.x_.R=function(a,b,c,d,e){var f=$CLJS.A(b);b=$CLJS.C(f);if(f=$CLJS.D(f))a=$CLJS.U.j(a,b,$CLJS.x_.R($CLJS.M.h(a,b),f,c,d,e));else{f=$CLJS.U.j;var g=$CLJS.M.h(a,b);c=c.j?c.j(g,d,e):c.call(null,g,d,e);a=f.call($CLJS.U,a,b,c)}return a};
$CLJS.x_.aa=function(a,b,c,d,e,f){var g=$CLJS.A(b);b=$CLJS.C(g);if(g=$CLJS.D(g))a=$CLJS.U.j(a,b,$CLJS.x_.aa($CLJS.M.h(a,b),g,c,d,e,f));else{g=$CLJS.U.j;var l=$CLJS.M.h(a,b);c=c.F?c.F(l,d,e,f):c.call(null,l,d,e,f);a=g.call($CLJS.U,a,b,c)}return a};$CLJS.x_.l=function(a,b,c,d,e,f,g){var l=$CLJS.A(b);b=$CLJS.C(l);return(l=$CLJS.D(l))?$CLJS.U.j(a,b,$CLJS.Q.l($CLJS.x_,$CLJS.M.h(a,b),l,c,d,$CLJS.G([e,f,g]))):$CLJS.U.j(a,b,$CLJS.Q.l(c,$CLJS.M.h(a,b),d,e,f,$CLJS.G([g])))};
$CLJS.x_.B=function(a){var b=$CLJS.C(a),c=$CLJS.D(a);a=$CLJS.C(c);var d=$CLJS.D(c);c=$CLJS.C(d);var e=$CLJS.D(d);d=$CLJS.C(e);var f=$CLJS.D(e);e=$CLJS.C(f);var g=$CLJS.D(f);f=$CLJS.C(g);g=$CLJS.D(g);return this.l(b,a,c,d,e,f,g)};$CLJS.x_.v=6;
$CLJS.u_=function u_(a){switch(arguments.length){case 3:return u_.j(arguments[0],arguments[1],arguments[2]);case 4:return u_.F(arguments[0],arguments[1],arguments[2],arguments[3]);case 5:return u_.R(arguments[0],arguments[1],arguments[2],arguments[3],arguments[4]);case 6:return u_.aa(arguments[0],arguments[1],arguments[2],arguments[3],arguments[4],arguments[5]);default:for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return u_.l(arguments[0],arguments[1],arguments[2],
arguments[3],arguments[4],arguments[5],new $CLJS.y(c.slice(6),0,null))}};$CLJS.u_.j=function(a,b,c){var d=$CLJS.Le(a,b);if($CLJS.m(d)){var e=$CLJS.U.j;d=$CLJS.uc(d);c=c.g?c.g(d):c.call(null,d);a=e.call($CLJS.U,a,b,c)}return a};$CLJS.u_.F=function(a,b,c,d){var e=$CLJS.Le(a,b);if($CLJS.m(e)){var f=$CLJS.U.j;e=$CLJS.uc(e);c=c.h?c.h(e,d):c.call(null,e,d);a=f.call($CLJS.U,a,b,c)}return a};
$CLJS.u_.R=function(a,b,c,d,e){var f=$CLJS.Le(a,b);if($CLJS.m(f)){var g=$CLJS.U.j;f=$CLJS.uc(f);c=c.j?c.j(f,d,e):c.call(null,f,d,e);a=g.call($CLJS.U,a,b,c)}return a};$CLJS.u_.aa=function(a,b,c,d,e,f){var g=$CLJS.Le(a,b);if($CLJS.m(g)){var l=$CLJS.U.j;g=$CLJS.uc(g);c=c.F?c.F(g,d,e,f):c.call(null,g,d,e,f);a=l.call($CLJS.U,a,b,c)}return a};$CLJS.u_.l=function(a,b,c,d,e,f,g){var l=$CLJS.Le(a,b);return $CLJS.m(l)?$CLJS.U.j(a,b,$CLJS.Q.l(c,$CLJS.uc(l),d,e,f,$CLJS.G([g]))):a};
$CLJS.u_.B=function(a){var b=$CLJS.C(a),c=$CLJS.D(a);a=$CLJS.C(c);var d=$CLJS.D(c);c=$CLJS.C(d);var e=$CLJS.D(d);d=$CLJS.C(e);var f=$CLJS.D(e);e=$CLJS.C(f);var g=$CLJS.D(f);f=$CLJS.C(g);g=$CLJS.D(g);return this.l(b,a,c,d,e,f,g)};$CLJS.u_.v=6;
var B6=function B6(a,b){return $CLJS.b_($CLJS.hg(B6,a),$CLJS.$e,a.g?a.g(b):a.call(null,b))},soa=new $CLJS.N(null,"value_field","value_field",-980977878),e1=new $CLJS.N(null,"ascending","ascending",-988350486),g1=new $CLJS.N(null,"named","named",-422393479),h1=new $CLJS.N(null,"values_source_config","values_source_config",-590570309),toa=new $CLJS.N(null,"field-id","field-id",-353751335),i1=new $CLJS.N(null,"descending","descending",-24766135),j1=new $CLJS.N(null,"binning-strategy","binning-strategy",
2063329158),k1=new $CLJS.N(null,"datetime-field","datetime-field",21731696),woa=new $CLJS.N(null,"use-as-display-name?","use-as-display-name?",686752941),l1=new $CLJS.N(null,"field-literal","field-literal",-1295883554),m1=new $CLJS.N(null,"viz-settings","viz-settings",256055379),xoa=new $CLJS.N(null,"rows","rows",850049680),yoa=new $CLJS.N(null,"special-fn","special-fn",1290649344),d1=new $CLJS.N("metabase.mbql.normalize","sequence","metabase.mbql.normalize/sequence",-1700645683),Q0=new $CLJS.N(null,
"ignore-path","ignore-path",944069061),zoa=new $CLJS.N(null,"label_field","label_field",-1573182765),Aoa=new $CLJS.N(null,"fk-\x3e","fk-\x3e",-499026738),Boa=new $CLJS.N(null,"joined-field","joined-field",-2048778268),roa=new $CLJS.N(null,"visibility_type","visibility_type",-508434247);var R6,Coa=$CLJS.ij.g($CLJS.P),Doa=$CLJS.ij.g($CLJS.P),Hoa=$CLJS.ij.g($CLJS.P),Ioa=$CLJS.ij.g($CLJS.P),Joa=$CLJS.M.j($CLJS.P,$CLJS.Wk,$CLJS.rj.A?$CLJS.rj.A():$CLJS.rj.call(null));R6=new $CLJS.Aj($CLJS.$i.h("metabase.mbql.normalize","normalize-mbql-clause-tokens"),$CLJS.Um.h(m6,$CLJS.C),$CLJS.Uj,Joa,Coa,Doa,Hoa,Ioa);R6.o(null,$CLJS.hQ,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);b=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hQ,b],null);return null!=a?$CLJS.mf.h(b,a):b});
R6.o(null,$CLJS.ZG,function(a){$CLJS.J(a,0,null);a=$CLJS.J(a,1,null);return new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ZG,a instanceof $CLJS.N?$CLJS.m_(a):a],null)});R6.o(null,j1,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null),c=$CLJS.J(a,2,null);a=$CLJS.J(a,3,null);return $CLJS.m(a)?$CLJS.mf.h(R6.g(new $CLJS.S(null,3,5,$CLJS.T,[j1,b,c],null)),a):new $CLJS.S(null,3,5,$CLJS.T,[j1,q6.h?q6.h(b,Q0):q6.call(null,b,Q0),m6(c)],null)});
R6.o(null,$CLJS.ZQ,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);var c=q6.h?q6.h(a,Q0):q6.call(null,a,Q0);return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.ZQ,b,function(){var d=$CLJS.m($CLJS.bH.g(c))?$CLJS.Zm.j(c,$CLJS.bH,$CLJS.Ui):c;d=$CLJS.m($CLJS.kR.g(c))?$CLJS.Zm.j(d,$CLJS.kR,$CLJS.Ui):d;return $CLJS.m($CLJS.kS.g(c))?$CLJS.Zm.j(d,$CLJS.kS,function(e){return $CLJS.m($CLJS.LL.g(e))?$CLJS.Zm.j(e,$CLJS.LL,$CLJS.Ui):e}):d}()],null)});
R6.o(null,l1,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);return new $CLJS.S(null,3,5,$CLJS.T,[l1,b instanceof $CLJS.N?$CLJS.m_(b):b,$CLJS.Ui.g(a)],null)});R6.o(null,k1,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null),c=$CLJS.J(a,2,null);a=$CLJS.J(a,3,null);return $CLJS.m(a)?new $CLJS.S(null,4,5,$CLJS.T,[k1,q6.h?q6.h(b,Q0):q6.call(null,b,Q0),$CLJS.$u,m6(a)],null):new $CLJS.S(null,3,5,$CLJS.T,[k1,q6.h?q6.h(b,Q0):q6.call(null,b,Q0),m6(c)],null)});
R6.o(null,$CLJS.MK,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null),c=$CLJS.J(a,2,null),d=$CLJS.J(a,3,null);a=$CLJS.J(a,4,null);return $CLJS.m(a)?$CLJS.mf.h(R6.g(new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.MK,b,c,d],null)),q6.h?q6.h(a,Q0):q6.call(null,a,Q0)):new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.MK,q6.h?q6.h(b,Q0):q6.call(null,b,Q0),$CLJS.Ae(c)?c:m6(c),m6(d)],null)});
R6.o(null,$CLJS.NJ,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);return $CLJS.m(a)?new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.NJ,b,m6(a)],null):new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.NJ,$CLJS.WB],null)});R6.o(null,$CLJS.EJ,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.EJ,b,m6(a)],null)});
R6.o(null,$CLJS.zK,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null),c=$CLJS.J(a,2,null);a=$CLJS.J(a,3,null);return new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.zK,q6.h?q6.h(b,Q0):q6.call(null,b,Q0),c,m6(a)],null)});R6.o(null,$CLJS.gL,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null),c=$CLJS.J(a,2,null);a=$CLJS.J(a,3,null);return new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.gL,q6.h?q6.h(b,Q0):q6.call(null,b,Q0),c,m6(a)],null)});
R6.o(null,$CLJS.yK,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);return $CLJS.m(a)?new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.yK,q6.h?q6.h(b,Q0):q6.call(null,b,Q0),m6(a)],null):new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.yK,q6.h?q6.h(b,Q0):q6.call(null,b,Q0)],null)});
R6.o(null,$CLJS.KK,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null),c=$CLJS.J(a,2,null);a=$CLJS.J(a,3,null);return $CLJS.m(a)?new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.KK,q6.h?q6.h(b,Q0):q6.call(null,b,Q0),m6(c),m6(a)],null):new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.KK,q6.h?q6.h(b,Q0):q6.call(null,b,Q0),m6(c)],null)});
R6.o(null,$CLJS.DJ,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null),c=$CLJS.J(a,2,null);a=$CLJS.J(a,3,null);return new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.DJ,q6.h?q6.h(b,Q0):q6.call(null,b,Q0),q6.h?q6.h(c,Q0):q6.call(null,c,Q0),m6(a)],null)});R6.o(null,$CLJS.xl,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.xl,b,a],null)});
R6.o(null,$CLJS.Uj,function(a){var b=$CLJS.A(a);a=$CLJS.C(b);b=$CLJS.D(b);return $CLJS.uh.j(new $CLJS.S(null,1,5,$CLJS.T,[m6(a)],null),$CLJS.xg.g(function(c){return q6.h?q6.h(c,Q0):q6.call(null,c,Q0)}),b)});
var S6=function S6(a){if($CLJS.m($CLJS.Wm.h($CLJS.rf,$CLJS.Jb)(a))){var c=m6(a);var d=new $CLJS.ti(null,new $CLJS.k(null,18,[$CLJS.wk,null,$CLJS.um,null,$CLJS.VK,null,$CLJS.AK,null,$CLJS.bv,null,$CLJS.hK,null,$CLJS.xL,null,$CLJS.zL,null,$CLJS.hJ,null,$CLJS.IJ,null,$CLJS.tm,null,$CLJS.lJ,null,$CLJS.QK,null,$CLJS.tK,null,$CLJS.em,null,$CLJS.VD,null,$CLJS.YJ,null,$CLJS.OK,null],null),null);c=d.g?d.g(c):d.call(null,c)}else c=null;return $CLJS.m(c)?c:$CLJS.m(o6(a))?(a=$CLJS.C(a),S6.g?S6.g(a):S6.call(null,
a)):null},bsa=new $CLJS.k(null,8,[$CLJS.bl,m6,$CLJS.oR,function(a){a=n6(a);return $CLJS.A($CLJS.DR.g(a))?$CLJS.Zm.j(a,$CLJS.DR,Era):a},$CLJS.QO,new $CLJS.k(null,6,[$CLJS.hQ,function T6(a){if($CLJS.m($CLJS.Wm.h($CLJS.rf,$CLJS.Jb)(a)))return m6(a);if($CLJS.m(p6(g1,a))){a=$CLJS.A(a);$CLJS.C(a);var c=$CLJS.D(a);a=$CLJS.C(c);c=$CLJS.D(c);return $CLJS.uh.h(new $CLJS.S(null,2,5,$CLJS.T,[g1,T6.g?T6.g(a):T6.call(null,a)],null),c)}return $CLJS.m($CLJS.m(o6(a))?S6($CLJS.$d(a)):null)?$CLJS.Ym.h(T6,a):q6.h?q6.h(a,
Q0):q6.call(null,a,Q0)},$CLJS.jR,function(a){return $CLJS.uh.h($CLJS.P,function(){return function d(c){return new $CLJS.Cf(null,function(){for(;;){var e=$CLJS.A(c);if(e){if($CLJS.re(e)){var f=$CLJS.$c(e),g=$CLJS.E(f),l=$CLJS.Ff(g);a:for(var n=0;;)if(n<g){var q=$CLJS.ce(f,n),u=$CLJS.J(q,0,null);q=$CLJS.J(q,1,null);u=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.m_(u),q6.h?q6.h(q,Q0):q6.call(null,q,Q0)],null);l.add(u);n+=1}else{f=!0;break a}return f?$CLJS.If($CLJS.Kf(l),d($CLJS.ad(e))):$CLJS.If($CLJS.Kf(l),null)}f=
$CLJS.C(e);l=$CLJS.J(f,0,null);f=$CLJS.J(f,1,null);return $CLJS.pf(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.m_(l),q6.h?q6.h(f,Q0):q6.call(null,f,Q0)],null),d($CLJS.Ad(e)))}return null}},null,null)}(a)}())},$CLJS.tS,function(a){return $CLJS.Sg(function(){return function d(c){return new $CLJS.Cf(null,function(){for(;;){var e=$CLJS.A(c);if(e){if($CLJS.re(e)){var f=$CLJS.$c(e),g=$CLJS.E(f),l=$CLJS.Ff(g);a:for(var n=0;;)if(n<g){var q=$CLJS.ce(f,n);q=$CLJS.m(o6(q))?R6.g(q):$CLJS.nf(R6.g($CLJS.nf(q)));l.add(q);
n+=1}else{f=!0;break a}return f?$CLJS.If($CLJS.Kf(l),d($CLJS.ad(e))):$CLJS.If($CLJS.Kf(l),null)}l=$CLJS.C(e);return $CLJS.pf($CLJS.m(o6(l))?R6.g(l):$CLJS.nf(R6.g($CLJS.nf(l))),d($CLJS.Ad(e)))}return null}},null,null)}(a)}())},$CLJS.GR,function(a){a=n6(a);a=$CLJS.Uf(a);var b=$CLJS.M.h(a,$CLJS.oR);if($CLJS.m(b))return a=$CLJS.YN(a,new $CLJS.k(null,1,[$CLJS.oR,$CLJS.QO],null)),b=new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.oR],null),a=q6.h?q6.h(a,b):q6.call(null,a,b),$CLJS.YN(a,new $CLJS.k(null,1,[$CLJS.QO,$CLJS.oR],
null));b=new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.QO],null);return q6.h?q6.h(a,b):q6.call(null,a,b)},$CLJS.iQ,new $CLJS.k(null,1,[d1,s6],null),$CLJS.pP,new $CLJS.k(null,1,[d1,function(a){a=q6.h?q6.h(a,$CLJS.QO):q6.call(null,a,$CLJS.QO);var b=$CLJS.Uf(a),c=$CLJS.M.h(b,$CLJS.LL),d=$CLJS.M.h(b,$CLJS.SO);a=$CLJS.M.h(b,$CLJS.RP);b=$CLJS.m(c)?$CLJS.Zm.j(b,$CLJS.LL,m6):b;d=$CLJS.m($CLJS.Wm.h($CLJS.rf,$CLJS.Jb)(d))?$CLJS.Zm.j(b,$CLJS.SO,m6):b;return $CLJS.m(a)?$CLJS.Zm.j(d,$CLJS.RP,$CLJS.m_):d}],null)],null),
$CLJS.JE,new $CLJS.k(null,1,[$CLJS.JP,$CLJS.$e],null),$CLJS.iP,new $CLJS.k(null,1,[d1,function(a){var b=$CLJS.Uf(a),c=$CLJS.M.h(b,$CLJS.bl),d=$CLJS.M.h(b,$CLJS.PL),e=$CLJS.M.h(b,$CLJS.Mk);a=$CLJS.M.h(b,h1);b=$CLJS.m(e)?$CLJS.Zm.j(b,$CLJS.Mk,$CLJS.m_):b;c=$CLJS.m(c)?$CLJS.Zm.j(b,$CLJS.bl,m6):b;d=$CLJS.m(d)?$CLJS.Zm.j(c,$CLJS.PL,function(f){return q6.h?q6.h(f,Q0):q6.call(null,f,Q0)}):c;d=$CLJS.m(a)?$CLJS.x_.j(d,new $CLJS.S(null,2,5,$CLJS.T,[h1,zoa],null),function(f){return q6.h?q6.h(f,Q0):q6.call(null,
f,Q0)}):d;return $CLJS.m(a)?$CLJS.x_.j(d,new $CLJS.S(null,2,5,$CLJS.T,[h1,soa],null),function(f){return q6.h?q6.h(f,Q0):q6.call(null,f,Q0)}):d}],null),$CLJS.gP,function(a){return null==a?null:m6(a)},$CLJS.iQ,new $CLJS.k(null,1,[d1,s6],null),m1,m6],null),q6=function q6(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return q6.l(arguments[0],1<c.length?new $CLJS.y(c.slice(1),0,null):null)};
q6.l=function(a,b){b=$CLJS.J(b,0,null);var c=b instanceof $CLJS.N?new $CLJS.S(null,1,5,$CLJS.T,[b],null):$CLJS.Sg(b);b=$CLJS.A(c)?$CLJS.rv(bsa,c):null;try{return $CLJS.ee(b)?b.g?b.g(a):b.call(null,a):$CLJS.pe(a)?a:$CLJS.oe(a)?$CLJS.uh.h($CLJS.P,function(){return function g(f){return new $CLJS.Cf(null,function(){for(;;){var l=$CLJS.A(f);if(l){if($CLJS.re(l)){var n=$CLJS.$c(l),q=$CLJS.E(n),u=$CLJS.Ff(q);a:for(var v=0;;)if(v<q){var x=$CLJS.ce(n,v),z=$CLJS.J(x,0,null);x=$CLJS.J(x,1,null);z=m6(z);z=new $CLJS.S(null,
2,5,$CLJS.T,[z,q6.l(x,$CLJS.G([$CLJS.mf.h($CLJS.Sg(c),z)]))],null);u.add(z);v+=1}else{n=!0;break a}return n?$CLJS.If($CLJS.Kf(u),g($CLJS.ad(l))):$CLJS.If($CLJS.Kf(u),null)}n=$CLJS.C(l);u=$CLJS.J(n,0,null);n=$CLJS.J(n,1,null);u=m6(u);return $CLJS.pf(new $CLJS.S(null,2,5,$CLJS.T,[u,q6.l(n,$CLJS.G([$CLJS.mf.h($CLJS.Sg(c),u)]))],null),g($CLJS.Ad(l)))}return null}},null,null)}(a)}()):$CLJS.m(o6(a))?R6.g(a):$CLJS.ne(a)?$CLJS.Ym.h(function(e){return q6.l(e,$CLJS.G([$CLJS.mf.h($CLJS.Sg(c),d1)]))},a):a}catch(e){if(e instanceof
Error){var d=e;throw $CLJS.Dj($CLJS.cI("Error normalizing form: {0}",$CLJS.G([$CLJS.YZ(d)])),new $CLJS.k(null,3,[$CLJS.SE,a,$CLJS.gm,c,yoa,b],null),d);}throw e;}};q6.v=1;q6.B=function(a){var b=$CLJS.C(a);a=$CLJS.D(a);return this.l(b,a)};
var u6=function(){var a=$CLJS.ij.g($CLJS.P),b=$CLJS.ij.g($CLJS.P),c=$CLJS.ij.g($CLJS.P),d=$CLJS.ij.g($CLJS.P),e=$CLJS.M.j($CLJS.P,$CLJS.Wk,$CLJS.rj.A?$CLJS.rj.A():$CLJS.rj.call(null));return new $CLJS.Aj($CLJS.$i.h("metabase.mbql.normalize","canonicalize-mbql-clause"),function(f){return $CLJS.m(o6(f))?$CLJS.C(f):null},$CLJS.Uj,e,a,b,c,d)}();u6.o(null,$CLJS.Uj,function(a){return a});
u6.o(null,$CLJS.ZQ,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);if($CLJS.m(p6($CLJS.ZQ,b))){$CLJS.J(b,0,null);var c=$CLJS.J(b,1,null);b=$CLJS.J(b,2,null);return u6.g(new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.ZQ,c,$CLJS.Vf($CLJS.pn.l($CLJS.G([b,a])))],null))}return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.ZQ,b,$CLJS.Vf(a)],null)});
u6.o(null,$CLJS.hQ,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);return $CLJS.je(a)?new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hQ,b],null):new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.hQ,b,a],null)});u6.o(null,toa,function(a){$CLJS.J(a,0,null);a=$CLJS.J(a,1,null);return $CLJS.m(o6(a))?u6.g(a):new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.ZQ,a,null],null)});
u6.o(null,l1,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.ZQ,b,new $CLJS.k(null,1,[$CLJS.bH,a],null)],null)});u6.o(null,Aoa,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);var c=v6(b);$CLJS.J(c,0,null);b=$CLJS.J(c,1,null);$CLJS.J(c,2,null);c=v6(a);$CLJS.J(c,0,null);a=$CLJS.J(c,1,null);c=$CLJS.J(c,2,null);return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.ZQ,a,$CLJS.U.j(c,$CLJS.qP,b)],null)});
u6.o(null,Boa,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);a=v6(a);return $CLJS.Q.F($CLJS.B_,a,$CLJS.U,$CLJS.G([$CLJS.HQ,b]))});
u6.o(null,k1,function(a){var b=$CLJS.E(a);switch(b){case 3:$CLJS.J(a,0,null);b=$CLJS.J(a,1,null);var c=$CLJS.J(a,2,null);a=v6(b);b=c;$CLJS.J(a,0,null);$CLJS.J(a,1,null);c=$CLJS.J(a,2,null);c=$CLJS.Uf(c);c=$CLJS.M.h(c,$CLJS.bH);return $CLJS.Fb(c)||$CLJS.RS.h(c,b)?$CLJS.Q.F($CLJS.B_,a,$CLJS.U,$CLJS.G([$CLJS.kR,b])):a;case 4:return $CLJS.J(a,0,null),b=$CLJS.J(a,1,null),$CLJS.J(a,2,null),c=$CLJS.J(a,3,null),u6.g(new $CLJS.S(null,3,5,$CLJS.T,[k1,b,c],null));default:throw Error(["No matching clause: ",
$CLJS.p.g(b)].join(""));}});u6.o(null,j1,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null),c=$CLJS.J(a,2,null),d=$CLJS.J(a,3,null);a=$CLJS.J(a,4,null);var e=v6(b);$CLJS.J(e,0,null);b=$CLJS.J(e,1,null);e=$CLJS.J(e,2,null);return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.ZQ,b,$CLJS.U.j(e,$CLJS.kS,$CLJS.pn.l($CLJS.G([new $CLJS.k(null,1,[$CLJS.LL,c],null),$CLJS.m(d)?$CLJS.Tf([c,d]):null,a])))],null)});
for(var o1=$CLJS.A(new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Qj,$CLJS.Hk,$CLJS.Zk],null)),p1=null,q1=0,r1=0;;)if(r1<q1){var s1=p1.$(null,r1);u6.o(null,s1,function(){return function(a){return w6(a)}}(o1,p1,q1,r1,s1));r1+=1}else{var t1=$CLJS.A(o1);if(t1){var w1=t1;if($CLJS.re(w1)){var x1=$CLJS.$c(w1),Koa=$CLJS.ad(w1),Loa=x1,Moa=$CLJS.E(x1);o1=Koa;p1=Loa;q1=Moa}else{var L1=$CLJS.C(w1);u6.o(null,L1,function(){return function(a){return w6(a)}}(o1,p1,q1,r1,L1,w1,t1));o1=$CLJS.D(w1);p1=null;q1=0}r1=0}else break}
u6.o(null,$CLJS.pJ,function(a){a=$CLJS.A(a);$CLJS.C(a);var b=$CLJS.D(a);a=$CLJS.C(b);var c=$CLJS.D(b);b=$CLJS.C(c);c=$CLJS.D(c);return $CLJS.uh.h(new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.pJ,v6(a),v6(b)],null),c)});u6.o(null,$CLJS.MK,function(a){a=$CLJS.A(a);$CLJS.C(a);var b=$CLJS.D(a);a=$CLJS.C(b);b=$CLJS.D(b);var c=v6(a);a=$CLJS.m($CLJS.n_($CLJS.ZQ,a))?$CLJS.B_.l(c,$CLJS.Mm,$CLJS.G([$CLJS.kR])):c;return $CLJS.uh.h(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.MK,a],null),b)});
for(var M1=$CLJS.A(new $CLJS.S(null,15,5,$CLJS.T,[$CLJS.QJ,$CLJS.IK,$CLJS.fK,$CLJS.RJ,$CLJS.om,$CLJS.YK,$CLJS.pm,$CLJS.Bm,$CLJS.rm,$CLJS.Am,$CLJS.fJ,$CLJS.vJ,$CLJS.TI,$CLJS.JJ,$CLJS.eJ],null)),N1=null,O1=0,P1=0;;)if(P1<O1){var Q1=N1.$(null,P1);u6.o(null,Q1,function(){return function(a){return x6(a)}}(M1,N1,O1,P1,Q1));P1+=1}else{var R1=$CLJS.A(M1);if(R1){var S1=R1;if($CLJS.re(S1)){var T1=$CLJS.$c(S1),Noa=$CLJS.ad(S1),Ooa=T1,Poa=$CLJS.E(T1);M1=Noa;N1=Ooa;O1=Poa}else{var U1=$CLJS.C(S1);u6.o(null,U1,
function(){return function(a){return x6(a)}}(M1,N1,O1,P1,U1,S1,R1));M1=$CLJS.D(S1);N1=null;O1=0}P1=0}else break}u6.o(null,xoa,function(){return null});u6.o(null,$CLJS.JO,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.JO,u6.g(b),a],null)});
u6.o(null,g1,function(a){a=$CLJS.A(a);$CLJS.C(a);a=$CLJS.D(a);var b=$CLJS.C(a),c=$CLJS.D(a);a=$CLJS.C(c);var d=$CLJS.D(c);c=u6.g;var e=$CLJS.T;b=u6.g(b);d=$CLJS.J(d,0,null);d=$CLJS.Uf(d);a=!1===$CLJS.M.h(d,woa)?new $CLJS.k(null,1,[$CLJS.V,a],null):new $CLJS.k(null,1,[$CLJS.oJ,a],null);return c.call(u6,new $CLJS.S(null,3,5,e,[$CLJS.JO,b,a],null))});
for(var V1=$CLJS.A(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.VD,$CLJS.hK],null)),W1=null,X1=0,Y1=0;;)if(Y1<X1){var Z1=W1.$(null,Y1);u6.o(null,Z1,function(){return function(a){return y6(a)}}(V1,W1,X1,Y1,Z1));Y1+=1}else{var $1=$CLJS.A(V1);if($1){var a2=$1;if($CLJS.re(a2)){var b2=$CLJS.$c(a2),Qoa=$CLJS.ad(a2),Roa=b2,Soa=$CLJS.E(b2);V1=Qoa;W1=Roa;X1=Soa}else{var q2=$CLJS.C(a2);u6.o(null,q2,function(){return function(a){return y6(a)}}(V1,W1,X1,Y1,q2,a2,$1));V1=$CLJS.D(a2);W1=null;X1=0}Y1=0}else break}
for(var r2=$CLJS.A(new $CLJS.S(null,9,5,$CLJS.T,[$CLJS.YJ,$CLJS.AL,$CLJS.hJ,$CLJS.VK,$CLJS.OK,$CLJS.wk,$CLJS.em,$CLJS.QK,$CLJS.lJ],null)),s2=null,t2=0,u2=0;;)if(u2<t2){var y2=s2.$(null,u2);u6.o(null,y2,function(){return function(a){return z6(a)}}(r2,s2,t2,u2,y2));u2+=1}else{var z2=$CLJS.A(r2);if(z2){var A2=z2;if($CLJS.re(A2)){var B2=$CLJS.$c(A2),Toa=$CLJS.ad(A2),Uoa=B2,Voa=$CLJS.E(B2);r2=Toa;s2=Uoa;t2=Voa}else{var C2=$CLJS.C(A2);u6.o(null,C2,function(){return function(a){return z6(a)}}(r2,s2,t2,u2,
C2,A2,z2));r2=$CLJS.D(A2);s2=null;t2=0}u2=0}else break}u6.o(null,$CLJS.IJ,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.IJ,v6(b),a],null)});
for(var D2=$CLJS.A(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.tK,$CLJS.AK],null)),E2=null,F2=0,G2=0;;)if(G2<F2){var H2=E2.$(null,G2);u6.o(null,H2,function(){return function(a){return A6(a)}}(D2,E2,F2,G2,H2));G2+=1}else{var I2=$CLJS.A(D2);if(I2){var J2=I2;if($CLJS.re(J2)){var K2=$CLJS.$c(J2),Woa=$CLJS.ad(J2),Xoa=K2,Yoa=$CLJS.E(K2);D2=Woa;E2=Xoa;F2=Yoa}else{var L2=$CLJS.C(J2);u6.o(null,L2,function(){return function(a){return A6(a)}}(D2,E2,F2,G2,L2,J2,I2));D2=$CLJS.D(J2);E2=null;F2=0}G2=0}else break}
u6.o(null,$CLJS.xL,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.xL,u6.g(b),u6.g(a)],null)});
u6.o(null,$CLJS.uJ,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);return $CLJS.m(a)?$CLJS.mf.h(u6.g(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.uJ,b],null)),q6.l(a,$CLJS.G([Q0]))):new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.uJ,$CLJS.Sg(function(){return function e(d){return new $CLJS.Cf(null,function(){for(;;){var f=$CLJS.A(d);if(f){if($CLJS.re(f)){var g=$CLJS.$c(f),l=$CLJS.E(g),n=$CLJS.Ff(l);a:for(var q=0;;)if(q<l){var u=$CLJS.ce(g,q),v=$CLJS.J(u,0,null);u=$CLJS.J(u,1,null);v=new $CLJS.S(null,
2,5,$CLJS.T,[u6.g(v),u6.g(u)],null);n.add(v);q+=1}else{g=!0;break a}return g?$CLJS.If($CLJS.Kf(n),e($CLJS.ad(f))):$CLJS.If($CLJS.Kf(n),null)}g=$CLJS.C(f);n=$CLJS.J(g,0,null);g=$CLJS.J(g,1,null);return $CLJS.pf(new $CLJS.S(null,2,5,$CLJS.T,[u6.g(n),u6.g(g)],null),e($CLJS.Ad(f)))}return null}},null,null)}(b)}())],null)});
u6.o(null,$CLJS.wI,function(a){a=$CLJS.A(a);$CLJS.C(a);var b=$CLJS.D(a);a=$CLJS.C(b);var c=$CLJS.D(b);b=$CLJS.C(c);c=$CLJS.D(c);return $CLJS.uh.h(new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.wI,u6.g(a),$CLJS.F.h(0,b)?1:u6.g(b)],null),$CLJS.xg.h(u6,c))});
var C6=function C6(a){return function f(d,e){try{if($CLJS.m(function(){var x=k_($CLJS.we);return x.g?x.g(e):x.call(null,e)}()))return f(d,$CLJS.Sg(e));throw $CLJS.Z;}catch(x){if(x instanceof Error)if(d=x,d===$CLJS.Z)try{if($CLJS.m(function(){var z=k_($CLJS.rf);return z.g?z.g(e):z.call(null,e)}()))return new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.S(null,1,5,$CLJS.T,[e],null)],null);throw $CLJS.Z;}catch(z){if(z instanceof Error)if(d=z,d===$CLJS.Z)try{if($CLJS.qe(e)&&2<=$CLJS.E(e))try{var g=$CLJS.hm.j(e,
0,2);if($CLJS.qe(g)&&2===$CLJS.E(g))try{var l=$CLJS.Ud(g,0);if($CLJS.m($CLJS.Vm.h($CLJS.rf,$CLJS.fg(new $CLJS.ti(null,new $CLJS.k(null,5,[$CLJS.um,null,$CLJS.bv,null,$CLJS.zL,null,$CLJS.tm,null,g1,null],null),null)))(l)))try{var n=$CLJS.Ud(g,1);if($CLJS.m(S6(n)))return $CLJS.Sg($CLJS.We($CLJS.wg,$CLJS.xg.h(C6,a)));throw $CLJS.Z;}catch(B){if(B instanceof Error){var q=B;if(q===$CLJS.Z)throw $CLJS.Z;throw q;}throw B;}else throw $CLJS.Z;}catch(B){if(B instanceof Error){q=B;if(q===$CLJS.Z)throw $CLJS.Z;
throw q;}throw B;}else throw $CLJS.Z;}catch(B){if(B instanceof Error){q=B;if(q===$CLJS.Z)throw $CLJS.Z;throw q;}throw B;}else throw $CLJS.Z;}catch(B){if(B instanceof Error)if(q=B,q===$CLJS.Z)try{if($CLJS.qe(e)&&1<=$CLJS.E(e))try{var u=$CLJS.hm.j(e,0,1);if($CLJS.qe(u)&&1===$CLJS.E(u))try{if($CLJS.Ud(u,0)instanceof $CLJS.N)return new $CLJS.S(null,1,5,$CLJS.T,[e],null);throw $CLJS.Z;}catch(H){if(H instanceof Error){var v=H;if(v===$CLJS.Z)throw $CLJS.Z;throw v;}throw H;}else throw $CLJS.Z;}catch(H){if(H instanceof
Error){v=H;if(v===$CLJS.Z)throw $CLJS.Z;throw v;}throw H;}else throw $CLJS.Z;}catch(H){if(H instanceof Error){v=H;if(v===$CLJS.Z)return e;throw v;}throw H;}else throw q;else throw B;}else throw d;else throw z;}else throw d;else throw x;}}($CLJS.Rg,a)},M7=function M7(a){return function f(d,e){try{var g=k_($CLJS.we);var l=g.g?g.g(e):g.call(null,e);if($CLJS.m(l))return f(d,$CLJS.Sg(e));throw $CLJS.Z;}catch(B){if(B instanceof Error)if(l=B,l===$CLJS.Z)try{if($CLJS.qe(e)&&2===$CLJS.E(e))try{var n=$CLJS.Ud(e,
1);if($CLJS.O(n,$CLJS.aK)){var q=$CLJS.Ud(e,0);return f(d,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.aK,q],null))}throw $CLJS.Z;}catch(H){if(H instanceof Error)if(l=H,l===$CLJS.Z)try{n=$CLJS.Ud(e,1);if($CLJS.O(n,$CLJS.OJ))return q=$CLJS.Ud(e,0),f(d,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.OJ,q],null));throw $CLJS.Z;}catch(I){if(I instanceof Error){var u=I;if(u===$CLJS.Z)try{n=$CLJS.Ud(e,1);if($CLJS.O(n,e1))return q=$CLJS.Ud(e,0),f(d,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.aK,q],null));throw $CLJS.Z;}catch(R){if(R instanceof
Error)if(l=R,l===$CLJS.Z)try{n=$CLJS.Ud(e,1);if($CLJS.O(n,i1))return q=$CLJS.Ud(e,0),f(d,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.OJ,q],null));throw $CLJS.Z;}catch(X){if(X instanceof Error)if(n=X,n===$CLJS.Z)try{var v=$CLJS.Ud(e,0);if($CLJS.O(v,e1))return q=$CLJS.Ud(e,1),f(d,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.aK,q],null));throw $CLJS.Z;}catch(ba){if(ba instanceof Error)if(n=ba,n===$CLJS.Z)try{v=$CLJS.Ud(e,0);if($CLJS.O(v,i1))return q=$CLJS.Ud(e,1),f(d,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.OJ,q],null));
throw $CLJS.Z;}catch(ha){if(ha instanceof Error)if(n=ha,n===$CLJS.Z)try{v=$CLJS.Ud(e,0);if($CLJS.O(v,$CLJS.aK))return q=$CLJS.Ud(e,1),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.aK,v6(q)],null);throw $CLJS.Z;}catch(oa){if(oa instanceof Error)if(n=oa,n===$CLJS.Z)try{v=$CLJS.Ud(e,0);if($CLJS.O(v,$CLJS.OJ))return q=$CLJS.Ud(e,1),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.OJ,v6(q)],null);throw $CLJS.Z;}catch(Fa){if(Fa instanceof Error){q=Fa;if(q===$CLJS.Z)throw $CLJS.Z;throw q;}throw Fa;}else throw n;else throw oa;
}else throw n;else throw ha;}else throw n;else throw ba;}else throw n;else throw X;}else throw l;else throw R;}else throw u;}else throw I;}else throw l;else throw H;}else throw $CLJS.Z;}catch(H){if(H instanceof Error)if(l=H,l===$CLJS.Z)try{if($CLJS.qe(e)&&0<=$CLJS.E(e))try{var x=$CLJS.hm.j(e,0,0);if($CLJS.qe(x)&&0===$CLJS.E(x))try{var z=$CLJS.hm.h(e,0);if($CLJS.F.h(z,a))return $CLJS.Sg($CLJS.rn.g($CLJS.xg.h(M7,a)));throw $CLJS.Z;}catch(I){if(I instanceof Error){u=I;if(u===$CLJS.Z)throw $CLJS.Z;throw u;
}throw I;}else throw $CLJS.Z;}catch(I){if(I instanceof Error){u=I;if(u===$CLJS.Z)throw $CLJS.Z;throw u;}throw I;}else throw $CLJS.Z;}catch(I){if(I instanceof Error){u=I;if(u===$CLJS.Z)return $CLJS.l_(f,d,e);throw u;}throw I;}else throw l;else throw H;}else throw l;else throw B;}}($CLJS.Rg,a)},F6=$CLJS.Um.h(l6,function(a){var b=G6($CLJS.hQ.g(a))?$CLJS.Zm.j(a,$CLJS.hQ,Gra):a;b=G6($CLJS.aQ.g(a))?$CLJS.Zm.j(b,$CLJS.aQ,Hra):b;b=G6($CLJS.SO.g(a))?$CLJS.Zm.j(b,$CLJS.SO,$CLJS.hg($CLJS.Ym,t6)):b;b=G6($CLJS.tS.g(a))?
$CLJS.Zm.j(b,$CLJS.tS,M7):b;return G6($CLJS.GR.g(a))?$CLJS.Zm.j(b,$CLJS.GR,Jra):b}),rsa=new $CLJS.k(null,3,[$CLJS.oR,$CLJS.$e,$CLJS.QO,new $CLJS.k(null,2,[$CLJS.GR,function(a){a=$CLJS.Uf(a);var b=$CLJS.M.h(a,$CLJS.oR);if($CLJS.m(b))return a=$CLJS.YN(a,new $CLJS.k(null,1,[$CLJS.oR,$CLJS.QO],null)),b=new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.oR],null),a=H6.h?H6.h(a,b):H6.call(null,a,b),$CLJS.YN(a,new $CLJS.k(null,1,[$CLJS.QO,$CLJS.oR],null));b=new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.QO],null);return H6.h?H6.h(a,
b):H6.call(null,a,b)},$CLJS.pP,new $CLJS.k(null,1,[d1,function(a){var b=new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.QO],null);return H6.h?H6.h(a,b):H6.call(null,a,b)}],null)],null),m1,$CLJS.$e],null),H6=function H6(a){switch(arguments.length){case 1:return H6.g(arguments[0]);case 2:return H6.h(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};H6.g=function(a){return H6.h(a,$CLJS.Rg)};
H6.h=function(a,b){try{var c=$CLJS.A(b)?$CLJS.rv(rsa,b):null;return $CLJS.ee(c)?c.g?c.g(a):c.call(null,a):$CLJS.pe(a)?a:$CLJS.oe(a)?Nra(a,b):$CLJS.ne(a)?Ora(a,b):a}catch(d){if(d instanceof Error)throw $CLJS.Dj("Error removing empty clauses from form.",new $CLJS.k(null,2,[$CLJS.SE,a,$CLJS.gm,b],null),d);throw d;}};H6.v=2;
$CLJS.N7=function(){var a=$CLJS.Um.l(H6,Mra,Kra,$CLJS.G([q6]));return function(b){try{return a(b)}catch(d){if(d instanceof Error){var c=d;throw $CLJS.Dj($CLJS.cI("Error normalizing query: {0}",$CLJS.G([$CLJS.YZ(c)])),new $CLJS.k(null,1,[$CLJS.QO,b],null),c);}throw d;}}}();