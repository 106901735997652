var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./medley.core.js");require("./metabase.lib.aggregation.js");require("./metabase.lib.binning.js");require("./metabase.lib.breakout.js");require("./metabase.lib.expression.js");require("./metabase.lib.field.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.join.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.normalize.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var W7,X7,Osa,$7,Psa,c8,Qsa,Rsa,Ssa,Tsa,Usa,Vsa,Wsa,h8,Xsa,j8,k8,Ysa,e8,d8,l8,m8;W7=function(a,b){return null==b||$CLJS.Je(a,$CLJS.rk)?a:$CLJS.U.j(a,$CLJS.rk,b)};
X7=function(a){return function(b){var c=$CLJS.og($CLJS.ui);return function(){function d(l,n){var q=a.g?a.g(n):a.call(null,n);if($CLJS.Je($CLJS.r(c),q))return l;c.He(null,$CLJS.mf.h(c.jc(null),q));return b.h?b.h(l,n):b.call(null,l,n)}function e(l){return b.g?b.g(l):b.call(null,l)}function f(){return b.A?b.A():b.call(null)}var g=null;g=function(l,n){switch(arguments.length){case 0:return f.call(this);case 1:return e.call(this,l);case 2:return d.call(this,l,n)}throw Error("Invalid arity: "+arguments.length);
};g.A=f;g.g=e;g.h=d;return g}()}};$CLJS.Y7=function(a,b){return $CLJS.I1.h(a,b instanceof $CLJS.N?b:$CLJS.bk.g(b))};$CLJS.Z7=function(a){a=$CLJS.J1.g(a);return $CLJS.m(a)?new $CLJS.k(null,2,[$CLJS.Zu,$CLJS.wW,$CLJS.bk,a],null):null};Osa=function(a,b,c){return $CLJS.uh.j($CLJS.Rg,$CLJS.WG(function(d){return $CLJS.F5(a,b,d,new $CLJS.k(null,2,[$CLJS.w4,c,$CLJS.s4,!1],null))}),$CLJS.pP.g($CLJS.W0(a,b)))};
$7=function(a,b,c){return $CLJS.uh.j($CLJS.Rg,$CLJS.WG(function(d){return $CLJS.xg.h($CLJS.iA($CLJS.b4,d,c),$CLJS.b1(a,b,d))}),$CLJS.pP.g($CLJS.W0(a,b)))};$CLJS.a8=function(a,b){return $CLJS.k5.h(a,$CLJS.Je(b,$CLJS.u7)?$CLJS.u7.g(b):b)};Psa=function(a,b,c){var d=$CLJS.g0(c);return $CLJS.U.l($CLJS.b1(a,b,c),$CLJS.E0,$CLJS.I3,$CLJS.G([$CLJS.V,d,$CLJS.oJ,d]))};
c8=function(a,b){var c=$CLJS.R5(b);if($CLJS.m(c))a=c;else a:{var d=$CLJS.Uf(b);c=$CLJS.M.h(d,$CLJS.ER);d=$CLJS.M.h(d,$CLJS.J0);if($CLJS.m($CLJS.m(c)?d:c)&&(d=$CLJS.Z5(a,d),$CLJS.m(d))){d=$CLJS.V.g(d);a=$CLJS.V.g($CLJS.W3(a,c));a=$CLJS.xa.j?$CLJS.xa.j("%s__via__%s",d,a):$CLJS.xa.call(null,"%s__via__%s",d,a);break a}a=null}return $CLJS.m(a)?$CLJS.a4(a,$CLJS.V.g(b)):$CLJS.V.g(b)};
$CLJS.f4=function(a,b){b=$CLJS.E5(a,b);return $CLJS.m(b)?$CLJS.X0(a,b,$CLJS.U,$CLJS.G([$CLJS.A9,d8?d8(a,b):e8.call(null,a,b)])):a};
Qsa=function(a,b){a=$CLJS.W0(a,b);a=$CLJS.Uf(a);var c=$CLJS.M.h(a,$CLJS.Zu),d=$CLJS.M.h(a,$CLJS.QZ);b=$CLJS.A9.g(a);if($CLJS.m(b))return b;var e=$CLJS.PZ.g(a);if($CLJS.m(e)&&$CLJS.m(function(){var g=$CLJS.F.h(c,$CLJS.D_);return g?g:d}())){var f=function(){var g=c instanceof $CLJS.N?c.S:null;switch(g){case "mbql.stage/native":return $CLJS.P3;case "mbql.stage/mbql":return $CLJS.N3;default:throw Error(["No matching clause: ",$CLJS.p.g(g)].join(""));}}();return $CLJS.Vf(function(){return function n(l){return new $CLJS.Cf(null,
function(){for(;;){var q=$CLJS.A(l);if(q){if($CLJS.re(q)){var u=$CLJS.$c(q),v=$CLJS.E(u),x=$CLJS.Ff(v);a:for(var z=0;;)if(z<v){var B=$CLJS.ce(u,z);B=$CLJS.pn.l($CLJS.G([new $CLJS.k(null,2,[$CLJS.F3,$CLJS.V.g(B),$CLJS.L3,$CLJS.V.g(B)],null),B,new $CLJS.k(null,1,[$CLJS.E0,f],null)]));x.add(B);z+=1}else{u=!0;break a}return u?$CLJS.If($CLJS.Kf(x),n($CLJS.ad(q))):$CLJS.If($CLJS.Kf(x),null)}x=$CLJS.C(q);return $CLJS.pf($CLJS.pn.l($CLJS.G([new $CLJS.k(null,2,[$CLJS.F3,$CLJS.V.g(x),$CLJS.L3,$CLJS.V.g(x)],
null),x,new $CLJS.k(null,1,[$CLJS.E0,f],null)])),n($CLJS.Ad(q)))}return null}},null,null)}($CLJS.D0.g(e))}())}return null};
Rsa=function(a,b,c){return $CLJS.Vf(function(){return function f(e){return new $CLJS.Cf(null,function(){for(;;){var g=$CLJS.A(e);if(g){if($CLJS.re(g)){var l=$CLJS.$c(g),n=$CLJS.E(l),q=$CLJS.Ff(n);return function(){for(var v=0;;)if(v<n){var x=$CLJS.ce(l,v),z=q,B=$CLJS.U,H=B.l,I=x,R=$CLJS.V.g(x);x=$CLJS.V.g(x);x=c.g?c.g(x):c.call(null,x);B=H.call(B,I,$CLJS.E0,$CLJS.Z3,$CLJS.G([$CLJS.F3,R,$CLJS.L3,x]));z.add(B);v+=1}else return!0}()?$CLJS.If($CLJS.Kf(q),f($CLJS.ad(g))):$CLJS.If($CLJS.Kf(q),null)}var u=
$CLJS.C(g);return $CLJS.pf($CLJS.U.l(u,$CLJS.E0,$CLJS.Z3,$CLJS.G([$CLJS.F3,$CLJS.V.g(u),$CLJS.L3,function(){var v=$CLJS.V.g(u);return c.g?c.g(v):c.call(null,v)}()])),f($CLJS.Ad(g)))}return null}},null,null)}($CLJS.b8.h(a,b))}())};
Ssa=function(a,b,c){return $CLJS.Vf(function(){return function f(e){return new $CLJS.Cf(null,function(){for(;;){var g=$CLJS.A(e);if(g){if($CLJS.re(g)){var l=$CLJS.$c(g),n=$CLJS.E(l),q=$CLJS.Ff(n);return function(){for(var v=0;;)if(v<n){var x=$CLJS.ce(l,v),z=q,B=$CLJS.U,H=B.l,I=x,R=$CLJS.V.g(x);x=$CLJS.V.g(x);x=c.g?c.g(x):c.call(null,x);B=H.call(B,I,$CLJS.E0,$CLJS.c4,$CLJS.G([$CLJS.F3,R,$CLJS.L3,x]));z.add(B);v+=1}else return!0}()?$CLJS.If($CLJS.Kf(q),f($CLJS.ad(g))):$CLJS.If($CLJS.Kf(q),null)}var u=
$CLJS.C(g);return $CLJS.pf($CLJS.U.l(u,$CLJS.E0,$CLJS.c4,$CLJS.G([$CLJS.F3,$CLJS.V.g(u),$CLJS.L3,function(){var v=$CLJS.V.g(u);return c.g?c.g(v):c.call(null,v)}()])),f($CLJS.Ad(g)))}return null}},null,null)}($CLJS.f8.h(a,b))}())};
Tsa=function(a,b,c){var d=$CLJS.W0(a,b);if($CLJS.m(d)){d=$CLJS.Uf(d);var e=$CLJS.M.h(d,$CLJS.SO);return $CLJS.Vf(function(){return function l(g){return new $CLJS.Cf(null,function(){for(;;){var n=$CLJS.A(g);if(n){if($CLJS.re(n)){var q=$CLJS.$c(n),u=$CLJS.E(q),v=$CLJS.Ff(u);return function(){for(var I=0;;)if(I<u){var R=$CLJS.ce(q,I),X=$CLJS.J(R,0,null),ba=function(){var oa=X;oa=oa instanceof $CLJS.N?oa.S:null;switch(oa){case "field":return $CLJS.O3;case "expression":return $CLJS.I3;default:throw Error(["No matching clause: ",
$CLJS.p.g(oa)].join(""));}}(),ha=$CLJS.b1(a,b,R);$CLJS.Jf(v,$CLJS.U.l(ha,$CLJS.E0,ba,$CLJS.G([$CLJS.F3,$CLJS.A1.j(a,b,ha),$CLJS.L3,function(){var oa=c8(a,ha);return c.g?c.g(oa):c.call(null,oa)}()])));I+=1}else return!0}()?$CLJS.If($CLJS.Kf(v),l($CLJS.ad(n))):$CLJS.If($CLJS.Kf(v),null)}var x=$CLJS.C(n),z=$CLJS.J(x,0,null),B=function(){var I=z;I=I instanceof $CLJS.N?I.S:null;switch(I){case "field":return $CLJS.O3;case "expression":return $CLJS.I3;default:throw Error(["No matching clause: ",$CLJS.p.g(I)].join(""));
}}(),H=$CLJS.b1(a,b,x);return $CLJS.pf($CLJS.U.l(H,$CLJS.E0,B,$CLJS.G([$CLJS.F3,$CLJS.A1.j(a,b,H),$CLJS.L3,function(){var I=c8(a,H);return c.g?c.g(I):c.call(null,I)}()])),l($CLJS.Ad(n)))}return null}},null,null)}(e)}())}return null};Usa=function(a,b,c){return $CLJS.Vf($CLJS.uh.j($CLJS.Rg,$CLJS.WG(function(d){return d.j?d.j(a,b,c):d.call(null,a,b,c)}),new $CLJS.S(null,2,5,$CLJS.T,[Rsa,Ssa],null)))};
Vsa=function(a,b,c){var d=$CLJS.E5(a,b);return $CLJS.m(d)?$CLJS.Vf(function(){return function g(f){return new $CLJS.Cf(null,function(){for(;;){var l=$CLJS.A(f);if(l){if($CLJS.re(l)){var n=$CLJS.$c(l),q=$CLJS.E(n),u=$CLJS.Ff(q);return function(){for(var z=0;;)if(z<q){var B=$CLJS.ce(n,z);var H=$CLJS.Wm.h($CLJS.L3,$CLJS.F3)(B);H=$CLJS.m(H)?H:$CLJS.A1.j(a,b,B);B=$CLJS.Mm.h($CLJS.U.l(B,$CLJS.E0,$CLJS.G3,$CLJS.G([$CLJS.F3,H,$CLJS.L3,c.g?c.g(H):c.call(null,H)])),$CLJS.C9);u.add(B);z+=1}else return!0}()?
$CLJS.If($CLJS.Kf(u),g($CLJS.ad(l))):$CLJS.If($CLJS.Kf(u),null)}var v=$CLJS.C(l),x=function(){var z=$CLJS.Wm.h($CLJS.L3,$CLJS.F3)(v);return $CLJS.m(z)?z:$CLJS.A1.j(a,b,v)}();return $CLJS.pf($CLJS.Mm.h($CLJS.U.l(v,$CLJS.E0,$CLJS.G3,$CLJS.G([$CLJS.F3,x,$CLJS.L3,c.g?c.g(x):c.call(null,x)])),$CLJS.C9),g($CLJS.Ad(l)))}return null}},null,null)}(d8?d8(a,d):e8.call(null,a,d))}()):null};
Wsa=function(a,b,c,d){return $CLJS.m(c)?(c=$CLJS.$0(a,c),$CLJS.m(c)?$CLJS.Vf($CLJS.Ym.h(function(e){return $CLJS.Mm.h(e,$CLJS.b7)},$CLJS.F5(a,b,c,new $CLJS.k(null,2,[$CLJS.w4,d,$CLJS.s4,!1],null)))):null):null};
h8=function(a,b,c){return $CLJS.Vf(function(){return function f(e){return new $CLJS.Cf(null,function(){for(;;){var g=$CLJS.A(e);if(g){if($CLJS.re(g)){var l=$CLJS.$c(g),n=$CLJS.E(l),q=$CLJS.Ff(n);return function(){for(var v=0;;)if(v<n){var x=$CLJS.ce(l,v),z=q;var B=$CLJS.bH.g(x);var H=$CLJS.U.l,I=x,R=$CLJS.V.g(x);x=$CLJS.V.g(x);x=c.g?c.g(x):c.call(null,x);B=W7(H.call($CLJS.U,I,$CLJS.E0,$CLJS.I3,$CLJS.G([$CLJS.F3,R,$CLJS.L3,x])),$CLJS.m(B)?B:$CLJS.yl);z.add(B);v+=1}else return!0}()?$CLJS.If($CLJS.Kf(q),
f($CLJS.ad(g))):$CLJS.If($CLJS.Kf(q),null)}var u=$CLJS.C(g);return $CLJS.pf(function(){var v=$CLJS.bH.g(u),x=$CLJS.U.l,z=u,B=$CLJS.V.g(u);var H=$CLJS.V.g(u);H=c.g?c.g(H):c.call(null,H);return W7(x.call($CLJS.U,z,$CLJS.E0,$CLJS.I3,$CLJS.G([$CLJS.F3,B,$CLJS.L3,H])),$CLJS.m(v)?v:$CLJS.yl)}(),f($CLJS.Ad(g)))}return null}},null,null)}(g8.h(a,b))}())};
Xsa=function(a,b,c,d){var e=$CLJS.uh.j($CLJS.ui,$CLJS.xg.g($CLJS.J0),c);return $CLJS.uh.j($CLJS.Rg,$CLJS.Um.l($CLJS.zg($CLJS.b7),X7($CLJS.b7),$CLJS.xg.g(function(f){var g=$CLJS.Uf(f);f=$CLJS.M.h(g,$CLJS.Mk);g=$CLJS.M.h(g,$CLJS.b7);return $CLJS.U.j($CLJS.W3(a,g),rva,f)}),$CLJS.G([$CLJS.Xm.g(function(f){return $CLJS.Je(e,$CLJS.J0.g(f))}),X7($CLJS.J0),$CLJS.WG(function(f){f=$CLJS.Uf(f);var g=$CLJS.M.h(f,$CLJS.J0),l=$CLJS.M.h(f,rva);f=$CLJS.Z0(a,g);return function u(q){return new $CLJS.Cf(null,function(){for(;;){var v=
$CLJS.A(q);if(v){if($CLJS.re(v)){var x=$CLJS.$c(v),z=$CLJS.E(x),B=$CLJS.Ff(z);return function(){for(var R=0;;)if(R<z){var X=$CLJS.ce(x,R),ba=$CLJS.U.l(X,$CLJS.ER,l,$CLJS.G([$CLJS.E0,$CLJS.J3,$CLJS.F3,$CLJS.V.g(X)]));X=B;var ha=$CLJS.U,oa=ha.j,Fa=ba;ba=c8(a,ba);ba=d.g?d.g(ba):d.call(null,ba);ha=oa.call(ha,Fa,$CLJS.L3,ba);X.add(ha);R+=1}else return!0}()?$CLJS.If($CLJS.Kf(B),u($CLJS.ad(v))):$CLJS.If($CLJS.Kf(B),null)}var H=$CLJS.C(v),I=$CLJS.U.l(H,$CLJS.ER,l,$CLJS.G([$CLJS.E0,$CLJS.J3,$CLJS.F3,$CLJS.V.g(H)]));
return $CLJS.pf($CLJS.U.j(I,$CLJS.L3,function(){var R=c8(a,I);return d.g?d.g(R):d.call(null,R)}()),u($CLJS.Ad(v)))}return null}},null,null)}($CLJS.F1.F(a,b,f,new $CLJS.k(null,2,[$CLJS.w4,d,$CLJS.s4,!1],null)))})])),c)};
j8=function(a,b,c){var d=$CLJS.Uf(c),e=$CLJS.M.h(d,$CLJS.w4);c=Vsa(a,b,e);if($CLJS.m(c))return c;c=$CLJS.W0(a,b);c=$CLJS.Uf(c);var f=$CLJS.M.h(c,$CLJS.tQ),g=$CLJS.M.h(c,$CLJS.QZ),l=$CLJS.m(f)?function(){var n=$CLJS.Z0(a,f);return $CLJS.F5(a,b,n,d)}():null;if($CLJS.m(l))return l;g=$CLJS.m(g)?Wsa(a,b,g,e):null;return $CLJS.m(g)?g:function u(q){return new $CLJS.Cf(null,function(){for(;;){var v=$CLJS.A(q);if(v){if($CLJS.re(v)){var x=$CLJS.$c(v),z=$CLJS.E(x),B=$CLJS.Ff(z);return function(){for(var I=0;;)if(I<
z){var R=$CLJS.ce(x,I),X=B,ba=$CLJS.U,ha=ba.l,oa=R,Fa=$CLJS.V.g(R);R=$CLJS.V.g(R);R=e.g?e.g(R):e.call(null,R);ba=ha.call(ba,oa,$CLJS.E0,$CLJS.P3,$CLJS.G([$CLJS.F3,Fa,$CLJS.L3,R]));X.add(ba);I+=1}else return!0}()?$CLJS.If($CLJS.Kf(B),u($CLJS.ad(v))):$CLJS.If($CLJS.Kf(B),null)}var H=$CLJS.C(v);return $CLJS.pf($CLJS.U.l(H,$CLJS.E0,$CLJS.P3,$CLJS.G([$CLJS.F3,$CLJS.V.g(H),$CLJS.L3,function(){var I=$CLJS.V.g(H);return e.g?e.g(I):e.call(null,I)}()])),u($CLJS.Ad(v)))}return null}},null,null)}($CLJS.D0.g($CLJS.PZ.g(c)))};
k8=function(a,b){var c=$CLJS.J(a,0,null);$CLJS.J(a,1,null);var d=$CLJS.J(a,2,null);switch(c instanceof $CLJS.N?c.S:null){case "field":if("number"===typeof d||"string"===typeof d)return $CLJS.F.h(d,$CLJS.Mk.g(b));throw $CLJS.Ej("unknown type of :field ref in lib.stage/ref-to?",new $CLJS.k(null,2,[$CLJS.pV,a,$CLJS.Gk,b],null));case "expression":return $CLJS.F.h(d,$CLJS.V.g(b));default:throw $CLJS.Ej("unknown clause in lib.stage/ref-to?",new $CLJS.k(null,2,[$CLJS.pV,a,$CLJS.Gk,b],null));}};
Ysa=function(a,b,c){var d=$CLJS.aQ.g($CLJS.W0(a,b));return $CLJS.m(d)?function g(f){return new $CLJS.Cf(null,function(){for(;;){var l=$CLJS.A(f);if(l){var n=l;if($CLJS.re(n)){var q=$CLJS.$c(n),u=$CLJS.E(q),v=$CLJS.Ff(u);return function(){for(var z=0;;)if(z<u){var B=$CLJS.ce(q,z);$CLJS.Jf(v,function(){var H=$CLJS.DW(function(X,ba){return function(ha){return k8(ha,ba)}}(z,B,q,u,v,n,l,d,d),d);if($CLJS.m(H)){var I=$CLJS.S5(H);H=$CLJS.Z7(H);H=$CLJS.Uf(H);H=$CLJS.M.h(H,$CLJS.bk);var R=B;I=$CLJS.m(I)?$CLJS.a8(R,
I):R;return $CLJS.m(H)?$CLJS.Y7(I,H):I}return B}());z+=1}else return!0}()?$CLJS.If($CLJS.Kf(v),g($CLJS.ad(n))):$CLJS.If($CLJS.Kf(v),null)}var x=$CLJS.C(n);return $CLJS.pf(function(){var z=$CLJS.DW(function(I){return function(R){return k8(R,I)}}(x,n,l,d,d),d);if($CLJS.m(z)){var B=$CLJS.S5(z);z=$CLJS.Z7(z);z=$CLJS.Uf(z);z=$CLJS.M.h(z,$CLJS.bk);var H=x;B=$CLJS.m(B)?$CLJS.a8(H,B):H;return $CLJS.m(z)?$CLJS.Y7(B,z):B}return x}(),g($CLJS.Ad(n)))}return null}},null,null)}(c):c};
e8=function(a){switch(arguments.length){case 2:return d8(arguments[0],arguments[1]);case 3:return l8(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};d8=function(a,b){return l8(a,b,$CLJS.e3())};
l8=function(a,b,c){var d=Qsa(a,b);if($CLJS.m(d))return d;a=$CLJS.f4(a,b);d=Usa(a,b,c);var e=Tsa(a,b,c);return $CLJS.m(d)?$CLJS.uh.h(d,e):$CLJS.m(e)?($CLJS.Ii(e),$CLJS.uh.j($CLJS.Rg,X7(function(f){return $CLJS.Mm.l(f,$CLJS.d4,$CLJS.G([$CLJS.E0,$CLJS.z4,$CLJS.L3]))}),$CLJS.wg.h(e,$7(a,b,c)))):$CLJS.wg.l(j8(a,b,new $CLJS.k(null,2,[$CLJS.s4,!1,$CLJS.w4,c],null)),h8(a,b,c),$CLJS.G([$7(a,b,c)]))};
m8=function m8(a){switch(arguments.length){case 2:return m8.h(arguments[0],arguments[1]);case 3:return m8.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};m8.h=function(a,b){return m8.j(a,-1,b)};m8.j=function(a,b,c){return $CLJS.B1.j(a,b,$CLJS.Ui.g(c))};m8.v=3;
$CLJS.f8=function f8(a){switch(arguments.length){case 1:return f8.g(arguments[0]);case 2:return f8.h(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};$CLJS.f8.g=function(a){return $CLJS.f8.h(a,-1)};
$CLJS.f8.h=function(a,b){var c=$CLJS.Vf($CLJS.hQ.g($CLJS.W0(a,b)));return null==c?null:$CLJS.uh.j($CLJS.Rg,$CLJS.xg.g(function(d){var e=$CLJS.b1(a,b,d),f=$CLJS.U.l,g=$CLJS.bH.g(e);return f.call($CLJS.U,W7(e,$CLJS.m(g)?g:$CLJS.yl),$CLJS.E0,$CLJS.c4,$CLJS.G([$CLJS.z4,$CLJS.MH.g($CLJS.$d(d))]))}),c)};$CLJS.f8.v=2;
$CLJS.b8=function b8(a){switch(arguments.length){case 1:return b8.g(arguments[0]);case 2:return b8.h(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};$CLJS.b8.g=function(a){return $CLJS.b8.h(a,-1)};$CLJS.b8.h=function(a,b){var c=$CLJS.Vf($CLJS.aQ.g($CLJS.W0(a,b)));return null==c?null:$CLJS.Ym.h(function(d){return $CLJS.U.j($CLJS.b1(a,b,d),$CLJS.E0,$CLJS.Z3)},c)};$CLJS.b8.v=2;
var g8=function g8(a){switch(arguments.length){case 1:return g8.g(arguments[0]);case 2:return g8.h(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};g8.g=function(a){return g8.h(a,-1)};g8.h=function(a,b){var c=$CLJS.Vf($CLJS.jR.g($CLJS.W0(a,b)));return null==c?null:$CLJS.Ym.h($CLJS.iA(Psa,a,b),c)};g8.v=2;
var rva=new $CLJS.N("metabase.lib.stage","source-field-id","metabase.lib.stage/source-field-id",-962431705),V9=new $CLJS.N("metabase.lib.stage","stage","metabase.lib.stage/stage",1448689281);$CLJS.Ypa={};$CLJS.tV($CLJS.UZ,V9);$CLJS.tV($CLJS.D_,V9);$CLJS.T3.o(null,$CLJS.UZ,function(a){return $CLJS.S3(a,new $CLJS.k(null,2,[$CLJS.hQ,$CLJS.hg($CLJS.Ym,$CLJS.T3),$CLJS.I_,$CLJS.hg($CLJS.Ym,$CLJS.T3)],null))});
$CLJS.F1.o(null,V9,function(a,b,c,d){var e=$CLJS.Uf(d);c=$CLJS.M.h(e,$CLJS.w4);d=$CLJS.M.h(e,$CLJS.s4);a=$CLJS.f4(a,b);e=$CLJS.Uf(e);var f=$CLJS.M.h(e,$CLJS.w4),g=$CLJS.M.h(e,$CLJS.u4),l=$CLJS.M.h(e,$CLJS.A4);e=$CLJS.wg.l(j8(a,b,e),$CLJS.m(l)?h8(a,b,f):null,$CLJS.G([$CLJS.m(g)?Osa(a,b,f):null]));return Ysa(a,b,$CLJS.wg.h(e,$CLJS.m(d)?Xsa(a,b,e,c):null))});$CLJS.a1.o(null,V9,function(a,b){return d8(a,b)});$CLJS.y1.o(null,$CLJS.D_,function(){return $CLJS.dI("Native query")});
var Zsa=new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.tQ,$CLJS.QZ,$CLJS.hQ,$CLJS.aQ,$CLJS.I_,$CLJS.tS,$CLJS.NV],null);
$CLJS.y1.o(null,$CLJS.UZ,function(a,b,c,d){var e=$CLJS.f4(a,b);a=$CLJS.Vf(function(){return $CLJS.Ov.h(", ",$CLJS.Xm.h($CLJS.bG,function(){return function l(g){return new $CLJS.Cf(null,function(){for(;;){var n=$CLJS.A(g);if(n){if($CLJS.re(n)){var q=$CLJS.$c(n),u=$CLJS.E(q),v=$CLJS.Ff(u);a:for(var x=0;;)if(x<u){var z=$CLJS.ce(q,x);z=m8.j(e,b,z);v.add(z);x+=1}else{q=!0;break a}return q?$CLJS.If($CLJS.Kf(v),l($CLJS.ad(n))):$CLJS.If($CLJS.Kf(v),null)}v=$CLJS.C(n);return $CLJS.pf(m8.j(e,b,v),l($CLJS.Ad(n)))}return null}},
null,null)}(Zsa)}()))}());if($CLJS.m(a))return a;a=$CLJS.E5(e,b);return $CLJS.m(a)?$CLJS.c1.F(e,a,$CLJS.W0(e,a),d):null});