var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");
'use strict';var G4,I4,L4,R4,mqa,X4;G4=new $CLJS.N("metabase.lib.types.constants","primary-key","metabase.lib.types.constants/primary-key",1371730630);$CLJS.H4=new $CLJS.N(null,"exclude","exclude",-1230250334);I4=new $CLJS.N("metabase.lib.types.constants","unknown","metabase.lib.types.constants/unknown",-886227696);L4=new $CLJS.N("metabase.lib.types.constants","string-like","metabase.lib.types.constants/string-like",776315654);
$CLJS.M4=new $CLJS.N("metabase.lib.types.constants","string","metabase.lib.types.constants/string",-2076057579);$CLJS.N4=new $CLJS.N("metabase.lib.types.constants","string_like","metabase.lib.types.constants/string_like",-1907279613);$CLJS.O4=new $CLJS.N("metabase.lib.types.constants","number","metabase.lib.types.constants/number",1385378285);$CLJS.Q4=new $CLJS.N("metabase.lib.types.constants","location","metabase.lib.types.constants/location",1597944369);
R4=new $CLJS.N("metabase.lib.types.constants","foreign-key","metabase.lib.types.constants/foreign-key",7703392);$CLJS.S4=new $CLJS.N("metabase.lib.types.constants","category","metabase.lib.types.constants/category",-644284871);$CLJS.T4=new $CLJS.N("metabase.lib.types.constants","foreign_key","metabase.lib.types.constants/foreign_key",-1061052182);$CLJS.U4=new $CLJS.N("metabase.lib.types.constants","boolean","metabase.lib.types.constants/boolean",-2002690319);
$CLJS.V4=new $CLJS.N(null,"include","include",153360230);$CLJS.W4=new $CLJS.N("metabase.lib.types.constants","primary_key","metabase.lib.types.constants/primary_key",1915474107);mqa=new $CLJS.N("metabase.lib.types.constants","dimension","metabase.lib.types.constants/dimension",728254877);X4=new $CLJS.N("metabase.lib.types.constants","entity","metabase.lib.types.constants/entity",-64775675);$CLJS.$4=new $CLJS.N("metabase.lib.types.constants","coordinate","metabase.lib.types.constants/coordinate",-565327840);
$CLJS.j5=new $CLJS.N("metabase.lib.types.constants","temporal","metabase.lib.types.constants/temporal",896988859);$CLJS.n5=new $CLJS.N("metabase.lib.types.constants","integer","metabase.lib.types.constants/integer",1592888555);var c2=$CLJS.Rb(function(a,b){var c=$CLJS.Ai(b);a[c]=b;return a},{},$CLJS.rn.g($CLJS.yg($CLJS.tn,$CLJS.G([new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.yl,$CLJS.il,$CLJS.yf],null)]))));$CLJS.Ra("metabase.lib.types.constants.name__GT_type",c2);$CLJS.Ra("metabase.lib.types.constants.key_number",$CLJS.O4);$CLJS.Ra("metabase.lib.types.constants.key_string",$CLJS.M4);$CLJS.Ra("metabase.lib.types.constants.key_string_like",L4);$CLJS.Ra("metabase.lib.types.constants.key_boolean",$CLJS.U4);
$CLJS.Ra("metabase.lib.types.constants.key_temporal",$CLJS.j5);$CLJS.Ra("metabase.lib.types.constants.key_location",$CLJS.Q4);$CLJS.Ra("metabase.lib.types.constants.key_coordinate",$CLJS.$4);$CLJS.Ra("metabase.lib.types.constants.key_foreign_KEY",R4);$CLJS.Ra("metabase.lib.types.constants.key_primary_KEY",G4);$CLJS.Ra("metabase.lib.types.constants.key_summable",$CLJS.hn);$CLJS.Ra("metabase.lib.types.constants.key_scope",$CLJS.Lk);$CLJS.Ra("metabase.lib.types.constants.key_category",$CLJS.S4);
$CLJS.Ra("metabase.lib.types.constants.key_unknown",I4);
$CLJS.d2=$CLJS.Yh([$CLJS.$4,$CLJS.N4,X4,$CLJS.T4,$CLJS.n5,$CLJS.Lk,$CLJS.O4,$CLJS.Q4,$CLJS.U4,$CLJS.M4,$CLJS.hn,$CLJS.S4,$CLJS.W4,$CLJS.j5,mqa],[new $CLJS.k(null,1,[$CLJS.Ol,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.sl],null)],null),new $CLJS.k(null,1,[$CLJS.rk,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.el],null)],null),new $CLJS.k(null,1,[$CLJS.Ol,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Ze,$CLJS.zf,$CLJS.$k],null)],null),new $CLJS.k(null,1,[$CLJS.Ol,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.Ze],null)],null),new $CLJS.k(null,
1,[$CLJS.rk,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.ol],null)],null),new $CLJS.k(null,2,[$CLJS.V4,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.O4,$CLJS.j5,$CLJS.S4,X4,$CLJS.M4],null),$CLJS.H4,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.Q4],null)],null),new $CLJS.k(null,2,[$CLJS.rk,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.Jl],null),$CLJS.Ol,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.Jl],null)],null),new $CLJS.k(null,1,[$CLJS.Ol,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.yk],null)],null),new $CLJS.k(null,1,[$CLJS.rk,new $CLJS.S(null,1,5,
$CLJS.T,[$CLJS.Gl],null)],null),new $CLJS.k(null,2,[$CLJS.rk,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.fn],null),$CLJS.Ol,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.fn,$CLJS.Nl],null)],null),new $CLJS.k(null,2,[$CLJS.V4,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.O4],null),$CLJS.H4,new $CLJS.S(null,3,5,$CLJS.T,[X4,$CLJS.Q4,$CLJS.j5],null)],null),new $CLJS.k(null,3,[$CLJS.rk,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.Gl],null),$CLJS.Ol,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.Nl],null),$CLJS.V4,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.Q4],
null)],null),new $CLJS.k(null,1,[$CLJS.Ol,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.zf],null)],null),new $CLJS.k(null,2,[$CLJS.rk,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.Ql],null),$CLJS.Ol,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.Ql],null)],null),new $CLJS.k(null,1,[$CLJS.V4,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.j5,$CLJS.S4,X4],null)],null)]);
module.exports={key_scope:$CLJS.Lk,key_summable:$CLJS.hn,key_location:$CLJS.Q4,key_coordinate:$CLJS.$4,key_boolean:$CLJS.U4,key_temporal:$CLJS.j5,key_category:$CLJS.S4,key_string:$CLJS.M4,key_foreign_KEY:R4,key_primary_KEY:G4,key_string_like:L4,key_unknown:I4,key_number:$CLJS.O4,name__GT_type:c2};