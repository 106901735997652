var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./medley.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var EW,FW,sla,HW,tla,ula,vla,YW,WW;$CLJS.DW=function(a,b){return $CLJS.Rb(function(c,d){return $CLJS.m(a.g?a.g(d):a.call(null,d))?$CLJS.Jd(d):null},null,b)};EW=function(a,b){$CLJS.J(a,0,null);$CLJS.J(a,1,null);$CLJS.J(a,2,null);a=$CLJS.J(a,3,null);b=$CLJS.XH(b,$CLJS.jJ)?$CLJS.zW:$CLJS.XH(b,$CLJS.ML)?$CLJS.uW:$CLJS.XH(b,$CLJS.nK)?$CLJS.xW:null;return $CLJS.m(b)?$CLJS.vV(b,a):!0};
FW=function(a){a=$CLJS.A(a);$CLJS.C(a);a=$CLJS.D(a);$CLJS.C(a);a=$CLJS.D(a);a=$CLJS.lj(function(d){return $CLJS.XH($CLJS.HL(d),$CLJS.HJ)},a);var b=$CLJS.Uf(a);a=$CLJS.M.h(b,!1);b=$CLJS.M.h(b,!0);if($CLJS.qi.h($CLJS.E(a),1))return"Temporal arithmetic expression must contain exactly one non-interval value";if(1>$CLJS.E(b))return"Temporal arithmetic expression must contain at least one :interval";var c=$CLJS.HL($CLJS.C(a));return $CLJS.ag(function(d){$CLJS.J(d,0,null);$CLJS.J(d,1,null);$CLJS.J(d,2,null);
var e=$CLJS.J(d,3,null);return $CLJS.m(EW(d,c))?null:["Cannot add a ",$CLJS.p.g(e)," interval to a ",$CLJS.p.g(c)," expression"].join("")},b)};
sla=function(a){return new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.Qj,new $CLJS.k(null,1,[$CLJS.ql,[$CLJS.p.g(a)," clause with a temporal expression and one or more :interval clauses"].join("")],null),new $CLJS.S(null,6,5,$CLJS.T,[$CLJS.iu,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.om,a],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.km,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qq,$CLJS.XL],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hu,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.km,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qq,WW],
null)],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.km,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qq,$CLJS.rM],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hu,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.km,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qq,WW],null)],null)],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.ru,new $CLJS.k(null,1,[$CLJS.Iv,function(b){b=$CLJS.Uf(b);b=$CLJS.M.h(b,$CLJS.xl);return["Invalid ",$CLJS.p.g(a)," clause: ",$CLJS.p.g(FW(b))].join("")}],null),$CLJS.fg(FW)],null)],null)};
HW=function(a){var b=$CLJS.T,c=sla(a);a=new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.iu,new $CLJS.k(null,1,[$CLJS.ql,[$CLJS.p.g(a)," clause with numeric args"].join("")],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.om,a],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.km,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qq,$CLJS.XL],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.hu,new $CLJS.k(null,1,[$CLJS.wk,2],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.km,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qq,$CLJS.IM],null)],null)],null)],
null);return new $CLJS.S(null,3,5,b,[$CLJS.Hk,c,a],null)};tla=function(a){return $CLJS.We($CLJS.sV,$CLJS.xg.h(function(b){var c=$CLJS.HL(b),d=$CLJS.XH(c,$CLJS.vM);b=d?$CLJS.vV($CLJS.pW,b):d;return $CLJS.m(b)?$CLJS.Jl:c},a))};ula=function(a){a=$CLJS.DW(function(b){return!$CLJS.XH(b,$CLJS.HJ)},$CLJS.xg.h($CLJS.HL,a));return $CLJS.XH(a,$CLJS.vM)?$CLJS.Ql:a};vla=function(a){return $CLJS.m($CLJS.ag(function(b){return $CLJS.XH($CLJS.HL(b),$CLJS.HJ)},a))?ula(a):tla(a)};
YW=new $CLJS.N("metabase.lib.schema.expression.arithmetic","args.numbers","metabase.lib.schema.expression.arithmetic/args.numbers",-179216621);$CLJS.ZW=new $CLJS.N("lib.type-of","type-is-type-of-arithmetic-args","lib.type-of/type-is-type-of-arithmetic-args",-626871615);WW=new $CLJS.N("mbql.clause","interval","mbql.clause/interval",-990446354);$CLJS.pL(new $CLJS.N("metabase.lib.schema.expression.arithmetic","args.temporal","metabase.lib.schema.expression.arithmetic/args.temporal",1338604923),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Qj,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.lu,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hM,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.km,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qq,$CLJS.rM],null)],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[new $CLJS.N(null,"intervals","intervals",2096054013),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.tm,WW],
null)],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.ru,new $CLJS.k(null,1,[$CLJS.ql,"Temporal arithmetic expression with valid interval units for the expression type"],null),function(a){var b=$CLJS.A(a);a=$CLJS.C(b);b=$CLJS.D(b);var c=$CLJS.HL(a);return $CLJS.$f(function(d){return EW(d,c)},b)}],null)],null));
$CLJS.pL(YW,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.hu,new $CLJS.k(null,1,[$CLJS.wk,2],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.km,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qq,$CLJS.IM],null)],null)],null));$CLJS.AV($CLJS.tm,HW($CLJS.tm));$CLJS.AV($CLJS.bv,HW($CLJS.bv));$CLJS.GV($CLJS.um,$CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.pl,YW],null)]));$CLJS.GV($CLJS.zL,$CLJS.G([$CLJS.bv,$CLJS.fL,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.pl,YW],null)]));
for(var VW=$CLJS.A(new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.tm,$CLJS.bv,$CLJS.um],null)),XW=null,$W=0,aX=0;;)if(aX<$W){var Ala=XW.$(null,aX);$CLJS.tV(Ala,$CLJS.ZW);aX+=1}else{var cX=$CLJS.A(VW);if(cX){var dX=cX;if($CLJS.re(dX)){var fX=$CLJS.$c(dX),Dla=$CLJS.ad(dX),Bla=fX,Cla=$CLJS.E(fX);VW=Dla;XW=Bla;$W=Cla}else{var Ela=$CLJS.C(dX);$CLJS.tV(Ela,$CLJS.ZW);VW=$CLJS.D(dX);XW=null;$W=0}aX=0}else break}$CLJS.GL.o(null,$CLJS.ZW,function(a){a=$CLJS.A(a);$CLJS.C(a);a=$CLJS.D(a);$CLJS.C(a);a=$CLJS.D(a);return vla(a)});
$CLJS.EV($CLJS.PI,$CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.km,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qq,$CLJS.IM],null)],null)]));$CLJS.tV($CLJS.PI,$CLJS.BM);
for(var gX=$CLJS.A(new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.BL,$CLJS.uI,$CLJS.KJ],null)),hX=null,iX=0,jX=0;;)if(jX<iX){var Fla=hX.$(null,jX);$CLJS.EV(Fla,$CLJS.G([$CLJS.bv,$CLJS.fL,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.km,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qq,$CLJS.IM],null)],null)]));jX+=1}else{var kX=$CLJS.A(gX);if(kX){var lX=kX;if($CLJS.re(lX)){var mX=$CLJS.$c(lX),Hla=$CLJS.ad(lX),Ila=mX,Gla=$CLJS.E(mX);gX=Hla;hX=Ila;iX=Gla}else{var Jla=$CLJS.C(lX);$CLJS.EV(Jla,$CLJS.G([$CLJS.bv,$CLJS.fL,new $CLJS.S(null,
2,5,$CLJS.T,[$CLJS.km,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qq,$CLJS.IM],null)],null)]));gX=$CLJS.D(lX);hX=null;iX=0}jX=0}else break}
for(var nX=$CLJS.A(new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.FI,$CLJS.bK,$CLJS.qK],null)),oX=null,pX=0,qX=0;;)if(qX<pX){var Kla=oX.$(null,qX);$CLJS.EV(Kla,$CLJS.G([$CLJS.bv,$CLJS.ol,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.km,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qq,$CLJS.IM],null)],null)]));qX+=1}else{var rX=$CLJS.A(nX);if(rX){var sX=rX;if($CLJS.re(sX)){var tX=$CLJS.$c(sX),Lla=$CLJS.ad(sX),Mla=tX,Nla=$CLJS.E(tX);nX=Lla;oX=Mla;pX=Nla}else{var Ola=$CLJS.C(sX);$CLJS.EV(Ola,$CLJS.G([$CLJS.bv,$CLJS.ol,new $CLJS.S(null,
2,5,$CLJS.T,[$CLJS.km,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qq,$CLJS.IM],null)],null)]));nX=$CLJS.D(sX);oX=null;pX=0}qX=0}else break}$CLJS.EV($CLJS.II,$CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.km,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qq,$CLJS.IM],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.km,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qq,$CLJS.IM],null)],null)]));
$CLJS.GL.o(null,$CLJS.II,function(a){$CLJS.J(a,0,null);$CLJS.J(a,1,null);var b=$CLJS.J(a,2,null);a=$CLJS.J(a,3,null);return $CLJS.XH($CLJS.HL(b),$CLJS.ol)&&$CLJS.XH($CLJS.HL(a),$CLJS.ol)?$CLJS.ol:$CLJS.fL});