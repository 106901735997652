var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.shared.formatting.internal.numbers.js");require("./metabase.shared.formatting.internal.numbers_core.js");require("./metabase.util.js");
'use strict';var Y4=function(a){var b=$CLJS.pn.l;var c=$CLJS.Uf(a);var d=$CLJS.M.h(c,$CLJS.B7);c=$CLJS.M.h(c,$CLJS.C7);if($CLJS.m($CLJS.m(d)?$CLJS.F.h(c,"currency"):d)){c=$CLJS.x7.g;d=$CLJS.Ui.g(d);var e=$CLJS.r($CLJS.J4);d=e.g?e.g(d):e.call(null,d);c=c.call($CLJS.x7,d);c=new $CLJS.k(null,2,[$CLJS.A7,c,$CLJS.D7,c],null)}else c=new $CLJS.k(null,1,[$CLJS.D7,2],null);b=b.call($CLJS.pn,$CLJS.G([c,a]));return $CLJS.m($CLJS.E7.g(a))?$CLJS.U.l($CLJS.Mm.h(b,$CLJS.E7),$CLJS.D7,$CLJS.E7.g(a),$CLJS.G([$CLJS.A7,
$CLJS.E7.g(a)])):b},Z4=function(a,b){if(null!=a&&null!=a.vf)a=a.vf(a,b);else{var c=Z4[$CLJS.Na(null==a?null:a)];if(null!=c)a=c.h?c.h(a,b):c.call(null,a,b);else if(c=Z4._,null!=c)a=c.h?c.h(a,b):c.call(null,a,b);else throw $CLJS.Ob("NumberFormatter.format-number-basic",a);}return a},zqa=function(a,b){var c=$CLJS.UF(a.formatToParts(b),$CLJS.G([new $CLJS.k(null,1,[$CLJS.lk,!0],null)]));a=$CLJS.m($CLJS.ag(function(d){return $CLJS.F.h($CLJS.bl.g(d),"exponentMinusSign")},c))?c:function(){var d=$CLJS.Hi(function(f){return $CLJS.qi.h($CLJS.bl.g(f),
"exponentInteger")},c),e=$CLJS.J(d,0,null);d=$CLJS.J(d,1,null);return $CLJS.wg.l(e,new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.k(null,2,[$CLJS.bl,"exponentPlusSign",$CLJS.xl,"+"],null)],null),$CLJS.G([d]))}();return $CLJS.Q.h($CLJS.p,$CLJS.xg.h(function(d){var e=$CLJS.Uf(d);d=$CLJS.M.h(e,$CLJS.bl);e=$CLJS.M.h(e,$CLJS.xl);switch(d){case "exponentSeparator":return"e";default:return e}},a))},Aqa=function(a,b){switch($CLJS.C7.g(b)){case "scientific":return function(c){return zqa(a,c)};default:return function(c){return a.format(c)}}},
G7=function(a,b,c,d,e){this.options=a;this.ah=b;this.dh=c;this.Ke=d;this.gk=e;this.C=393216;this.K=0},Bqa=function(a,b){return $CLJS.Q.h($CLJS.p,function(){return function e(d){return new $CLJS.Cf(null,function(){for(var f=d;;)if(f=$CLJS.A(f)){if($CLJS.re(f)){var g=$CLJS.$c(f),l=$CLJS.E(g),n=$CLJS.Ff(l);return function(){for(var v=0;;)if(v<l){var x=$CLJS.ce(g,v),z=$CLJS.Uf(x);x=$CLJS.M.h(z,$CLJS.bl);z=$CLJS.M.h(z,$CLJS.xl);var B=new $CLJS.ti(null,new $CLJS.k(null,2,["currency",null,"integer",null],
null),null);B=B.g?B.g(x):B.call(null,x);$CLJS.m(B)&&(x=$CLJS.F.h(x,"integer")?b:z,n.add(x));v+=1}else return!0}()?$CLJS.If($CLJS.Kf(n),e($CLJS.ad(f))):$CLJS.If($CLJS.Kf(n),null)}var q=$CLJS.C(f);q=$CLJS.Uf(q);var u=$CLJS.M.h(q,$CLJS.bl);q=$CLJS.M.h(q,$CLJS.xl);if($CLJS.m(function(){var v=new $CLJS.ti(null,new $CLJS.k(null,2,["currency",null,"integer",null],null),null);return v.g?v.g(u):v.call(null,u)}()))return $CLJS.pf($CLJS.F.h(u,"integer")?b:q,e($CLJS.Ad(f)));f=$CLJS.Ad(f)}else return null},null,
null)}($CLJS.UF(a.ah.formatToParts(1),$CLJS.G([$CLJS.lk,!0])))}())},a5=function(a){var b=$CLJS.F.h($CLJS.C7.g(a),"currency")?2:null;b=new Intl.NumberFormat("en",$CLJS.sn($CLJS.kG($CLJS.Hb,$CLJS.Yh([$CLJS.esa,$CLJS.Wra,$CLJS.Zra,$CLJS.asa,$CLJS.hsa,$CLJS.B7,$CLJS.$ra,$CLJS.csa,$CLJS.Xra,$CLJS.Yra],[$CLJS.D7.h(a,b),$CLJS.F7.g(a),$CLJS.Vra.g(a),$CLJS.dsa.g(a),$CLJS.F.h($CLJS.C7.g(a),"scientific")?null:$CLJS.C7.h(a,"decimal"),$CLJS.B7.g(a),!0,$CLJS.A7.h(a,b),$CLJS.F.h($CLJS.C7.g(a),"scientific")?"scientific":
null,$CLJS.gsa.g(a)]))));var c=$CLJS.F7.g(a);var d=$CLJS.B7.g(a);c=$CLJS.m(d)?null==c||$CLJS.F.h(c,"symbol"):d;d=Aqa(b,a);return new G7(a,b,c,d,$CLJS.P)},b5=function(a,b){return a5(Y4(b)).vf(null,a)},d5=function(a,b){var c=$CLJS.Mm.l(b,$CLJS.eI,$CLJS.G([$CLJS.C7])),d=Math.abs(a);if(0===a)return"0";if(1E3>d)return $CLJS.c5.h?$CLJS.c5.h(a,c):$CLJS.c5.call(null,a,c);b=$CLJS.C($CLJS.Ag(function(f){return d>=$CLJS.C(f)},Cqa));var e=$CLJS.J(b,0,null);b=$CLJS.J(b,1,null);return[$CLJS.p.g(function(){var f=
a/e,g=$CLJS.pn.l($CLJS.G([c,new $CLJS.k(null,2,[$CLJS.A7,1,$CLJS.D7,1],null)]));return $CLJS.c5.h?$CLJS.c5.h(f,g):$CLJS.c5.call(null,f,g)}()),$CLJS.p.g(b)].join("")},Dqa=function(a,b){var c=Y4(b);if($CLJS.m(isa.g(c)))b=isa.g(c);else if($CLJS.Fb($CLJS.E7.g(c))&&$CLJS.Fb($CLJS.A7.g(c))&&$CLJS.qi.h($CLJS.C7.g(c),"currency")&&Math.abs(a)<($CLJS.F.h($CLJS.C7.g(c),"percent")?.01:1)){b=$CLJS.U.j;var d=$CLJS.Mm.h(c,$CLJS.D7);c=$CLJS.gsa.h(c,0);b=a5(b.call($CLJS.U,d,$CLJS.Vra,2>c?2:c))}else b=a5(c);return Z4(b,
a)};G7.prototype.N=function(a,b){return new G7(this.options,this.ah,this.dh,this.Ke,b)};G7.prototype.M=function(){return this.gk};
G7.prototype.vf=function(a,b){a=this.Ke.g?this.Ke.g(b):this.Ke.call(null,b);var c=$CLJS.fsa.g(this.options);$CLJS.m($CLJS.m(c)?$CLJS.qi.h(c,".,"):c)&&(b=$CLJS.C(c),c=$CLJS.$d(c),a=$CLJS.vv(a,/[\.,]/,new $CLJS.k(null,2,[",",$CLJS.m(c)?c:"",".",b],null)));$CLJS.m(this.dh)&&(b=$CLJS.B7.g(this.options),c=$CLJS.K4(b),a=$CLJS.vv($CLJS.vv(a,[$CLJS.Ai(b)," "].join(""),c),$CLJS.Ai(b),c));return a};
var isa=new $CLJS.N(null,"number-formatter","number-formatter",929788393),jsa=new $CLJS.N(null,"scale","scale",-230427353),ksa=new $CLJS.N(null,"negative-in-parentheses","negative-in-parentheses",-1676084141),lsa=new $CLJS.N(null,"scientific","scientific",316285837);var h5=new $CLJS.k(null,1,[$CLJS.F7,"symbol"],null),Fqa=$CLJS.sn(h5),Cqa=new $CLJS.S(null,4,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[1E12,"T"],null),new $CLJS.S(null,2,5,$CLJS.T,[1E9,"B"],null),new $CLJS.S(null,2,5,$CLJS.T,[1E6,"M"],null),new $CLJS.S(null,2,5,$CLJS.T,[1E3,"k"],null)],null),i5=function(){var a=$CLJS.ij.g($CLJS.P),b=$CLJS.ij.g($CLJS.P),c=$CLJS.ij.g($CLJS.P),d=$CLJS.ij.g($CLJS.P),e=$CLJS.M.j($CLJS.P,$CLJS.Wk,$CLJS.rj.A?$CLJS.rj.A():$CLJS.rj.call(null));return new $CLJS.Aj($CLJS.$i.h("metabase.shared.formatting.numbers",
"format-number-compact*"),function(f,g){f=$CLJS.Uf(g);return $CLJS.M.h(f,$CLJS.C7)},$CLJS.Uj,e,a,b,c,d)}();i5.o(null,$CLJS.Uj,function(a,b){return d5(a,b)});i5.o(null,"percent",function(a,b){return[$CLJS.p.g(d5(100*a,b)),"%"].join("")});i5.o(null,"currency",function(a,b){b=$CLJS.pn.l($CLJS.G([b,h5]));var c=a5(b);return 1E3>Math.abs(a)?c.vf(null,a):Bqa(c,d5(a,b))});i5.o(null,"scientific",function(a,b){return b5(a,$CLJS.pn.l($CLJS.G([b,new $CLJS.k(null,2,[$CLJS.D7,1,$CLJS.A7,1],null)])))});
$CLJS.c5=function c5(a,b){b=$CLJS.tG(b);var d=$CLJS.Uf(b);b=$CLJS.M.h(d,$CLJS.eI);var e=$CLJS.M.h(d,ksa),f=$CLJS.M.h(d,$CLJS.C7),g=$CLJS.M.h(d,jsa);return $CLJS.m($CLJS.m(g)?!isNaN(g):g)?(b=g*a,f=$CLJS.Mm.h(d,jsa),c5.h?c5.h(b,f):c5.call(null,b,f)):$CLJS.m(function(){var l=0>a;return l?e:l}())?["(",$CLJS.p.g(function(){var l=-a,n=$CLJS.U.j(d,ksa,!1);return c5.h?c5.h(l,n):c5.call(null,l,n)}()),")"].join(""):$CLJS.m(b)?i5.h(a,Y4($CLJS.Mm.h(d,$CLJS.eI))):$CLJS.F.h($CLJS.Ui.g(f),lsa)?b5(a,d):Dqa(a,d)};
module.exports={compact_currency_options_js:Fqa,format_number:$CLJS.c5};