var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.options.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");require("./metabase.util.malli.registry.js");
'use strict';var uoa,S0,voa,T0,U0,n1,Tpa,Upa,t4,i4,v4,Vpa,l4;uoa=function(a){return $CLJS.A(a)?a.normalize("NFKD").replace(RegExp("[̀-ͯ]","gu"),""):null};S0=function(a,b){return 128>a.charCodeAt(0)?$CLJS.Je($CLJS.Rea,a)?a:"_":$CLJS.m(b)?encodeURIComponent(a):a};
voa=function(a){var b=$CLJS.Uf(new $CLJS.k(null,1,[$CLJS.mH,!0],null)),c=$CLJS.M.h(b,$CLJS.ffa),d=$CLJS.M.h(b,$CLJS.mH);return $CLJS.A(a)?(b=$CLJS.Ov.g(function(){return function g(f){return new $CLJS.Cf(null,function(){for(;;){var l=$CLJS.A(f);if(l){if($CLJS.re(l)){var n=$CLJS.$c(l),q=$CLJS.E(n),u=$CLJS.Ff(q);a:for(var v=0;;)if(v<q){var x=$CLJS.ce(n,v);x=S0(x,$CLJS.Fb(d));u.add(x);v+=1}else{n=!0;break a}return n?$CLJS.If($CLJS.Kf(u),g($CLJS.ad(l))):$CLJS.If($CLJS.Kf(u),null)}u=$CLJS.C(l);return $CLJS.pf(S0(u,
$CLJS.Fb(d)),g($CLJS.Ad(l)))}return null}},null,null)}(uoa($CLJS.cG(a)))}()),$CLJS.m(c)?$CLJS.Ov.g($CLJS.pg(c,b)):b):null};$CLJS.V0=function(a,b){var c=0>b?$CLJS.E(a)+b:b;if(c>=$CLJS.E(a)||0>c)throw $CLJS.Ej($CLJS.cI("Stage {0} does not exist",$CLJS.G([b])),new $CLJS.k(null,1,[$CLJS.poa,$CLJS.E(a)],null));return c};$CLJS.W0=function(a,b){a=$CLJS.k0(a);a=$CLJS.Uf(a);a=$CLJS.M.h(a,$CLJS.IZ);return $CLJS.M.h($CLJS.Sg(a),$CLJS.V0(a,b))};
$CLJS.X0=function(a,b,c,d){a=$CLJS.k0(a);a=$CLJS.Uf(a);var e=$CLJS.M.h(a,$CLJS.IZ);b=$CLJS.V0(e,b);c=$CLJS.Q.R($CLJS.Zm,$CLJS.Sg(e),b,c,d);return $CLJS.U.j(a,$CLJS.IZ,c)};T0=function(a,b){if(null!=a&&null!=a.Fh)a=a.Fh(a,b);else{var c=T0[$CLJS.Na(null==a?null:a)];if(null!=c)a=c.h?c.h(a,b):c.call(null,a,b);else if(c=T0._,null!=c)a=c.h?c.h(a,b):c.call(null,a,b);else throw $CLJS.Ob("MetadataProvider.table",a);}return a};
U0=function(a,b){if(null!=a&&null!=a.Ug)a=a.Ug(a,b);else{var c=U0[$CLJS.Na(null==a?null:a)];if(null!=c)a=c.h?c.h(a,b):c.call(null,a,b);else if(c=U0._,null!=c)a=c.h?c.h(a,b):c.call(null,a,b);else throw $CLJS.Ob("MetadataProvider.card",a);}return a};$CLJS.Y0=function(a){return $CLJS.v0(a)?a:$CLJS.OZ.g(a)};$CLJS.Z0=function(a,b){return T0($CLJS.Y0(a),b)};$CLJS.$0=function(a,b){return U0($CLJS.Y0(a),b)};$CLJS.b1=function(a,b,c){return $CLJS.a1.j(a,b,c)};
n1=function(a,b,c){var d=$CLJS.b1(a,b,c);return $CLJS.pn.l($CLJS.G([$CLJS.ri(d,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.V,$CLJS.oJ,$CLJS.Ol],null)),function(){var e=$CLJS.c1.F(a,b,c,$CLJS.e4);return $CLJS.m(e)?new $CLJS.k(null,1,[$CLJS.h4,e],null):null}(),function(){var e=$CLJS.Wm.h($CLJS.rk,$CLJS.bH)(d);return $CLJS.m(e)?new $CLJS.k(null,1,[$CLJS.rk,e],null):null}(),function(){var e=$CLJS.J0.g(d);return $CLJS.m(e)?new $CLJS.k(null,1,[$CLJS.K0,$CLJS.f1.j(a,b,$CLJS.Ae(e)?$CLJS.Z0(a,e):"string"===typeof e?
$CLJS.$0(a,$CLJS.l0(e)):null)],null):null}(),function(){var e=$CLJS.E0.g(d);return $CLJS.m(e)?new $CLJS.k(null,4,[i4,$CLJS.F.h(e,$CLJS.G3),$CLJS.j4,$CLJS.F.h(e,$CLJS.K3),l4,$CLJS.F.h(e,$CLJS.I3),$CLJS.m4,$CLJS.F.h(e,$CLJS.J3)],null):null}(),function(){var e=$CLJS.M3.g(d);return $CLJS.m(e)?new $CLJS.k(null,1,[$CLJS.n4,e],null):null}(),$CLJS.ri(d,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.o4,$CLJS.p4],null))]))};$CLJS.q4=new $CLJS.N(null,"metadata-fn","metadata-fn",915781389);
$CLJS.r4=new $CLJS.N(null,"direction","direction",-633359395);$CLJS.m4=new $CLJS.N(null,"is-implicitly-joinable","is-implicitly-joinable",2054926844);$CLJS.o4=new $CLJS.N(null,"breakout-position","breakout-position",-760153191);$CLJS.s4=new $CLJS.N(null,"include-implicitly-joinable?","include-implicitly-joinable?",-772701866);$CLJS.n4=new $CLJS.N(null,"selected","selected",574897764);Tpa=new $CLJS.N(null,"is-order-by-column","is-order-by-column",1320802549);
Upa=new $CLJS.w("metabase.lib.metadata.calculation","display-name-method","metabase.lib.metadata.calculation/display-name-method",37675198,null);t4=new $CLJS.N("metabase.lib.metadata.calculation","display-info","metabase.lib.metadata.calculation/display-info",-2072755534);$CLJS.u4=new $CLJS.N(null,"include-joined?","include-joined?",-410632824);i4=new $CLJS.N(null,"is-from-previous-stage","is-from-previous-stage",956780376);v4=new $CLJS.N(null,"is-source-table","is-source-table",1559075056);
$CLJS.w4=new $CLJS.N(null,"unique-name-fn","unique-name-fn",-755402870);$CLJS.j4=new $CLJS.N(null,"is-from-join","is-from-join",-1107917905);$CLJS.x4=new $CLJS.N(null,"requires-column","requires-column",934105295);$CLJS.p4=new $CLJS.N(null,"order-by-position","order-by-position",-1307229997);Vpa=new $CLJS.N(null,"is-breakout-column","is-breakout-column",46784229);$CLJS.h4=new $CLJS.N(null,"long-display-name","long-display-name",1550784464);$CLJS.e4=new $CLJS.N(null,"long","long",-171452093);
$CLJS.y4=new $CLJS.N(null,"short-name","short-name",-1767085022);$CLJS.z4=new $CLJS.N("lib","source-uuid","lib/source-uuid",1828897581);$CLJS.A4=new $CLJS.N(null,"include-expressions?","include-expressions?",-1817639288);l4=new $CLJS.N(null,"is-calculated","is-calculated",-86687269);$CLJS.y1=function(){var a=$CLJS.ij.g($CLJS.P),b=$CLJS.ij.g($CLJS.P),c=$CLJS.ij.g($CLJS.P),d=$CLJS.ij.g($CLJS.P),e=$CLJS.M.j(new $CLJS.k(null,1,[$CLJS.Wk,$CLJS.JH],null),$CLJS.Wk,$CLJS.rj.A?$CLJS.rj.A():$CLJS.rj.call(null));return new $CLJS.Aj($CLJS.$i.h("metabase.lib.metadata.calculation","display-name-method"),function(f,g,l){return $CLJS.IH(l)},$CLJS.Uj,e,a,b,c,d)}();
$CLJS.z1=function(){var a=$CLJS.ij.g($CLJS.P),b=$CLJS.ij.g($CLJS.P),c=$CLJS.ij.g($CLJS.P),d=$CLJS.ij.g($CLJS.P),e=$CLJS.M.j(new $CLJS.k(null,1,[$CLJS.Wk,$CLJS.JH],null),$CLJS.Wk,$CLJS.rj.A?$CLJS.rj.A():$CLJS.rj.call(null));return new $CLJS.Aj($CLJS.$i.h("metabase.lib.metadata.calculation","column-name-method"),function(f,g,l){return $CLJS.IH(l)},$CLJS.Uj,e,a,b,c,d)}();
$CLJS.c1=function c1(a){switch(arguments.length){case 2:return c1.h(arguments[0],arguments[1]);case 3:return c1.j(arguments[0],arguments[1],arguments[2]);case 4:return c1.F(arguments[0],arguments[1],arguments[2],arguments[3]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};$CLJS.Ra("metabase.lib.metadata.calculation.display_name",$CLJS.c1);$CLJS.c1.h=function(a,b){return $CLJS.c1.j(a,-1,b)};$CLJS.c1.j=function(a,b,c){return $CLJS.c1.F(a,b,c,$CLJS.Uj)};
$CLJS.c1.F=function(a,b,c,d){var e=$CLJS.oJ.g($CLJS.KH(c));if($CLJS.m(e))return e;try{return $CLJS.y1.F(a,b,c,d)}catch(f){if(f instanceof Error)throw b=f,$CLJS.Dj($CLJS.cI("Error calculating display name for {0}: {1}",$CLJS.G([$CLJS.gj.l($CLJS.G([c])),$CLJS.YZ(b)])),new $CLJS.k(null,2,[$CLJS.QO,a,$CLJS.NH,c],null),b);throw f;}};$CLJS.c1.v=4;
$CLJS.A1=function A1(a){switch(arguments.length){case 2:return A1.h(arguments[0],arguments[1]);case 3:return A1.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};$CLJS.A1.h=function(a,b){return $CLJS.A1.j(a,-1,b)};
$CLJS.A1.j=function(a,b,c){var d=$CLJS.V.g($CLJS.KH(c));if($CLJS.m(d))return d;try{return $CLJS.z1.j(a,b,c)}catch(e){if(e instanceof Error)throw d=e,$CLJS.Dj($CLJS.cI("Error calculating column name for {0}: {1}",$CLJS.G([$CLJS.gj.l($CLJS.G([c])),$CLJS.YZ(d)])),new $CLJS.k(null,3,[$CLJS.NH,c,$CLJS.QO,a,$CLJS.H0,b],null),d);throw e;}};$CLJS.A1.v=3;
$CLJS.y1.o(null,$CLJS.Uj,function(a,b,c){a=$CLJS.hG($CLJS.HE);$CLJS.m($CLJS.mG("metabase.lib.metadata.calculation",a))&&$CLJS.nG("metabase.lib.metadata.calculation",a,$CLJS.Y_("Don't know how to calculate display name for %s. Add an impl for %s for %s",$CLJS.G([$CLJS.gj.l($CLJS.G([c])),Upa,$CLJS.IH(c)])),null);return $CLJS.qe(c)&&$CLJS.C(c)instanceof $CLJS.N?$CLJS.Ai($CLJS.C(c)):$CLJS.gj.l($CLJS.G([c]))});$CLJS.z1.o(null,$CLJS.Uj,function(a,b,c){return voa($CLJS.vv($CLJS.c1.j(a,b,c),/[\(\)]/,""))});
$CLJS.B1=function(){var a=$CLJS.ij.g($CLJS.P),b=$CLJS.ij.g($CLJS.P),c=$CLJS.ij.g($CLJS.P),d=$CLJS.ij.g($CLJS.P),e=$CLJS.M.j(new $CLJS.k(null,1,[$CLJS.Wk,$CLJS.JH],null),$CLJS.Wk,$CLJS.rj.A?$CLJS.rj.A():$CLJS.rj.call(null));return new $CLJS.Aj($CLJS.$i.h("metabase.lib.metadata.calculation","describe-top-level-key-method"),function(f,g,l){return l},$CLJS.Uj,e,a,b,c,d)}();
$CLJS.C1=function(){var a=$CLJS.ij.g($CLJS.P),b=$CLJS.ij.g($CLJS.P),c=$CLJS.ij.g($CLJS.P),d=$CLJS.ij.g($CLJS.P),e=$CLJS.M.j(new $CLJS.k(null,1,[$CLJS.Wk,$CLJS.JH],null),$CLJS.Wk,$CLJS.rj.A?$CLJS.rj.A():$CLJS.rj.call(null));return new $CLJS.Aj($CLJS.$i.h("metabase.lib.metadata.calculation","type-of-method"),function(f,g,l){return $CLJS.IH(l)},$CLJS.Uj,e,a,b,c,d)}();
$CLJS.D1=function D1(a){switch(arguments.length){case 2:return D1.h(arguments[0],arguments[1]);case 3:return D1.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};$CLJS.D1.h=function(a,b){return $CLJS.D1.j(a,-1,b)};
$CLJS.D1.j=function(a,b,c){var d=$CLJS.KH(c);d=$CLJS.Uf(d);var e=$CLJS.M.h(d,$CLJS.kR),f=$CLJS.rk.g(d);if($CLJS.m(f))return f;e=$CLJS.m($CLJS.m(e)?$CLJS.Je($CLJS.cW,e):e)?$CLJS.ol:null;if($CLJS.m(e))return e;d=$CLJS.bH.g(d);if($CLJS.m(d))return d;a=$CLJS.C1.j(a,b,c);return $CLJS.XH(a,$CLJS.yl)?a:$CLJS.yl};$CLJS.D1.v=3;$CLJS.C1.o(null,$CLJS.Uj,function(a,b,c){return $CLJS.HL(c)});
$CLJS.C1.o(null,$CLJS.BM,function(a,b,c){$CLJS.J(c,0,null);$CLJS.J(c,1,null);c=$CLJS.J(c,2,null);return $CLJS.D1.j(a,b,c)});$CLJS.C1.o(null,$CLJS.cY,function(a,b,c){$CLJS.J(c,0,null);$CLJS.J(c,1,null);var d=$CLJS.J(c,2,null);return"string"===typeof d?$CLJS.HL(c):$CLJS.D1.j(a,b,d)});
$CLJS.a1=function(){var a=$CLJS.ij.g($CLJS.P),b=$CLJS.ij.g($CLJS.P),c=$CLJS.ij.g($CLJS.P),d=$CLJS.ij.g($CLJS.P),e=$CLJS.M.j(new $CLJS.k(null,1,[$CLJS.Wk,$CLJS.JH],null),$CLJS.Wk,$CLJS.rj.A?$CLJS.rj.A():$CLJS.rj.call(null));return new $CLJS.Aj($CLJS.$i.h("metabase.lib.metadata.calculation","metadata-method"),function(f,g,l){return $CLJS.IH(l)},$CLJS.Uj,e,a,b,c,d)}();
$CLJS.a1.o(null,$CLJS.Uj,function(a,b,c){try{return new $CLJS.k(null,4,[$CLJS.Zu,$CLJS.KW,$CLJS.bH,$CLJS.D1.j(a,b,c),$CLJS.V,$CLJS.A1.j(a,b,c),$CLJS.oJ,$CLJS.c1.j(a,b,c)],null)}catch(e){if(e instanceof Error){var d=e;throw $CLJS.Dj($CLJS.cI("Error calculating metadata {0}",$CLJS.G([$CLJS.YZ(d)])),new $CLJS.k(null,3,[$CLJS.QO,a,$CLJS.H0,b,$CLJS.NH,c],null),d);}throw e;}});
$CLJS.E1=function(){var a=$CLJS.ij.g($CLJS.P),b=$CLJS.ij.g($CLJS.P),c=$CLJS.ij.g($CLJS.P),d=$CLJS.ij.g($CLJS.P),e=$CLJS.M.j(new $CLJS.k(null,1,[$CLJS.Wk,$CLJS.JH],null),$CLJS.Wk,$CLJS.rj.A?$CLJS.rj.A():$CLJS.rj.call(null));return new $CLJS.Aj($CLJS.$i.h("metabase.lib.metadata.calculation","display-info-method"),function(f,g,l){return $CLJS.IH(l)},$CLJS.Uj,e,a,b,c,d)}();
$CLJS.pL(t4,new $CLJS.S(null,19,5,$CLJS.T,[$CLJS.dm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.oJ,$CLJS.Ul],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.h4,new $CLJS.k(null,1,[$CLJS.Wj,!0],null),$CLJS.Ul],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.K0,new $CLJS.k(null,1,[$CLJS.Wj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.tu,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qq,t4],null)],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[i4,new $CLJS.k(null,1,[$CLJS.Wj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.tu,$CLJS.qk],
null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.j4,new $CLJS.k(null,1,[$CLJS.Wj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.tu,$CLJS.qk],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[l4,new $CLJS.k(null,1,[$CLJS.Wj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.tu,$CLJS.qk],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.m4,new $CLJS.k(null,1,[$CLJS.Wj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.tu,$CLJS.qk],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[v4,new $CLJS.k(null,1,[$CLJS.Wj,!0],null),new $CLJS.S(null,
2,5,$CLJS.T,[$CLJS.tu,$CLJS.qk],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[Vpa,new $CLJS.k(null,1,[$CLJS.Wj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.tu,$CLJS.qk],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[Tpa,new $CLJS.k(null,1,[$CLJS.Wj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.tu,$CLJS.qk],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.V,new $CLJS.k(null,1,[$CLJS.Wj,!0],null),$CLJS.Ul],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.QW,new $CLJS.k(null,1,[$CLJS.Wj,!0],null),$CLJS.Ul],null),
new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.hI,new $CLJS.k(null,1,[$CLJS.Wj,!0],null),$CLJS.Ul],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.y4,new $CLJS.k(null,1,[$CLJS.Wj,!0],null),$CLJS.Ul],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.x4,new $CLJS.k(null,1,[$CLJS.Wj,!0],null),$CLJS.qk],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.n4,new $CLJS.k(null,1,[$CLJS.Wj,!0],null),$CLJS.qk],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Uj,new $CLJS.k(null,1,[$CLJS.Wj,!0],null),$CLJS.qk],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.r4,
new $CLJS.k(null,1,[$CLJS.Wj,!0],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.im,$CLJS.aK,$CLJS.OJ],null)],null)],null));$CLJS.f1=function f1(a){switch(arguments.length){case 2:return f1.h(arguments[0],arguments[1]);case 3:return f1.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};$CLJS.f1.h=function(a,b){return $CLJS.f1.j(a,-1,b)};
$CLJS.f1.j=function(a,b,c){try{return $CLJS.E1.j(a,b,c)}catch(e){if(e instanceof Error){var d=e;throw $CLJS.Dj($CLJS.cI("Error calculating display info for {0}: {1}",$CLJS.G([$CLJS.IH(c),$CLJS.YZ(d)])),new $CLJS.k(null,3,[$CLJS.QO,a,$CLJS.H0,b,$CLJS.NH,c],null),d);}throw e;}};$CLJS.f1.v=3;$CLJS.E1.o(null,$CLJS.Uj,function(a,b,c){return n1(a,b,c)});
$CLJS.E1.o(null,$CLJS.G0,function(a,b,c){return $CLJS.pn.l($CLJS.G([n1(a,b,c),new $CLJS.k(null,1,[v4,$CLJS.F.h($CLJS.tQ.g($CLJS.C($CLJS.IZ.g(a))),$CLJS.Mk.g(c))],null)]))});
$CLJS.F1=function(){var a=$CLJS.ij.g($CLJS.P),b=$CLJS.ij.g($CLJS.P),c=$CLJS.ij.g($CLJS.P),d=$CLJS.ij.g($CLJS.P),e=$CLJS.M.j(new $CLJS.k(null,1,[$CLJS.Wk,$CLJS.JH],null),$CLJS.Wk,$CLJS.rj.A?$CLJS.rj.A():$CLJS.rj.call(null));return new $CLJS.Aj($CLJS.$i.h("metabase.lib.metadata.calculation","visible-columns-method"),function(f,g,l){return $CLJS.IH(l)},$CLJS.Uj,e,a,b,c,d)}();$CLJS.F1.o(null,$CLJS.sH,function(){return $CLJS.Rg});module.exports={display_name:$CLJS.c1};