var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./medley.core.js");require("./metabase.lib.card.js");require("./metabase.lib.convert.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.ref.js");require("./metabase.lib.util.js");require("./metabase.mbql.util.match.js");require("./metabase.util.malli.js");require("./metabase.util.memoize.js");
'use strict';var M0,N0,O0,rta,fqa,gqa,E4,F4;M0=function(a,b){var c=$CLJS.Ie,d=L0;for(a=$CLJS.A(a);;)if(null!=a){d=$CLJS.M.j(d,$CLJS.C(a),c);if(c===d)return b;a=$CLJS.D(a)}else return d};N0=function(a){return $CLJS.uh.j($CLJS.ui,$CLJS.Xm.g(function(b){return $CLJS.Bf(b)&&$CLJS.qi.h(b,$CLJS.Zu)}),$CLJS.mi(a))};O0=function(a){return $CLJS.uh.j($CLJS.P,$CLJS.Qo(function(b,c){$CLJS.J(c,0,null);c=$CLJS.J(c,1,null);c=$CLJS.Uf(c);c=$CLJS.M.h(c,$CLJS.MH);return new $CLJS.S(null,2,5,$CLJS.T,[c,b],null)}),$CLJS.hQ.g(a))};
rta=function(a){return $CLJS.mW(a,function(b){return $CLJS.uh.j($CLJS.P,$CLJS.Xm.g(function(c){var d=$CLJS.J(c,0,null);$CLJS.J(c,1,null);return $CLJS.Bf(d)}),b)})};fqa=new $CLJS.N("metabase.lib.equality","no-right","metabase.lib.equality/no-right",2015744769);gqa=new $CLJS.N("metabase.lib.equality","no-left","metabase.lib.equality/no-left",-1403666439);$CLJS.D4=new $CLJS.N("metabase.lib.join","join-alias","metabase.lib.join/join-alias",1166233023);E4=new $CLJS.N(null,"left","left",-399115937);
F4=new $CLJS.N("metabase.lib.equality","different-dispatch-values","metabase.lib.equality/different-dispatch-values",-2107733395);$CLJS.R0=function(){var a=$CLJS.ij.g($CLJS.P),b=$CLJS.ij.g($CLJS.P),c=$CLJS.ij.g($CLJS.P),d=$CLJS.ij.g($CLJS.P),e=$CLJS.M.j(new $CLJS.k(null,1,[$CLJS.Wk,$CLJS.JH],null),$CLJS.Wk,$CLJS.rj.A?$CLJS.rj.A():$CLJS.rj.call(null));return new $CLJS.Aj($CLJS.$i.h("metabase.lib.equality","\x3d"),function(f,g){f=$CLJS.IH(f);g=$CLJS.IH(g);return $CLJS.qi.h(f,g)?F4:f},$CLJS.Uj,e,a,b,c,d)}();$CLJS.R0.o(null,F4,function(){return!1});
$CLJS.R0.o(null,$CLJS.YG,function(a,b){var c=N0(a),d=N0(b);return $CLJS.F.h(c,d)&&$CLJS.$f(function(e){return $CLJS.R0.h($CLJS.M.h(a,e),$CLJS.M.h(b,e))},c)});$CLJS.R0.o(null,$CLJS.PG,function(a,b){var c=$CLJS.F.h($CLJS.E(a),$CLJS.E(b));if(c)for(c=$CLJS.A(a),$CLJS.C(c),$CLJS.D(c),c=$CLJS.A(b),$CLJS.C(c),$CLJS.D(c),c=a,a=b;;){c=$CLJS.A(c);b=$CLJS.C(c);var d=$CLJS.D(c);c=b;b=d;a=$CLJS.A(a);d=$CLJS.C(a);a=$CLJS.D(a);c=$CLJS.R0.h(c,d);if($CLJS.m(c)){if(c=$CLJS.je(b))return c;c=b}else return c}else return c});
var L0=null;$CLJS.R0.o(null,$CLJS.UZ,function(a,b){var c=L0;L0=new $CLJS.k(null,2,[E4,O0(a),$CLJS.wD,O0(b)],null);try{var d=$CLJS.yj($CLJS.R0,$CLJS.YG);return d.h?d.h(a,b):d.call(null,a,b)}finally{L0=c}});
$CLJS.R0.o(null,$CLJS.hQ,function(a,b){var c=$CLJS.J(a,0,null),d=$CLJS.J(a,1,null),e=$CLJS.J(a,2,null),f=$CLJS.J(b,0,null),g=$CLJS.J(b,1,null),l=$CLJS.J(b,2,null);return(a=$CLJS.F.l(3,$CLJS.E(a),$CLJS.G([$CLJS.E(b)])))?(c=$CLJS.F.h(c,f))?(d=$CLJS.R0.h(d,g),$CLJS.m(d)?$CLJS.m(L0)?$CLJS.F.h(M0(new $CLJS.S(null,2,5,$CLJS.T,[E4,e],null),gqa),M0(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.wD,l],null),fqa)):$CLJS.F.h(e,l):d):c:a});
$CLJS.R0.o(null,$CLJS.Uj,function(a,b){if($CLJS.oe(a)){var c=$CLJS.yj($CLJS.R0,$CLJS.YG);return c.h?c.h(a,b):c.call(null,a,b)}return $CLJS.ne(a)?(c=$CLJS.yj($CLJS.R0,$CLJS.PG),c.h?c.h(a,b):c.call(null,a,b)):$CLJS.F.h(a,b)});var u1=function u1(a){switch(arguments.length){case 2:return u1.h(arguments[0],arguments[1]);case 3:return u1.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};
u1.h=function(a,b){for(var c=$CLJS.$e,d=new $CLJS.S(null,4,5,$CLJS.T,[function(){return function(g){return $CLJS.lW(g,$CLJS.u_,$CLJS.G([$CLJS.kS,$CLJS.Mm,$CLJS.q4,$CLJS.Zu]))}}(c),rta,function(){return function(g){return $CLJS.lW(g,$CLJS.Mm,$CLJS.G([$CLJS.bH,$CLJS.rk]))}}(c),function(){return function(g){return $CLJS.lW(g,$CLJS.Mm,$CLJS.G([$CLJS.HQ]))}}(c)],null);;){var e=function(){var g=c.g?c.g(a):c.call(null,a);return $CLJS.DW(function(l,n,q){return function(u){return $CLJS.R0.h(l.g?l.g(u):l.call(null,
u),q)}}(c,d,g),b)}();if($CLJS.m(e))return e;if($CLJS.A(d)){e=$CLJS.Um.h(c,$CLJS.C(d));var f=$CLJS.Ad(d);c=e;d=f}else return null}};
u1.j=function(a,b,c){var d=u1.h(b,c);return $CLJS.m(d)?d:$CLJS.C($CLJS.A($CLJS.Ag($CLJS.Hb,function l(f,g){try{if($CLJS.qe(g)&&3===$CLJS.E(g))try{var n=$CLJS.Ud(g,0);if($CLJS.O(n,$CLJS.ZQ))try{var q=$CLJS.Ud(g,2);if($CLJS.Ae(q)){var u=$CLJS.Ud(g,2),v=$CLJS.Ud(g,1);n=$CLJS.T;var x=$CLJS.V.g($CLJS.W3(a,u));var z=$CLJS.m(x)?u1.h(new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.ZQ,v,x],null),c):null;return new $CLJS.S(null,1,5,n,[z],null)}throw $CLJS.Z;}catch(H){if(H instanceof Error){var B=H;if(B===$CLJS.Z)throw $CLJS.Z;
throw B;}throw H;}else throw $CLJS.Z;}catch(H){if(H instanceof Error){B=H;if(B===$CLJS.Z)throw $CLJS.Z;throw B;}throw H;}else throw $CLJS.Z;}catch(H){if(H instanceof Error){B=H;if(B===$CLJS.Z)return $CLJS.CZ(l,f,g);throw B;}throw H;}}($CLJS.Rg,b))))};u1.v=3;$CLJS.v1=$CLJS.IG(u1,8);