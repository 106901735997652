var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.aggregation.js");require("./metabase.lib.binning.js");require("./metabase.lib.breakout.js");require("./metabase.lib.card.js");require("./metabase.lib.column_group.js");require("./metabase.lib.common.js");require("./metabase.lib.expression.js");require("./metabase.lib.field.js");require("./metabase.lib.filter.js");require("./metabase.lib.join.js");require("./metabase.lib.limit.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.metric.js");require("./metabase.lib.native.js");require("./metabase.lib.normalize.js");require("./metabase.lib.order_by.js");require("./metabase.lib.query.js");require("./metabase.lib.ref.js");require("./metabase.lib.remove_replace.js");require("./metabase.lib.segment.js");require("./metabase.lib.stage.js");require("./metabase.lib.table.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.shared.util.namespaces.js");
'use strict';var kta=function(a,b){$CLJS.Rb(function(c,d){return a.g?a.g(d):a.call(null,d)},null,b)},$Z=function(a,b){return $CLJS.Vc($CLJS.Rb(function(c,d){return $CLJS.m(a.g?a.g(d):a.call(null,d))?$CLJS.Uc(c,d):c},$CLJS.Tc($CLJS.Rg),b))},s8=function(a,b){if(b=$CLJS.A(b)){var c=$CLJS.A(b);b=$CLJS.C(c);c=$CLJS.D(c);return $CLJS.A(c)?(c=s8($CLJS.M.h(a,b),c),$CLJS.je(c)?$CLJS.Mm.h(a,b):$CLJS.U.j(a,b,c)):$CLJS.Mm.h(a,b)}return a},lta=function(a,b,c,d){return function q(f,g,l,n){var u=$CLJS.A(g);g=$CLJS.C(u);
u=$CLJS.D(u);var v=$CLJS.Le(f,g);return $CLJS.m(v)?u?$CLJS.U.j(f,g,q($CLJS.uc(v),u,l,n)):$CLJS.U.j(f,g,$CLJS.Q.j(l,$CLJS.uc(v),n)):f}(a,b,c,d)},mta=function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;return lta(arguments[0],arguments[1],arguments[2],3<b.length?new $CLJS.y(b.slice(3),0,null):null)},nta=function(a,b){return $CLJS.C($CLJS.kg(function(c){if($CLJS.m($CLJS.m(c)?b:c)){var d=$CLJS.bF.g(c);c=$CLJS.FF.g(c);d=$CLJS.m($CLJS.Ki(d,b))?$CLJS.vv(b,d,c):null}else d=
null;return d},a))},t8=function(a){a=$CLJS.AH(a);var b=$CLJS.bG(a);b||($CLJS.m(a)?(b=$CLJS.m(a)?!$CLJS.Je($CLJS.r($CLJS.GH),$CLJS.AH(a).toLowerCase()):null,b=$CLJS.Fb(b)):b=null);return $CLJS.m(b)?a:nta($CLJS.Pc($CLJS.r($CLJS.CH)),a)},ota=function(a){var b=$CLJS.J(a,0,null),c=$CLJS.J(a,1,null);return $CLJS.m(a)?new $CLJS.k(null,4,[$CLJS.Zu,$CLJS.SL,$CLJS.kK,b instanceof $CLJS.N?$CLJS.Ai(b):b,$CLJS.Tk,c,$CLJS.pl,$CLJS.hm.h(a,2)],null):null},v8=function(a){return $CLJS.f0(a)?$CLJS.rv(a,new $CLJS.S(null,
2,5,$CLJS.T,[1,$CLJS.MH],null)):null},pta=function(a,b,c,d){var e=$CLJS.F.h($CLJS.jR,$CLJS.C(b))?$CLJS.c3(d,$CLJS.g0(c)):d;return mta(a,b,function(f){return $CLJS.Sg(function(){return function n(l){return new $CLJS.Cf(null,function(){for(;;){var q=$CLJS.A(l);if(q){if($CLJS.re(q)){var u=$CLJS.$c(q),v=$CLJS.E(u),x=$CLJS.Ff(v);a:for(var z=0;;)if(z<v){var B=$CLJS.ce(u,z);B=$CLJS.F.h(v8(B),v8(c))?e:B;x.add(B);z+=1}else{u=!0;break a}return u?$CLJS.If($CLJS.Kf(x),n($CLJS.ad(q))):$CLJS.If($CLJS.Kf(x),null)}x=
$CLJS.C(q);return $CLJS.pf($CLJS.F.h(v8(x),v8(c))?e:x,n($CLJS.Ad(q)))}return null}},null,null)}(f)}())})},w8=function(a,b,c){var d=$CLJS.rv(a,b);if($CLJS.m(d)){var e=v8(c),f=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.C(b),$CLJS.ae(b)],null);c=$CLJS.J(f,0,null);f=$CLJS.J(f,1,null);d=$CLJS.uh.j($CLJS.Rg,$CLJS.Xm.g($CLJS.Um.h($CLJS.vi([e]),v8)),d);d=$CLJS.F.h(b,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.SO],null))&&$CLJS.$f(function(g){return $CLJS.f0(g)&&$CLJS.F.h($CLJS.C(g),$CLJS.ZG)},d)?null:d;if($CLJS.A(d))return $CLJS.xv(a,
b,d);if($CLJS.F.h(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.pP,$CLJS.LZ],null),new $CLJS.S(null,2,5,$CLJS.T,[c,f],null)))throw $CLJS.Ej($CLJS.dI("Cannot remove the final join condition"),new $CLJS.k(null,1,[$CLJS.LZ,$CLJS.rv(a,b)],null));return $CLJS.F.h(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.pP,$CLJS.SO],null),new $CLJS.S(null,2,5,$CLJS.T,[c,f],null))?$CLJS.x_.F(a,$CLJS.ie(b),$CLJS.Mm,f):s8(a,b)}return a},qta=function(a,b){a=$CLJS.Uf(a);a=$CLJS.M.h(a,$CLJS.IZ);b=0>b?$CLJS.E(a)+b:b;return b+1<$CLJS.E(a)?b+
1:null},x8=function(a,b,c,d){a=$CLJS.k0(a);var e=$CLJS.Uf(a);a=$CLJS.M.h(e,$CLJS.IZ);b=$CLJS.m(b)?b:-1;var f=$CLJS.W0(e,b);f=!($CLJS.A($CLJS.hQ.g(f))||$CLJS.A($CLJS.aQ.g(f)));c=$CLJS.X0(e,b,$CLJS.Zm,$CLJS.G([c,function(g){return $CLJS.mf.h($CLJS.Sg(g),$CLJS.rW.g(d))}]));return f?$CLJS.Zm.R($CLJS.f6(c,b,function(g){return $CLJS.u_.j($CLJS.Mm.l(g,$CLJS.tS,$CLJS.G([$CLJS.SO])),$CLJS.pP,function(l){return $CLJS.Ym.h(function(n){return $CLJS.Mm.h(n,$CLJS.SO)},l)})}),$CLJS.IZ,$CLJS.Um.h(function(g){return $CLJS.uh.h($CLJS.Rg,
g)},$CLJS.hm),0,$CLJS.V0(a,b)+1):c},y8=function(a){var b=$CLJS.KH(a);b=$CLJS.m($CLJS.rk.g(b))?null:$CLJS.bH.g(b);return $CLJS.m(b)?$CLJS.lW(a,$CLJS.U,$CLJS.G([$CLJS.rk,b])):a},u8=function(a,b){if(null!=a&&null!=a.Zg)a=a.Zg(a,b);else{var c=u8[$CLJS.Na(null==a?null:a)];if(null!=c)a=c.h?c.h(a,b):c.call(null,a,b);else if(c=u8._,null!=c)a=c.h?c.h(a,b):c.call(null,a,b);else throw $CLJS.Ob("MetadataProvider.metrics",a);}return a},sta=function(a){if($CLJS.F.h($CLJS.Zu.g($CLJS.W0(a,-1)),$CLJS.D_))return null;
try{return $CLJS.c1.h(a,a)}catch(c){if(c instanceof Error){a=c;var b=$CLJS.hG($CLJS.Gv);$CLJS.m($CLJS.mG("metabase.lib.metadata.calculation",b))&&(a instanceof Error?$CLJS.nG("metabase.lib.metadata.calculation",b,$CLJS.HC.l($CLJS.G([$CLJS.cI("Error calculating display name for query: {0}",$CLJS.G([$CLJS.YZ(a)]))])),a):$CLJS.nG("metabase.lib.metadata.calculation",b,$CLJS.HC.l($CLJS.G([a,$CLJS.cI("Error calculating display name for query: {0}",$CLJS.G([$CLJS.YZ(a)]))])),null));return null}throw c;}},
tta=function(a,b){a=$CLJS.o2(a);b=$CLJS.m(b)?b:$CLJS.zv;return 0===a?$CLJS.dI("Now"):0>a?$CLJS.cI("{0} {1} ago",$CLJS.G([Math.abs(a),$CLJS.H1.h(Math.abs(a),b).toLowerCase()])):$CLJS.cI("{0} {1} from now",$CLJS.G([a,$CLJS.H1.h(a,b).toLowerCase()]))},uta=function(a){var b=new $CLJS.S(null,9,5,$CLJS.T,[new $CLJS.k(null,2,[$CLJS.iW,$CLJS.om,$CLJS.oJ,$CLJS.dI("Is")],null),new $CLJS.k(null,2,[$CLJS.iW,$CLJS.YK,$CLJS.oJ,$CLJS.dI("Is not")],null),new $CLJS.k(null,2,[$CLJS.iW,$CLJS.rm,$CLJS.oJ,$CLJS.dI("Greater than")],
null),new $CLJS.k(null,2,[$CLJS.iW,$CLJS.pm,$CLJS.oJ,$CLJS.dI("Less than")],null),new $CLJS.k(null,2,[$CLJS.iW,$CLJS.eJ,$CLJS.oJ,$CLJS.dI("Between")],null),new $CLJS.k(null,2,[$CLJS.iW,$CLJS.Am,$CLJS.oJ,$CLJS.dI("Greater than or equal to")],null),new $CLJS.k(null,2,[$CLJS.iW,$CLJS.Bm,$CLJS.oJ,$CLJS.dI("Less than or equal to")],null),new $CLJS.k(null,2,[$CLJS.iW,$CLJS.TI,$CLJS.oJ,$CLJS.dI("Is empty")],null),new $CLJS.k(null,2,[$CLJS.iW,$CLJS.JJ,$CLJS.oJ,$CLJS.dI("Not empty")],null)],null);return $CLJS.f2(a,
$CLJS.zf)?b:$CLJS.f2(a,$CLJS.Ze)?b:$CLJS.f2(a,$CLJS.vI)?new $CLJS.S(null,8,5,$CLJS.T,[new $CLJS.k(null,2,[$CLJS.iW,$CLJS.om,$CLJS.oJ,$CLJS.dI("Is")],null),new $CLJS.k(null,2,[$CLJS.iW,$CLJS.YK,$CLJS.oJ,$CLJS.dI("Is not")],null),new $CLJS.k(null,2,[$CLJS.iW,$CLJS.fJ,$CLJS.oJ,$CLJS.dI("Is empty")],null),new $CLJS.k(null,2,[$CLJS.iW,$CLJS.vJ,$CLJS.oJ,$CLJS.dI("Not empty")],null),new $CLJS.k(null,2,[$CLJS.iW,$CLJS.fK,$CLJS.oJ,$CLJS.dI("Contains")],null),new $CLJS.k(null,2,[$CLJS.iW,$CLJS.RJ,$CLJS.oJ,
$CLJS.dI("Does not contain")],null),new $CLJS.k(null,2,[$CLJS.iW,$CLJS.QJ,$CLJS.oJ,$CLJS.dI("Starts with")],null),new $CLJS.k(null,2,[$CLJS.iW,$CLJS.IK,$CLJS.oJ,$CLJS.dI("Ends with")],null)],null):$CLJS.f2(a,$CLJS.Ql)?new $CLJS.S(null,7,5,$CLJS.T,[new $CLJS.k(null,2,[$CLJS.iW,$CLJS.YK,$CLJS.oJ,$CLJS.dI("Excludes")],null),new $CLJS.k(null,2,[$CLJS.iW,$CLJS.om,$CLJS.oJ,$CLJS.dI("Is")],null),new $CLJS.k(null,2,[$CLJS.iW,$CLJS.pm,$CLJS.oJ,$CLJS.dI("Before")],null),new $CLJS.k(null,2,[$CLJS.iW,$CLJS.rm,
$CLJS.oJ,$CLJS.dI("After")],null),new $CLJS.k(null,2,[$CLJS.iW,$CLJS.eJ,$CLJS.oJ,$CLJS.dI("Between")],null),new $CLJS.k(null,2,[$CLJS.iW,$CLJS.TI,$CLJS.oJ,$CLJS.dI("Is empty")],null),new $CLJS.k(null,2,[$CLJS.iW,$CLJS.JJ,$CLJS.oJ,$CLJS.dI("Not empty")],null)],null):$CLJS.f2(a,$CLJS.sl)?new $CLJS.S(null,8,5,$CLJS.T,[new $CLJS.k(null,2,[$CLJS.iW,$CLJS.om,$CLJS.oJ,$CLJS.dI("Is")],null),new $CLJS.k(null,2,[$CLJS.iW,$CLJS.YK,$CLJS.oJ,$CLJS.dI("Is not")],null),new $CLJS.k(null,2,[$CLJS.iW,$CLJS.pJ,$CLJS.oJ,
$CLJS.dI("Inside")],null),new $CLJS.k(null,2,[$CLJS.iW,$CLJS.rm,$CLJS.oJ,$CLJS.dI("Greater than")],null),new $CLJS.k(null,2,[$CLJS.iW,$CLJS.pm,$CLJS.oJ,$CLJS.dI("Less than")],null),new $CLJS.k(null,2,[$CLJS.iW,$CLJS.eJ,$CLJS.oJ,$CLJS.dI("Between")],null),new $CLJS.k(null,2,[$CLJS.iW,$CLJS.Am,$CLJS.oJ,$CLJS.dI("Greater than or equal to")],null),new $CLJS.k(null,2,[$CLJS.iW,$CLJS.Bm,$CLJS.oJ,$CLJS.dI("Less than or equal to")],null)],null):$CLJS.f2(a,$CLJS.Jl)?new $CLJS.S(null,9,5,$CLJS.T,[new $CLJS.k(null,
2,[$CLJS.iW,$CLJS.om,$CLJS.oJ,$CLJS.dI("Equal to")],null),new $CLJS.k(null,2,[$CLJS.iW,$CLJS.YK,$CLJS.oJ,$CLJS.dI("Not equal to")],null),new $CLJS.k(null,2,[$CLJS.iW,$CLJS.rm,$CLJS.oJ,$CLJS.dI("Greater than")],null),new $CLJS.k(null,2,[$CLJS.iW,$CLJS.pm,$CLJS.oJ,$CLJS.dI("Less than")],null),new $CLJS.k(null,2,[$CLJS.iW,$CLJS.eJ,$CLJS.oJ,$CLJS.dI("Between")],null),new $CLJS.k(null,2,[$CLJS.iW,$CLJS.Am,$CLJS.oJ,$CLJS.dI("Greater than or equal to")],null),new $CLJS.k(null,2,[$CLJS.iW,$CLJS.Bm,$CLJS.oJ,
$CLJS.dI("Less than or equal to")],null),new $CLJS.k(null,2,[$CLJS.iW,$CLJS.TI,$CLJS.oJ,$CLJS.dI("Is empty")],null),new $CLJS.k(null,2,[$CLJS.iW,$CLJS.JJ,$CLJS.oJ,$CLJS.dI("Not empty")],null)],null):$CLJS.f2(a,$CLJS.fn)?new $CLJS.S(null,10,5,$CLJS.T,[new $CLJS.k(null,2,[$CLJS.iW,$CLJS.om,$CLJS.oJ,$CLJS.dI("Is")],null),new $CLJS.k(null,2,[$CLJS.iW,$CLJS.YK,$CLJS.oJ,$CLJS.dI("Is not")],null),new $CLJS.k(null,2,[$CLJS.iW,$CLJS.fK,$CLJS.oJ,$CLJS.dI("Contains")],null),new $CLJS.k(null,2,[$CLJS.iW,$CLJS.RJ,
$CLJS.oJ,$CLJS.dI("Does not contain")],null),new $CLJS.k(null,2,[$CLJS.iW,$CLJS.TI,$CLJS.oJ,$CLJS.dI("Is null")],null),new $CLJS.k(null,2,[$CLJS.iW,$CLJS.JJ,$CLJS.oJ,$CLJS.dI("Not null")],null),new $CLJS.k(null,2,[$CLJS.iW,$CLJS.fJ,$CLJS.oJ,$CLJS.dI("Is empty")],null),new $CLJS.k(null,2,[$CLJS.iW,$CLJS.vJ,$CLJS.oJ,$CLJS.dI("Not empty")],null),new $CLJS.k(null,2,[$CLJS.iW,$CLJS.QJ,$CLJS.oJ,$CLJS.dI("Starts with")],null),new $CLJS.k(null,2,[$CLJS.iW,$CLJS.IK,$CLJS.oJ,$CLJS.dI("Ends with")],null)],null):
$CLJS.f2(a,$CLJS.el)?new $CLJS.S(null,6,5,$CLJS.T,[new $CLJS.k(null,2,[$CLJS.iW,$CLJS.om,$CLJS.oJ,$CLJS.dI("Is")],null),new $CLJS.k(null,2,[$CLJS.iW,$CLJS.YK,$CLJS.oJ,$CLJS.dI("Is not")],null),new $CLJS.k(null,2,[$CLJS.iW,$CLJS.TI,$CLJS.oJ,$CLJS.dI("Is null")],null),new $CLJS.k(null,2,[$CLJS.iW,$CLJS.JJ,$CLJS.oJ,$CLJS.dI("Not null")],null),new $CLJS.k(null,2,[$CLJS.iW,$CLJS.fJ,$CLJS.oJ,$CLJS.dI("Is empty")],null),new $CLJS.k(null,2,[$CLJS.iW,$CLJS.vJ,$CLJS.oJ,$CLJS.dI("Not empty")],null)],null):$CLJS.f2(a,
$CLJS.Gl)?new $CLJS.S(null,3,5,$CLJS.T,[new $CLJS.k(null,2,[$CLJS.iW,$CLJS.om,$CLJS.oJ,$CLJS.dI("Is")],null),new $CLJS.k(null,2,[$CLJS.iW,$CLJS.TI,$CLJS.oJ,$CLJS.dI("Is empty")],null),new $CLJS.k(null,2,[$CLJS.iW,$CLJS.JJ,$CLJS.oJ,$CLJS.dI("Not empty")],null)],null):new $CLJS.S(null,4,5,$CLJS.T,[new $CLJS.k(null,2,[$CLJS.iW,$CLJS.om,$CLJS.oJ,$CLJS.dI("Is")],null),new $CLJS.k(null,2,[$CLJS.iW,$CLJS.YK,$CLJS.oJ,$CLJS.dI("Is not")],null),new $CLJS.k(null,2,[$CLJS.iW,$CLJS.TI,$CLJS.oJ,$CLJS.dI("Is null")],
null),new $CLJS.k(null,2,[$CLJS.iW,$CLJS.JJ,$CLJS.oJ,$CLJS.dI("Not null")],null)],null)},vta=function(a){return $CLJS.x5.g(a)},wta=function(a,b){var c=$CLJS.uh.j($CLJS.P,$CLJS.Hn(function(e){var f=$CLJS.oW(e);f=$CLJS.v1.h?$CLJS.v1.h(f,b):$CLJS.v1.call(null,f,b);return $CLJS.m(f)?new $CLJS.S(null,2,5,$CLJS.T,[f,e],null):null}),a);c=$CLJS.xg.h(c,b);var d=$CLJS.DW($CLJS.Wm.h($CLJS.j2,$CLJS.k2),c);if($CLJS.m(d))return d;c=$CLJS.C(c);if($CLJS.m(c))return c;c=$CLJS.DW($CLJS.j2,a);if($CLJS.m(c))return c;
c=$CLJS.DW($CLJS.k2,a);return $CLJS.m(c)?c:$CLJS.C(a)},xta=function(a){return"string"===typeof a?(0,$CLJS.Va)($CLJS.vv(a,/ id$/i,"")):null},yta=function(a,b){var c="string"===typeof a;if(c){if(c="string"===typeof b){c=new $CLJS.dj(function(){return t8(b)});var d=new $CLJS.dj(function(){return t8(a)});return $CLJS.F.h(a,b)||$CLJS.F.h(a,$CLJS.r(c))||$CLJS.F.h($CLJS.r(d),b)||$CLJS.F.h($CLJS.r(d),$CLJS.r(c))}return c}return c},zta=function(a,b,c){var d=$CLJS.c1.h($CLJS.F.h($CLJS.Zu.g(b),$CLJS.H_)?b:a,
b),e=$CLJS.m(c)?xta($CLJS.c1.h(a,c)):null,f=yta(d,e);return function(){var g=function(){if($CLJS.m(d)){if($CLJS.m(e)){var l=$CLJS.Fb($CLJS.Ji(/id/i,e));return l?(l=!f)?[$CLJS.p.g(d)," - ",e].join(""):l:l}return e}return d}();return $CLJS.m(g)?g:$CLJS.m(d)?d:$CLJS.m(e)?e:"source"}()},B8=function(a){return $CLJS.f0(a)&&$CLJS.F.h($CLJS.C(a),$CLJS.ZQ)},Ata=function(a,b,c,d){return function l(f,g){try{if(B8(g)&&$CLJS.ye($CLJS.v1.j?$CLJS.v1.j(a,g,d):$CLJS.v1.call(null,a,g,d)))return $CLJS.$3(g,c);throw $CLJS.Z;
}catch(q){if(q instanceof Error){var n=q;if(n===$CLJS.Z)return $CLJS.l_(l,f,g);throw n;}throw q;}}($CLJS.Rg,b)},Bta=function(a,b,c,d,e){b=Ata(a,b,c,e);return function n(g,l){try{if($CLJS.qe(l)&&4===$CLJS.E(l))try{var q=$CLJS.Ud(l,2);if(B8(q))try{var u=$CLJS.Ud(l,3);if(B8(u)){var v=$CLJS.Ud(l,3),x=$CLJS.Ud(l,2),z=$CLJS.Ud(l,0),B=$CLJS.Ud(l,1),H=$CLJS.Je($CLJS.KH(x),$CLJS.HQ),I=$CLJS.Je($CLJS.KH(v),$CLJS.HQ);if(H||I){if(H&&I){var R=$CLJS.lW(x,$CLJS.Mm,$CLJS.G([$CLJS.HQ])),X=$CLJS.lW(v,$CLJS.Mm,$CLJS.G([$CLJS.HQ])),
ba=null==($CLJS.v1.j?$CLJS.v1.j(a,R,d):$CLJS.v1.call(null,a,R,d));var ha=ba?$CLJS.v1.j?$CLJS.v1.j(a,X,d):$CLJS.v1.call(null,a,X,d):ba;return $CLJS.m(ha)?new $CLJS.S(null,4,5,$CLJS.T,[z,B,x,X],null):new $CLJS.S(null,4,5,$CLJS.T,[z,B,R,v],null)}return l}return $CLJS.m($CLJS.v1.j?$CLJS.v1.j(a,v,d):$CLJS.v1.call(null,a,v,d))?new $CLJS.S(null,4,5,$CLJS.T,[z,B,$CLJS.$3(x,c),v],null):new $CLJS.S(null,4,5,$CLJS.T,[z,B,x,$CLJS.$3(v,c)],null)}throw $CLJS.Z;}catch(Fa){if(Fa instanceof Error){var oa=Fa;if(oa===
$CLJS.Z)throw $CLJS.Z;throw oa;}throw Fa;}else throw $CLJS.Z;}catch(Fa){if(Fa instanceof Error){oa=Fa;if(oa===$CLJS.Z)throw $CLJS.Z;throw oa;}throw Fa;}else throw $CLJS.Z;}catch(Fa){if(Fa instanceof Error){oa=Fa;if(oa===$CLJS.Z)return $CLJS.l_(n,g,l);throw oa;}throw Fa;}}($CLJS.Rg,b)},Cta=function(a,b){var c=$CLJS.e3();kta(c,b);return c(a)},Dta=function(a,b,c){var d=$CLJS.W0(a,b);b=$CLJS.G5(a,b,d);var e=$CLJS.A($CLJS.Ag($CLJS.Hb,function v(q,u){try{if($CLJS.qe(u)&&1<=$CLJS.E(u))try{var x=$CLJS.hm.j(u,
0,1);if($CLJS.qe(x)&&1===$CLJS.E(x))try{var z=$CLJS.Ud(x,0);if($CLJS.O(z,$CLJS.ZQ))return new $CLJS.S(null,1,5,$CLJS.T,[u],null);throw $CLJS.Z;}catch(H){if(H instanceof Error){var B=H;if(B===$CLJS.Z)throw $CLJS.Z;throw B;}throw H;}else throw $CLJS.Z;}catch(H){if(H instanceof Error){B=H;if(B===$CLJS.Z)throw $CLJS.Z;throw B;}throw H;}else throw $CLJS.Z;}catch(H){if(H instanceof Error){B=H;if(B===$CLJS.Z)return $CLJS.CZ(v,q,u);throw B;}throw H;}}($CLJS.Rg,$CLJS.LZ.g(c))));e=wta(b,e);var f=Cta(zta(a,
c,e),$CLJS.xg.h($CLJS.RP,$CLJS.pP.g(d))),g=$CLJS.Ym.h($CLJS.oW,b),l=$CLJS.Ym.h($CLJS.oW,$CLJS.q5($CLJS.X3(a,$CLJS.IZ.g(c))));return $CLJS.$3($CLJS.Zm.j(c,$CLJS.LZ,function(q){return $CLJS.Ym.h(function(u){return Bta(a,u,f,g,l)},q)}),f)},Eta=function(a){return $CLJS.LZ.g(a)},Fta=function(a){return $CLJS.SO.g(a)},Gta=function(a){return $CLJS.M.j(a,$CLJS.LL,$CLJS.SQ)},Hta=function(a,b){return $CLJS.U.j(a,$CLJS.LL,b)},C8=function(a){a=$CLJS.lj(function(d){return $CLJS.k2(d)?$CLJS.a7:$CLJS.j2(d)?$CLJS.$6:
$CLJS.Xz},a);var b=$CLJS.Uf(a);a=$CLJS.M.h(b,$CLJS.a7);var c=$CLJS.M.h(b,$CLJS.$6);b=$CLJS.M.h(b,$CLJS.Xz);return $CLJS.wg.l(a,c,$CLJS.G([b]))},D8=function(){return new $CLJS.k(null,3,[$CLJS.Zu,$CLJS.EY,$CLJS.iW,$CLJS.om,$CLJS.oJ,$CLJS.dI("Equal to")],null)},Ita=function(a,b,c){var d=$CLJS.Mk.g(c);return $CLJS.m(d)?$CLJS.DW(function(e){e=$CLJS.Uf(e);var f=$CLJS.M.h(e,$CLJS.b7);return $CLJS.j2(e)&&$CLJS.F.h(f,d)},$CLJS.G5(a,b,$CLJS.W0(a,b))):null},Jta=function(a){a=$CLJS.Uf(a);var b=$CLJS.M.h(a,$CLJS.E0);
switch(b instanceof $CLJS.N?b.S:null){case "source/implicitly-joinable":return new $CLJS.k(null,2,[$CLJS.c7,$CLJS.ara,$CLJS.ER,$CLJS.ER.g(a)],null);case "source/joins":return new $CLJS.k(null,2,[$CLJS.c7,$CLJS.bra,$CLJS.HQ,$CLJS.R5(a)],null);default:return new $CLJS.k(null,1,[$CLJS.c7,$CLJS.$qa],null)}},Kta=function(a){return $CLJS.Ym.h(function(b){var c=$CLJS.J(b,0,null);b=$CLJS.J(b,1,null);return $CLJS.U.l(c,$CLJS.Zu,$CLJS.d7,$CLJS.G([$CLJS.e7,b]))},$CLJS.lj(Jta,a))},Lta=function(a){return $CLJS.e7.g(a)},
Mta=function(a){return $CLJS.D0.g(a)},Nta=function(a,b){if($CLJS.A(a)){var c=$CLJS.J(b,0,null);$CLJS.J(b,1,null);var d=$CLJS.J(b,2,null),e=$CLJS.kR.g($CLJS.KH(d));return $CLJS.Ym.h(function(f){return $CLJS.F.h($CLJS.iW.g(f),c)?$CLJS.u_.j($CLJS.U.j(f,$CLJS.M3,!0),$CLJS.D0,function(g){var l=$CLJS.Ym.h($CLJS.oW,g),n=function(){var q=$CLJS.lW(d,$CLJS.Mm,$CLJS.G([$CLJS.kR]));return $CLJS.v1.h?$CLJS.v1.h(q,l):$CLJS.v1.call(null,q,l)}();return $CLJS.m(n)?$CLJS.Ym.j(function(q,u){q=$CLJS.F.h(q,n)?$CLJS.U.j(u,
$CLJS.M3,!0):u;return null!=e?$CLJS.Y7(q,e):q},l,g):g}):f},a)}return null},Ota=function(a,b,c){var d=$CLJS.W0(a,b);a=$CLJS.G5(a,b,d);var e=$CLJS.cG(c);return $CLJS.ag(function(f){return $CLJS.F.h($CLJS.cG($CLJS.V.g(f)),e)},a)},Pta=function(a,b){var c=$CLJS.Zm.F(a,$CLJS.jR,$CLJS.ig($CLJS.mf,$CLJS.Rg),b);return $CLJS.qe($CLJS.SO.g(a))?$CLJS.Zm.F(c,$CLJS.SO,$CLJS.mf,$CLJS.nW(new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.ZG,$CLJS.P,$CLJS.g0(b)],null))):c},Qta=function(a,b){return function e(d){return new $CLJS.Cf(null,
function(){for(var f=d;;)if(f=$CLJS.A(f)){if($CLJS.re(f)){var g=$CLJS.$c(f),l=$CLJS.E(g),n=$CLJS.Ff(l);a:for(var q=0;;)if(q<l){var u=$CLJS.ce(g,q);$CLJS.F.h($CLJS.E0.g(u),$CLJS.I3)&&(u=$CLJS.oW(u),n.add(u));q+=1}else{g=!0;break a}return g?$CLJS.If($CLJS.Kf(n),e($CLJS.ad(f))):$CLJS.If($CLJS.Kf(n),null)}n=$CLJS.C(f);if($CLJS.F.h($CLJS.E0.g(n),$CLJS.I3))return $CLJS.pf($CLJS.oW(n),e($CLJS.Ad(f)));f=$CLJS.Ad(f)}else return null},null,null)}($CLJS.b1(a,b,$CLJS.W0(a,b)))},Rta=function(a){var b=$CLJS.tQ.g($CLJS.C($CLJS.IZ.g(a)));
return $CLJS.m(b)?$CLJS.Vf(u8($CLJS.Y0(a),b)):null},Sta=function(a){a=$CLJS.Uf(a);var b=$CLJS.M.h(a,$CLJS.bH);return $CLJS.ag(function(c){return $CLJS.XH(b,c)},$CLJS.YL)},Tta=function(a,b){var c=v8(b);return function g(e,f){try{if($CLJS.qe(f)&&3===$CLJS.E(f))try{if($CLJS.F.h($CLJS.MH.g($CLJS.Ud(f,1)),c)){var l=$CLJS.Ud(f,0);return $CLJS.U.j(f,0,$CLJS.U7.g?$CLJS.U7.g(l):$CLJS.U7.call(null,l))}throw $CLJS.Z;}catch(n){if(n instanceof Error){l=n;if(l===$CLJS.Z)throw $CLJS.Z;throw l;}throw n;}else throw $CLJS.Z;
}catch(n){if(n instanceof Error){l=n;if(l===$CLJS.Z)return $CLJS.l_(g,e,f);throw l;}throw n;}}($CLJS.Rg,a)},F8=function(a,b){var c=$CLJS.Mj(0,$CLJS.E(E8.h(a,b)));return $CLJS.wg.l(new $CLJS.S(null,6,5,$CLJS.T,[new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.tS],null),new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.aQ],null),new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.I_],null),new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.SO],null),new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.hQ],null),new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.jR],null)],null),function(){return function f(e){return new $CLJS.Cf(null,
function(){for(;;){var g=$CLJS.A(e);if(g){if($CLJS.re(g)){var l=$CLJS.$c(g),n=$CLJS.E(l),q=$CLJS.Ff(n);a:for(var u=0;;)if(u<n){var v=$CLJS.ce(l,u);q.add(new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.pP,v,$CLJS.SO],null));u+=1}else{l=!0;break a}return l?$CLJS.If($CLJS.Kf(q),f($CLJS.ad(g))):$CLJS.If($CLJS.Kf(q),null)}q=$CLJS.C(g);return $CLJS.pf(new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.pP,q,$CLJS.SO],null),f($CLJS.Ad(g)))}return null}},null,null)}(c)}(),$CLJS.G([function(){return function f(e){return new $CLJS.Cf(null,
function(){for(;;){var g=$CLJS.A(e);if(g){if($CLJS.re(g)){var l=$CLJS.$c(g),n=$CLJS.E(l),q=$CLJS.Ff(n);a:for(var u=0;;)if(u<n){var v=$CLJS.ce(l,u);q.add(new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.pP,v,$CLJS.LZ],null));u+=1}else{l=!0;break a}return l?$CLJS.If($CLJS.Kf(q),f($CLJS.ad(g))):$CLJS.If($CLJS.Kf(q),null)}q=$CLJS.C(g);return $CLJS.pf(new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.pP,q,$CLJS.LZ],null),f($CLJS.Ad(g)))}return null}},null,null)}(c)}()]))},G8=function(a,b,c){var d=$CLJS.J(c,0,null),e=$CLJS.J(c,1,
null);e=$CLJS.Uf(e);var f=$CLJS.M.h(e,$CLJS.kR),g=$CLJS.M.h(e,$CLJS.kS),l=$CLJS.J(c,2,null);return $CLJS.C($CLJS.DW(function(n){$CLJS.J(n,0,null);n=$CLJS.J(n,1,null);$CLJS.J(n,0,null);$CLJS.J(n,1,null);n=$CLJS.J(n,2,null);return $CLJS.F.h($CLJS.C(n),d)&&$CLJS.F.h($CLJS.kR.g($CLJS.$d(n)),f)&&$CLJS.F.h($CLJS.kS.g($CLJS.$d(n)),g)&&$CLJS.F.h($CLJS.ae(n),l)},$CLJS.jg($CLJS.cn,$CLJS.tS.g($CLJS.W0(a,b)))))},Uta=function(a,b,c,d){c=G8(a,b,c);return $CLJS.m(c)?$CLJS.X0(a,b,$CLJS.x_,$CLJS.G([new $CLJS.S(null,
4,5,$CLJS.T,[$CLJS.tS,c,2,1],null),$CLJS.Um.h(function(e){return $CLJS.kG($CLJS.fg($CLJS.Bb),e)},$CLJS.pn),d])):a},Vta=function(a,b,c){c=G8(a,b,c);return $CLJS.m(c)?$CLJS.X0(a,b,w8,$CLJS.G([new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.tS],null),$CLJS.rv($CLJS.W0(a,b),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.tS,c],null))])):a},J8=function(a,b,c,d,e,f){var g=$CLJS.X0(a,b,f,$CLJS.G([d,e])),l=v8(e);return $CLJS.qi.h(a,g)?$CLJS.C($CLJS.A($CLJS.Ag($CLJS.Hb,function(n,q){try{if($CLJS.qe(q)&&1===$CLJS.E(q))try{var u=$CLJS.Ud(q,
0);if($CLJS.O(u,$CLJS.jR))return new $CLJS.S(null,1,5,$CLJS.T,[function(){var I=$CLJS.g0(e);I=H8.R?H8.R(g,b,c,$CLJS.ZG,I):H8.call(null,g,b,c,$CLJS.ZG,I);return I8.F?I8.F(I,b,c,l):I8.call(null,I,b,c,l)}()],null);throw $CLJS.Z;}catch(I){if(I instanceof Error)if(n=I,n===$CLJS.Z)try{u=$CLJS.Ud(q,0);if($CLJS.O(u,$CLJS.hQ))return new $CLJS.S(null,1,5,$CLJS.T,[function(){var R=H8.R?H8.R(g,b,c,$CLJS.hQ,l):H8.call(null,g,b,c,$CLJS.hQ,l);return I8.F?I8.F(R,b,c,l):I8.call(null,R,b,c,l)}()],null);throw $CLJS.Z;
}catch(R){if(R instanceof Error){var v=R;if(v===$CLJS.Z)throw $CLJS.Z;throw v;}throw R;}else throw n;else throw I;}else throw $CLJS.Z;}catch(I){if(I instanceof Error)if(n=I,n===$CLJS.Z)try{if($CLJS.qe(q)&&1===$CLJS.E(q))try{var x=$CLJS.Ud(q,0);if($CLJS.O(x,$CLJS.aQ))return new $CLJS.S(null,1,5,$CLJS.T,[I8.F?I8.F(g,b,c,l):I8.call(null,g,b,c,l)],null);throw $CLJS.Z;}catch(R){if(R instanceof Error)if(v=R,v===$CLJS.Z)try{x=$CLJS.Ud(q,0);if($CLJS.O(x,$CLJS.SO))return new $CLJS.S(null,1,5,$CLJS.T,[I8.F?
I8.F(g,b,c,l):I8.call(null,g,b,c,l)],null);throw $CLJS.Z;}catch(X){if(X instanceof Error){var z=X;if(z===$CLJS.Z)throw $CLJS.Z;throw z;}throw X;}else throw v;else throw R;}else throw $CLJS.Z;}catch(R){if(R instanceof Error)if(v=R,v===$CLJS.Z)try{if($CLJS.qe(q)&&3===$CLJS.E(q))try{var B=$CLJS.Ud(q,0);if($CLJS.O(B,$CLJS.pP))try{var H=$CLJS.Ud(q,2);if($CLJS.O(H,$CLJS.SO))return new $CLJS.S(null,1,5,$CLJS.T,[I8.F?I8.F(g,b,c,l):I8.call(null,g,b,c,l)],null);throw $CLJS.Z;}catch(X){if(X instanceof Error){z=
X;if(z===$CLJS.Z)throw $CLJS.Z;throw z;}throw X;}else throw $CLJS.Z;}catch(X){if(X instanceof Error){z=X;if(z===$CLJS.Z)throw $CLJS.Z;throw z;}throw X;}else throw $CLJS.Z;}catch(X){if(X instanceof Error){z=X;if(z===$CLJS.Z)return new $CLJS.S(null,1,5,$CLJS.T,[g],null);throw z;}throw X;}else throw v;else throw R;}else throw n;else throw I;}}($CLJS.Rg,d)))):g},H8=function(a,b,c,d,e){var f=$CLJS.W0(a,b),g=$CLJS.yg(function(l){var n=$CLJS.rv(f,l);return $CLJS.m(n)?$CLJS.xe(n)?$CLJS.kg(function(q){return $CLJS.C($CLJS.A($CLJS.Ag($CLJS.Hb,
function z(v,x){try{if($CLJS.qe(x)&&3===$CLJS.E(x))try{var B=$CLJS.Ud(x,0);if($CLJS.F.h(B,d))try{var H=$CLJS.Ud(x,2);if($CLJS.F.h(H,e))return new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[l,q],null)],null);throw $CLJS.Z;}catch(R){if(R instanceof Error){var I=R;if(I===$CLJS.Z)throw $CLJS.Z;throw I;}throw R;}else throw $CLJS.Z;}catch(R){if(R instanceof Error){I=R;if(I===$CLJS.Z)throw $CLJS.Z;throw I;}throw R;}else throw $CLJS.Z;}catch(R){if(R instanceof Error){I=R;if(I===$CLJS.Z)return $CLJS.CZ(z,
v,x);throw I;}throw R;}}($CLJS.Rg,q))))},n):null:null},$CLJS.G([F8(a,b)]));return $CLJS.Rb(function(l,n){var q=$CLJS.J(n,0,null);n=$CLJS.J(n,1,null);return J8(l,b,c,q,n,w8)},a,g)},I8=function(a,b,c,d){b=qta(c,b);if($CLJS.m(b)){var e=$CLJS.W0(c,b);c=$CLJS.ag(function(f){f=$CLJS.Uf(f);var g=$CLJS.M.h(f,$CLJS.E0),l=$CLJS.M.h(f,$CLJS.z4);return $CLJS.F.h($CLJS.G3,g)&&$CLJS.F.h(d,l)?$CLJS.L3.g(f):null},$CLJS.G5(c,b,e));return $CLJS.m(c)?H8(a,b,a,$CLJS.ZQ,c):a}return a},K8=function(a,b,c,d,e){var f=$CLJS.rW.g(c),
g=$CLJS.W0(a,b);c=$CLJS.DW(function(q){var u=$CLJS.rv(g,q);if($CLJS.m(u)){var v=v8(f);return $CLJS.m($CLJS.ag($CLJS.Um.j($CLJS.vi([v]),$CLJS.MH,$CLJS.$d),u))?q:null}return null},F8(a,b));var l=(d=$CLJS.F.h($CLJS.IL,d))?$CLJS.rW.g(e):null;e=d?function(q,u,v){return pta(q,u,v,l)}:w8;var n=$CLJS.F.h(new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.aQ],null),c);a=d&&n&&$CLJS.F.h($CLJS.C(f),$CLJS.C(l))&&$CLJS.F.h($CLJS.ae(f),$CLJS.ae(l))?Uta(a,b,f,$CLJS.ri($CLJS.$d(l),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.kS,$CLJS.kR],
null))):n?Vta(a,b,f):a;return $CLJS.m(c)?J8(a,b,a,c,f,e):a},Wta=function(a){return $CLJS.Zm.F(a,$CLJS.IZ,$CLJS.mf,new $CLJS.k(null,1,[$CLJS.Zu,$CLJS.UZ],null))},Xta=function(a){if($CLJS.F.h(1,$CLJS.E($CLJS.IZ.g(a))))throw $CLJS.Ej($CLJS.dI("Cannot drop the only stage"),new $CLJS.k(null,1,[$CLJS.IZ,$CLJS.IZ.g(a)],null));return $CLJS.Zm.j(a,$CLJS.IZ,$CLJS.Um.h($CLJS.Sg,$CLJS.zi))},L8=function L8(a){switch(arguments.length){case 2:return L8.h(arguments[0],arguments[1]);case 3:return L8.j(arguments[0],
arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};L8.h=function(a,b){return L8.j(a,null,b)};L8.j=function(a,b,c){b=$CLJS.m(b)?b:-1;c=$CLJS.rW.g(c);return $CLJS.X0(a,b,$CLJS.Zm,$CLJS.G([$CLJS.I_,$CLJS.ig($CLJS.mf,$CLJS.Rg),c]))};L8.v=3;var M8=function M8(a){switch(arguments.length){case 1:return M8.g(arguments[0]);case 2:return M8.h(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};
M8.g=function(a){return M8.h(a,null)};M8.h=function(a,b){return $CLJS.Vf($CLJS.I_.g($CLJS.W0(a,$CLJS.m(b)?b:-1)))};M8.v=2;var N8=function N8(a){switch(arguments.length){case 1:return N8.g(arguments[0]);case 2:return N8.h(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};N8.g=function(a){return N8.h(a,-1)};
N8.h=function(a,b){var c=$CLJS.W0(a,b);a=$CLJS.G5(a,b,c);return $CLJS.Vf($CLJS.uh.j($CLJS.Rg,$CLJS.Hn(function(d){var e=$CLJS.Vf($CLJS.Ym.h(function(f){return $CLJS.U.j(f,$CLJS.Zu,$CLJS.EY)},uta(d)));return $CLJS.m(e)?$CLJS.U.j(d,$CLJS.x5,e):null}),a))};N8.v=2;var O8=function O8(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return O8.l(arguments[0],arguments[1],2<c.length?new $CLJS.y(c.slice(2),0,null):null)};
O8.l=function(a,b,c){return $CLJS.nW($CLJS.uh.h(new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.iW.g(a),$CLJS.P,$CLJS.rW.g(b)],null),$CLJS.xg.h($CLJS.rW,c)))};O8.v=2;O8.B=function(a){var b=$CLJS.C(a),c=$CLJS.D(a);a=$CLJS.C(c);c=$CLJS.D(c);return this.l(b,a,c)};var P8=function P8(a){switch(arguments.length){case 2:return P8.h(arguments[0],arguments[1]);case 3:return P8.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};
P8.h=function(a,b){return P8.j(a,-1,b)};P8.j=function(a,b,c){var d=$CLJS.Je(c,$CLJS.RP)?c:Dta(a,b,c);return $CLJS.X0(a,b,$CLJS.Zm,$CLJS.G([$CLJS.pP,function(e){return $CLJS.mf.h($CLJS.Sg(e),d)}]))};P8.v=3;var E8=function E8(a){switch(arguments.length){case 1:return E8.g(arguments[0]);case 2:return E8.h(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};E8.g=function(a){return E8.h(a,-1)};
E8.h=function(a,b){return $CLJS.Vf($CLJS.M.h($CLJS.W0(a,b),$CLJS.pP))};E8.v=2;var Q8=function Q8(a){switch(arguments.length){case 1:return Q8.g(arguments[0]);case 2:return Q8.h(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};Q8.g=function(a){return Q8.h(a,-1)};Q8.h=function(a){a=$CLJS.U3($CLJS.Y0(a));a=$CLJS.$E.g(a);return $Z($CLJS.hg($CLJS.Je,a),new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.SQ,$CLJS.HO,$CLJS.IR,$CLJS.dS],null))};Q8.v=2;
var R8=function R8(a){switch(arguments.length){case 2:return R8.h(arguments[0],arguments[1]);case 3:return R8.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};R8.h=function(a,b){return R8.j(a,-1,b)};R8.j=function(a,b){return C8($CLJS.F5(a,b,$CLJS.W0(a,b),new $CLJS.k(null,1,[$CLJS.s4,!1],null)))};R8.v=3;
var S8=function S8(a){switch(arguments.length){case 3:return S8.j(arguments[0],arguments[1],arguments[2]);case 4:return S8.F(arguments[0],arguments[1],arguments[2],arguments[3]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};S8.j=function(a,b,c){return S8.F(a,-1,b,c)};S8.F=function(a,b,c){return C8($CLJS.F5(a,b,c,new $CLJS.k(null,1,[$CLJS.s4,!1],null)))};S8.v=4;
var T8=function T8(a){switch(arguments.length){case 3:return T8.j(arguments[0],arguments[1],arguments[2]);case 4:return T8.F(arguments[0],arguments[1],arguments[2],arguments[3]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};T8.j=function(a,b,c){return T8.F(a,-1,b,c)};
T8.F=function(){return new $CLJS.S(null,6,5,$CLJS.T,[D8(),new $CLJS.k(null,3,[$CLJS.Zu,$CLJS.EY,$CLJS.iW,$CLJS.rm,$CLJS.oJ,$CLJS.dI("Greater than")],null),new $CLJS.k(null,3,[$CLJS.Zu,$CLJS.EY,$CLJS.iW,$CLJS.pm,$CLJS.oJ,$CLJS.dI("Less than")],null),new $CLJS.k(null,3,[$CLJS.Zu,$CLJS.EY,$CLJS.iW,$CLJS.Am,$CLJS.oJ,$CLJS.dI("Greater than or equal to")],null),new $CLJS.k(null,3,[$CLJS.Zu,$CLJS.EY,$CLJS.iW,$CLJS.Bm,$CLJS.oJ,$CLJS.dI("Less than or equal to")],null),new $CLJS.k(null,3,[$CLJS.Zu,$CLJS.EY,
$CLJS.iW,$CLJS.YK,$CLJS.oJ,$CLJS.dI("Not equal to")],null)],null)};T8.v=4;var U8=function U8(a){switch(arguments.length){case 2:return U8.h(arguments[0],arguments[1]);case 3:return U8.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};U8.h=function(a,b){return U8.j(a,-1,b)};U8.j=function(a,b,c){c=$CLJS.DW($CLJS.k2,$CLJS.G5(a,b,c));return $CLJS.m(c)?(a=Ita(a,b,c),$CLJS.m(a)?O8.l(D8(),a,$CLJS.G([c])):null):null};U8.v=3;
var V8=function V8(a){switch(arguments.length){case 2:return V8.h(arguments[0],arguments[1]);case 3:return V8.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};V8.h=function(a,b){return V8.j(a,-1,b)};V8.j=function(a,b,c){for(;;)if($CLJS.F.h($CLJS.IH(c),$CLJS.LW))c=$CLJS.oW(c);else return x8(a,b,$CLJS.hQ,c)};V8.v=3;
var W8=function W8(a){switch(arguments.length){case 1:return W8.g(arguments[0]);case 2:return W8.h(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};W8.g=function(a){return W8.h(a,-1)};W8.h=function(a,b){return $CLJS.Vf($CLJS.hQ.g($CLJS.W0(a,b)))};W8.v=2;
var X8=function X8(a){switch(arguments.length){case 1:return X8.g(arguments[0]);case 2:return X8.h(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};X8.g=function(a){return X8.h(a,-1)};
X8.h=function(a,b){var c=function(){var f=$CLJS.$E.g($CLJS.U3($CLJS.Y0(a)));return $CLJS.m(f)?f:$CLJS.ui}(),d=$CLJS.W0(a,b),e=$CLJS.G5(a,b,d);return $CLJS.Vf($CLJS.uh.j($CLJS.Rg,$CLJS.Um.j($CLJS.zg(function(f){f=$CLJS.TW.g(f);var g=null==f;return g?g:c.g?c.g(f):c.call(null,f)}),$CLJS.Hn(function(f){f=$CLJS.Uf(f);var g=$CLJS.M.h(f,$CLJS.PW),l=$CLJS.M.h(f,$CLJS.MW);if($CLJS.Fb(g))return f;if($CLJS.F.h(l,$CLJS.Du))return $CLJS.U.j(f,$CLJS.D0,e);g=$CLJS.Vf($Z(function(n){return $CLJS.g2(l,n)},e));return $CLJS.m(g)?
$CLJS.U.j(f,$CLJS.D0,g):null}),$CLJS.xg.g(function(f){return $CLJS.U.j(f,$CLJS.Zu,$CLJS.OW)})),$CLJS.CW))};X8.v=2;var Y8=function Y8(a){switch(arguments.length){case 1:return Y8.g(arguments[0]);case 2:return Y8.h(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};
Y8.g=function(a){if($CLJS.Fb($CLJS.PW.g(a)))return $CLJS.nW(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.iW.g(a),$CLJS.P],null));var b=$CLJS.iW.g(a);b=$CLJS.xa.h?$CLJS.xa.h("aggregation operator %s requires an argument",b):$CLJS.xa.call(null,"aggregation operator %s requires an argument",b);throw $CLJS.Ej(b,new $CLJS.k(null,1,[$CLJS.cra,a],null));};Y8.h=function(a,b){return $CLJS.nW(new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.iW.g(a),$CLJS.P,$CLJS.rW.g(b)],null))};Y8.v=2;
var Z8=function Z8(a){switch(arguments.length){case 2:return Z8.h(arguments[0],arguments[1]);case 3:return Z8.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};Z8.h=function(a,b){return Z8.j(a,-1,b)};Z8.j=function(a,b,c){c=$CLJS.oW($CLJS.ee(c)?c.h?c.h(a,b):c.call(null,a,b):c);return x8(a,b,$CLJS.aQ,c)};Z8.v=3;
var $8=function $8(a){switch(arguments.length){case 1:return $8.g(arguments[0]);case 2:return $8.h(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};$8.g=function(a){return $8.h(a,-1)};$8.h=function(a,b){return $CLJS.Vf($CLJS.aQ.g($CLJS.W0(a,b)))};$8.v=2;
var a9=function a9(a){switch(arguments.length){case 1:return a9.g(arguments[0]);case 2:return a9.h(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};a9.g=function(a){return a9.h(a,-1)};
a9.h=function(a,b){var c=function(){var f=$CLJS.W0(a,b);return function n(l){return new $CLJS.Cf(null,function(){for(;;){var q=$CLJS.A(l);if(q){if($CLJS.re(q)){var u=$CLJS.$c(q),v=$CLJS.E(u),x=$CLJS.Ff(v);a:for(var z=0;;)if(z<v){var B=$CLJS.ce(u,z);B=$CLJS.U.j(B,$CLJS.I7,$CLJS.oW(B));x.add(B);z+=1}else{u=!0;break a}return u?$CLJS.If($CLJS.Kf(x),n($CLJS.ad(q))):$CLJS.If($CLJS.Kf(x),null)}x=$CLJS.C(q);return $CLJS.pf($CLJS.U.j(x,$CLJS.I7,$CLJS.oW(x)),n($CLJS.Ad(q)))}return null}},null,null)}($CLJS.G5(a,
b,f))}(),d=$CLJS.uh.j($CLJS.P,$CLJS.Qo(function(f,g){var l=$CLJS.xg.h($CLJS.I7,c);g=$CLJS.v1.j?$CLJS.v1.j(a,g,l):$CLJS.v1.call(null,a,g,l);return $CLJS.m(g)?new $CLJS.S(null,2,5,$CLJS.T,[g,f],null):null}),$8.h(a,b)),e=$CLJS.Vf(c);return null==e?null:$CLJS.uh.j($CLJS.Rg,$CLJS.xg.g(function(f){var g=$CLJS.I7.g(f);g=d.g?d.g(g):d.call(null,g);f=$CLJS.m(g)?$CLJS.U.j(f,$CLJS.o4,g):f;return $CLJS.Mm.h(f,$CLJS.I7)}),e)};a9.v=2;
var b9=function b9(a){switch(arguments.length){case 3:return b9.j(arguments[0],arguments[1],arguments[2]);case 4:return b9.F(arguments[0],arguments[1],arguments[2],arguments[3]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};b9.j=function(a,b,c){return b9.F(a,-1,b,c)};
b9.F=function(a,b,c,d){b=$CLJS.m(b)?b:-1;if($CLJS.m(Ota(a,b,c)))throw $CLJS.Ej("Expression name conflicts with a column in the same query stage",new $CLJS.k(null,1,[$CLJS.qV,c],null));return $CLJS.X0(a,b,Pta,$CLJS.G([$CLJS.c3($CLJS.rW.g(d),c)]))};b9.v=4;var c9=function c9(a){switch(arguments.length){case 1:return c9.g(arguments[0]);case 2:return c9.h(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};c9.g=function(a){return c9.h(a,-1)};
c9.h=function(a,b){return $CLJS.Vf($CLJS.jR.g($CLJS.W0(a,b)))};c9.v=2;var d9=function d9(a){switch(arguments.length){case 2:return d9.h(arguments[0],arguments[1]);case 3:return d9.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};d9.h=function(a,b){return d9.j(a,-1,b)};
d9.j=function(a,b,c){var d=$CLJS.uh.h($CLJS.P,$CLJS.jg(function(f,g){return new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.g0(g),f],null)},c9.h(a,b))),e=$CLJS.W0(a,b);a=$CLJS.G5(a,b,e);return $CLJS.Vf($Z(function(f){return $CLJS.Fb(c)||$CLJS.qi.h($CLJS.E0.g(f),$CLJS.I3)||$CLJS.M.h(d,$CLJS.V.g(f))<c},a))};d9.v=3;
var e9=function e9(a){switch(arguments.length){case 1:return e9.g(arguments[0]);case 2:return e9.h(arguments[0],arguments[1]);case 3:return e9.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};e9.g=function(a){return function(b,c){return e9.j(b,c,a)}};e9.h=function(a,b){return e9.j(a,-1,b)};
e9.j=function(a,b,c){var d=$CLJS.Vf($CLJS.Ym.h($CLJS.oW,c));c=function(){return null==d?null:$CLJS.uh.j(d,$CLJS.Xm.g(function(e){return $CLJS.v1.h?$CLJS.v1.h(e,d):$CLJS.v1.call(null,e,d)}),Qta(a,b))}();return $CLJS.X0(a,b,$CLJS.kW,$CLJS.G([$CLJS.SO,c]))};e9.v=3;var f9=function f9(a){switch(arguments.length){case 1:return f9.g(arguments[0]);case 2:return f9.h(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};f9.g=function(a){return f9.h(a,-1)};
f9.h=function(a,b){return $CLJS.SO.g($CLJS.W0(a,b))};f9.v=2;var g9=function g9(a){switch(arguments.length){case 1:return g9.g(arguments[0]);case 2:return g9.h(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};g9.g=function(a){return g9.h(a,-1)};
g9.h=function(a,b){var c=f9.h(a,b),d=$CLJS.je(c)?$CLJS.gg(!0):function(e){var f=$CLJS.oW(e);return $CLJS.ye($CLJS.ag(function(g){var l=$CLJS.R0.h(f,g);return $CLJS.m(l)?l:$CLJS.R0.h(y8(f),y8(g))},c))};return $CLJS.Ym.h(function(e){return $CLJS.U.j(e,$CLJS.M3,d(e))},$CLJS.F5(a,b,$CLJS.W0(a,b),new $CLJS.k(null,3,[$CLJS.u4,!1,$CLJS.A4,!1,$CLJS.s4,!1],null)))};g9.v=2;
var h9=function h9(a){switch(arguments.length){case 1:return h9.g(arguments[0]);case 2:return h9.h(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};h9.g=function(a){return h9.h(a,$CLJS.aK)};h9.h=function(a,b){a=$CLJS.T7.g(a);b=$CLJS.m(b)?b:$CLJS.aK;return $CLJS.U.j($CLJS.Sg(a),0,b)};h9.v=2;
var i9=function i9(a){switch(arguments.length){case 2:return i9.h(arguments[0],arguments[1]);case 3:return i9.j(arguments[0],arguments[1],arguments[2]);case 4:return i9.F(arguments[0],arguments[1],arguments[2],arguments[3]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};i9.h=function(a,b){return i9.F(a,-1,b,null)};i9.j=function(a,b,c){return i9.F(a,-1,b,c)};
i9.F=function(a,b,c,d){b=$CLJS.m(b)?b:-1;var e=function(){var f=$CLJS.T7.g(c);return $CLJS.m(d)?$CLJS.U.j($CLJS.Sg(f),0,d):f}();return $CLJS.X0(a,b,$CLJS.Zm,$CLJS.G([$CLJS.tS,function(f){return $CLJS.mf.h($CLJS.Sg(f),e)}]))};i9.v=4;var j9=function j9(a){switch(arguments.length){case 1:return j9.g(arguments[0]);case 2:return j9.h(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};j9.g=function(a){return j9.h(a,-1)};
j9.h=function(a,b){return $CLJS.Vf($CLJS.M.h($CLJS.W0(a,b),$CLJS.tS))};j9.v=2;var k9=function k9(a){switch(arguments.length){case 1:return k9.g(arguments[0]);case 2:return k9.h(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};k9.g=function(a){return k9.h(a,-1)};
k9.h=function(a,b){function c(g){return $CLJS.ag(function(l){var n=$CLJS.J(l,0,null);l=$CLJS.J(l,1,null);var q=$CLJS.oW(g),u=$CLJS.R0.h(q,l);l=$CLJS.m(u)?u:$CLJS.R0.h(q,y8(l));return $CLJS.m(l)?n:null},d)}var d=$CLJS.jg(function(g,l){$CLJS.J(l,0,null);$CLJS.J(l,1,null);l=$CLJS.J(l,2,null);return new $CLJS.S(null,2,5,$CLJS.T,[g,l],null)},j9.h(a,b)),e=$CLJS.Vf($CLJS.b8.h(a,b)),f=$CLJS.Vf($CLJS.f8.h(a,b));e=$CLJS.m($CLJS.m(e)?e:f)?$CLJS.wg.h(e,f):function(){var g=$CLJS.W0(a,b);return $CLJS.G5(a,b,g)}();
e=$CLJS.Vf(e);return null==e?null:$CLJS.uh.j($CLJS.Rg,$CLJS.Um.h($CLJS.zg(Sta),$CLJS.xg.g(function(g){var l=c(g);return $CLJS.m(l)?$CLJS.U.j(g,$CLJS.p4,l):g})),e)};k9.v=2;var l9=function l9(a){switch(arguments.length){case 2:return l9.h(arguments[0],arguments[1]);case 3:return l9.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};l9.h=function(a,b){return l9.j(a,-1,b)};l9.j=function(a,b,c){return K8(a,b,c,$CLJS.qva,null)};
l9.v=3;var m9=function m9(a){switch(arguments.length){case 3:return m9.j(arguments[0],arguments[1],arguments[2]);case 4:return m9.F(arguments[0],arguments[1],arguments[2],arguments[3]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};m9.j=function(a,b,c){return m9.F(a,-1,b,c)};m9.F=function(a,b,c,d){return K8(a,b,c,$CLJS.IL,d)};m9.v=4;$CLJS.Yta=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.y(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.Q.h(V8,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.n9=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.y(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.Q.h(Y8,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.Zta=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.y(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.Q.h(Mta,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.$ta=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.y(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.Q.h(W8,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.aua=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.y(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.Q.h(X8,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.bua=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.y(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.Q.h(Nta,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.o9=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.y(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.Q.h($CLJS.b6,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.cua=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.y(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.Q.h($CLJS.S5,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.dua=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.y(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.Q.h($CLJS.a8,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.eua=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.y(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.Q.h(Z8,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.fua=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.y(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.Q.h(a9,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.gua=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.y(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.Q.h($8,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.hua=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.y(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.Q.h(Lta,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.iua=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.y(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.Q.h(Kta,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.jua=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.y(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.Q.h(ota,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.kua=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.y(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.Q.h(b9,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.lua=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.y(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.Q.h(c9,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.mua=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.y(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.Q.h(d9,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.nua=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.y(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.Q.h(f9,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.oua=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.y(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.Q.h(e9,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.pua=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.y(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.Q.h(g9,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.qua=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.y(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.Q.h(L8,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.rua=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.y(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.Q.h(M8,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.sua=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.y(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.Q.h(N8,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.tua=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.y(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.Q.h(vta,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.uua=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.y(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.Q.h(O8,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.vua=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.y(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.Q.h(Q8,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.wua=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.y(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.Q.h(P8,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.xua=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.y(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.Q.h($CLJS.r8,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.yua=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.y(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.Q.h(R8,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.zua=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.y(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.Q.h(T8,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.Aua=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.y(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.Q.h(S8,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.Bua=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.y(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.Q.h(Eta,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.Cua=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.y(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.Q.h(Fta,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.Dua=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.y(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.Q.h(Gta,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.Eua=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.y(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.Q.h(E8,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.Fua=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.y(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.Q.h(U8,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.Gua=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.y(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.Q.h($CLJS.q8,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.Hua=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.y(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.Q.h(Hta,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.Iua=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.y(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.Q.h($CLJS.p8,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.Jua=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.y(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.Q.h($CLJS.f1,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.Kua=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.y(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.Q.h(sta,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.Lua=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.y(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.Q.h(Rta,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.Mua=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.y(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.Q.h($CLJS.Gsa,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.Nua=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.y(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.Q.h($CLJS.Hsa,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.Oua=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.y(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.Q.h($CLJS.P7,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.Pua=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.y(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.Q.h($CLJS.Q7,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.Qua=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.y(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.Q.h(Tta,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.Rua=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.y(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.Q.h(i9,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.Sua=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.y(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.Q.h(h9,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.Tua=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.y(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.Q.h(j9,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.Uua=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.y(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.Q.h(k9,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.p9=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.y(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.Q.h($CLJS.T3,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.Vua=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.y(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.Q.h($CLJS.r5,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.Wua=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.y(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.Q.h($CLJS.oW,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.Xua=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.y(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.Q.h(l9,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.Yua=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.y(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.Q.h(m9,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.Zua=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.y(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.Q.h(Wta,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.$ua=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.y(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.Q.h(Xta,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.ava=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.y(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.Q.h($CLJS.H1,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.bva=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.y(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.Q.h($CLJS.p2,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.cva=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.y(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.Q.h(tta,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.q9=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.y(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.Q.h($CLJS.a6,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.dva=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.y(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.Q.h($CLJS.Z7,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.eva=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.y(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.Q.h($CLJS.Y7,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();