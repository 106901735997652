var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./medley.core.js");require("./metabase.lib.aggregation.js");require("./metabase.lib.binning.js");require("./metabase.lib.card.js");require("./metabase.lib.equality.js");require("./metabase.lib.expression.js");require("./metabase.lib.join.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.normalize.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.ref.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.shared.util.time.js");require("./metabase.util.js");require("./metabase.util.humanization.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var Ara,T5,Fra,Pra,Qra,Rra,Sra,X5,Y5,$5,A$,D9,E9,iva,B9,jva;$CLJS.E5=function(a,b){a=$CLJS.Uf(a);a=$CLJS.M.h(a,$CLJS.IZ);b=$CLJS.V0(a,b);return 0<b?b-1:null};$CLJS.F5=function(a,b,c,d){d=$CLJS.pn.l($CLJS.G([new $CLJS.k(null,4,[$CLJS.w4,$CLJS.e3(),$CLJS.u4,!0,$CLJS.A4,!0,$CLJS.s4,!0],null),d]));return $CLJS.F1.F(a,b,c,d)};$CLJS.G5=function(a,b,c){return $CLJS.F5(a,b,c,null)};Ara=function(a,b){b=$CLJS.$0(a,b);return $CLJS.m(b)?$CLJS.t5(a,b):null};
$CLJS.R5=function(a){var b=$CLJS.IH(a);b=b instanceof $CLJS.N?b.S:null;switch(b){case "field":return $CLJS.HQ.g($CLJS.KH(a));case "metadata/column":return $CLJS.D4.g(a);case "mbql/join":return $CLJS.RP.g(a);default:throw Error(["No matching clause: ",$CLJS.p.g(b)].join(""));}};$CLJS.S5=function(a){return $CLJS.l5.g(a)};T5=function(a,b){return $CLJS.F.h($CLJS.u7.g(a),$CLJS.ri(b,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.LL,$CLJS.YI,$CLJS.DL],null)))};
Fra=function(a){var b=new $CLJS.k(null,3,[$CLJS.MH,$CLJS.p.g($CLJS.tL()),$CLJS.bH,$CLJS.bH.g(a),$CLJS.rk,$CLJS.Wm.h($CLJS.rk,$CLJS.bH)(a)],null);return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.ZG,b,$CLJS.V.g(a)],null)};Pra=function(a){return $CLJS.S3(a,new $CLJS.k(null,1,[$CLJS.LL,$CLJS.Ui],null))};
Qra=function(a,b,c){var d=$CLJS.pn.l;b=$CLJS.Fb($CLJS.E5(a,b))?function(){var f=$CLJS.QZ.g($CLJS.C($CLJS.IZ.g(a)));return $CLJS.m(f)?(f=Ara(a,f),$CLJS.m(f)?$CLJS.DW(function(g){return $CLJS.F.h($CLJS.Mk.g(g),c)},f):null):null}():null;try{var e=$CLJS.W3(a,c)}catch(f){e=null}return d.call($CLJS.pn,$CLJS.G([b,e]))};
Rra=function(a,b){var c=$CLJS.DW(function(d){return $CLJS.F.h($CLJS.L3.g(d),a)},b);if($CLJS.m(c))return c;c=$CLJS.DW(function(d){return $CLJS.F.h($CLJS.V.g(d),a)},b);if($CLJS.m(c))return c;c=$CLJS.hG($CLJS.HE);$CLJS.m($CLJS.mG("metabase.lib.field",c))&&(b=$CLJS.cI("Invalid :field clause: column {0} does not exist. Found: {1}",$CLJS.G([$CLJS.gj.l($CLJS.G([a])),$CLJS.gj.l($CLJS.G([$CLJS.Ym.h($CLJS.L3,b)]))])),b instanceof Error?$CLJS.nG("metabase.lib.field",c,$CLJS.HC(),b):$CLJS.nG("metabase.lib.field",
c,$CLJS.HC.l($CLJS.G([b])),null));return null};
Sra=function(a,b,c){if($CLJS.m(m7))return null;var d=m7;m7=!0;try{var e=$CLJS.E5(a,b),f=$CLJS.m(e)?$CLJS.W0(a,e):$CLJS.W0(a,b),g=function(){var n=$CLJS.A9.g(f);if($CLJS.m(n))return n;n=$CLJS.rv(f,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.PZ,$CLJS.D0],null));if($CLJS.m(n))return n;n=$CLJS.m($CLJS.QZ.g(f))?$CLJS.G5(a,b,f):null;if($CLJS.m(n))return n;n=$CLJS.hG($CLJS.HE);if($CLJS.m($CLJS.mG("metabase.lib.field",n))){var q=$CLJS.cI("Cannot resolve column {0}: stage has no metadata",$CLJS.G([$CLJS.gj.l($CLJS.G([c]))]));
return q instanceof Error?$CLJS.nG("metabase.lib.field",n,$CLJS.HC(),q):$CLJS.nG("metabase.lib.field",n,$CLJS.HC.l($CLJS.G([q])),null)}return null}(),l=function(){var n=$CLJS.A(g);return n?Rra(c,g):n}();return $CLJS.m(l)?$CLJS.m(e)?$CLJS.U.j($CLJS.U.j($CLJS.$3($CLJS.Mm.l(l,$CLJS.Mk,$CLJS.G([$CLJS.J0,B9,$CLJS.C9])),null),$CLJS.V,function(){var n=$CLJS.L3.g(l);return $CLJS.m(n)?n:$CLJS.V.g(l)}()),$CLJS.E0,$CLJS.G3):l:null}finally{m7=d}};
X5=function(a,b,c){$CLJS.J(c,0,null);var d=$CLJS.J(c,1,null),e=$CLJS.Uf(d);d=$CLJS.M.h(e,$CLJS.HQ);var f=$CLJS.J(c,2,null);c=$CLJS.pn.l($CLJS.G([function(){var g=$CLJS.bH.g(e);return $CLJS.m(g)?new $CLJS.k(null,1,[$CLJS.bH,g],null):null}(),function(){var g=$CLJS.Wm.h($CLJS.rk,$CLJS.bH)(e);return $CLJS.m(g)?new $CLJS.k(null,1,[$CLJS.rk,g],null):null}(),function(){var g=$CLJS.kS.g(e);return $CLJS.m(g)?new $CLJS.k(null,1,[B9,g],null):null}(),function(){var g=$CLJS.kR.g(e);return $CLJS.m(g)?new $CLJS.k(null,
1,[$CLJS.C9,g],null):null}(),$CLJS.Ae(f)?function(){var g=Qra(a,b,f);return $CLJS.m(g)?g:new $CLJS.k(null,2,[$CLJS.Zu,$CLJS.KW,$CLJS.V,f],null)}():$CLJS.m(d)?new $CLJS.k(null,2,[$CLJS.Zu,$CLJS.KW,$CLJS.V,f],null):function(){var g=Sra(a,b,f);return $CLJS.m(g)?g:new $CLJS.k(null,2,[$CLJS.Zu,$CLJS.KW,$CLJS.V,f],null)}()]));return $CLJS.m(d)?$CLJS.$3(c,d):c};Y5=function(a){a=$CLJS.Uf(a);var b=$CLJS.M.h(a,$CLJS.C9);return $CLJS.m($CLJS.m(b)?$CLJS.Je($CLJS.cW,b):b)?$CLJS.ol:$CLJS.Wm.h($CLJS.rk,$CLJS.bH)(a)};
$CLJS.Z5=function(a,b){return"string"===typeof b?$CLJS.$0(a,$CLJS.l0(b)):$CLJS.Ae(b)?$CLJS.Z0(a,b):null};$5=function(a,b,c){return $CLJS.m($CLJS.ag(function(d){return $CLJS.F.h($CLJS.bk.g(d),c)},a))?$CLJS.Ym.h(function(d){var e=$CLJS.Mm.h(d,b);return $CLJS.F.h($CLJS.bk.g(d),c)?$CLJS.U.j(e,b,!0):e},a):a};
A$=function(a){var b=function(){var d=$CLJS.E0.g(a),e=new $CLJS.ti(null,new $CLJS.k(null,3,[$CLJS.N3,null,$CLJS.P3,null,$CLJS.G3,null],null),null);return e.g?e.g(d):e.call(null,d)}(),c=$CLJS.pn.l($CLJS.G([new $CLJS.k(null,3,[$CLJS.MH,$CLJS.p.g($CLJS.tL()),$CLJS.bH,$CLJS.bH.g(a),$CLJS.rk,Y5(a)],null),function(){var d=$CLJS.R5(a);return $CLJS.m(d)?new $CLJS.k(null,1,[$CLJS.HQ,d],null):null}(),function(){var d=$CLJS.C9.g(a);return $CLJS.m(d)?new $CLJS.k(null,1,[$CLJS.kR,d],null):null}(),function(){var d=
B9.g(a);return $CLJS.m(d)?new $CLJS.k(null,1,[$CLJS.kS,d],null):null}(),function(){var d=$CLJS.ER.g(a);return $CLJS.m(d)?new $CLJS.k(null,1,[$CLJS.qP,d],null):null}()]));return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.ZQ,c,$CLJS.m(b)?function(){var d=$CLJS.L3.g(a);return $CLJS.m(d)?d:$CLJS.V.g(a)}():function(){var d=$CLJS.Mk.g(a);return $CLJS.m(d)?d:$CLJS.V.g(a)}()],null)};
$CLJS.a6=function a6(a){switch(arguments.length){case 2:return a6.h(arguments[0],arguments[1]);case 3:return a6.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};$CLJS.a6.h=function(a,b){return $CLJS.a6.j(a,-1,b)};$CLJS.a6.j=function(a,b,c){return $CLJS.K1.j(a,b,c)};$CLJS.a6.v=3;
$CLJS.b6=function b6(a){switch(arguments.length){case 2:return b6.h(arguments[0],arguments[1]);case 3:return b6.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};$CLJS.b6.h=function(a,b){return $CLJS.b6.j(a,-1,b)};$CLJS.b6.j=function(a,b,c){return $CLJS.m5.j(a,b,c)};$CLJS.b6.v=3;$CLJS.C9=new $CLJS.N("metabase.lib.field","temporal-unit","metabase.lib.field/temporal-unit",602312727);
D9=new $CLJS.N(null,"parent-id","parent-id",-1400729131);$CLJS.A9=new $CLJS.N("metabase.lib.stage","cached-metadata","metabase.lib.stage/cached-metadata",-1406479151);E9=new $CLJS.N("metabase.lib.field","original-effective-type","metabase.lib.field/original-effective-type",34164372);iva=new $CLJS.N(null,"earliest","earliest",-1928154382);B9=new $CLJS.N("metabase.lib.field","binning","metabase.lib.field/binning",-222751823);jva=new $CLJS.N(null,"latest","latest",24323665);$CLJS.T3.o(null,$CLJS.ZQ,function(a){var b=$CLJS.J(a,0,null),c=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Ui.g(b),$CLJS.S3(c,new $CLJS.k(null,2,[$CLJS.kR,$CLJS.Ui,$CLJS.kS,Pra],null)),a],null)});var m7=!1,e6=function e6(a,b){var d=$CLJS.W3(a,D9.g(b));a=$CLJS.m(D9.g(d))?e6.h?e6.h(a,d):e6.call(null,a,d):d;a=$CLJS.Uf(a);var e=$CLJS.M.h(a,$CLJS.V);return $CLJS.Zm.j(b,$CLJS.V,function(f){return[$CLJS.p.g(e),".",$CLJS.p.g(f)].join("")})};
$CLJS.C1.o(null,$CLJS.KW,function(a,b,c){return Y5(c)});$CLJS.C1.o(null,$CLJS.ZQ,function(a,b,c){$CLJS.J(c,0,null);var d=$CLJS.J(c,1,null);d=$CLJS.Uf(d);d=$CLJS.M.h(d,$CLJS.kR);$CLJS.J(c,2,null);c=X5(a,b,c);c=$CLJS.m(d)?$CLJS.U.j(c,$CLJS.C9,d):c;return $CLJS.D1.j(a,b,c)});$CLJS.a1.o(null,$CLJS.KW,function(a,b,c){a=$CLJS.Uf(c);b=$CLJS.M.h(a,$CLJS.V);return $CLJS.U.j(a,$CLJS.V,b)});
$CLJS.a1.o(null,$CLJS.ZQ,function(a,b,c){$CLJS.J(c,0,null);var d=$CLJS.J(c,1,null),e=$CLJS.Uf(d),f=$CLJS.M.h(e,$CLJS.MH),g=$CLJS.M.h(e,$CLJS.bH),l=$CLJS.M.h(e,$CLJS.kS),n=$CLJS.M.h(e,$CLJS.rk);d=$CLJS.M.h(e,$CLJS.HQ);var q=$CLJS.M.h(e,$CLJS.qP),u=$CLJS.M.h(e,$CLJS.kR),v=X5(a,b,c),x=$CLJS.pn.l;f=new $CLJS.k(null,2,[$CLJS.Zu,$CLJS.KW,$CLJS.z4,f],null);e=$CLJS.oJ.g(e);b=$CLJS.m(e)?e:$CLJS.c1.j(a,b,c);b=x.call($CLJS.pn,$CLJS.G([f,v,new $CLJS.k(null,1,[$CLJS.oJ,b],null),$CLJS.m(n)?new $CLJS.k(null,1,[$CLJS.rk,
n],null):null,$CLJS.m(g)?new $CLJS.k(null,1,[$CLJS.bH,g],null):null,$CLJS.m(u)?new $CLJS.k(null,1,[$CLJS.C9,u],null):null,$CLJS.m(l)?new $CLJS.k(null,1,[B9,l],null):null,$CLJS.m(q)?new $CLJS.k(null,1,[$CLJS.ER,q],null):null]));d=$CLJS.m(d)?$CLJS.$3(b,d):b;return $CLJS.m(D9.g(d))?e6(a,d):d});
$CLJS.y1.o(null,$CLJS.KW,function(a,b,c,d){c=$CLJS.Uf(c);var e=$CLJS.M.h(c,$CLJS.oJ),f=$CLJS.M.h(c,$CLJS.V),g=$CLJS.M.h(c,$CLJS.bk),l=$CLJS.M.h(c,B9),n=$CLJS.M.h(c,$CLJS.d4),q=$CLJS.M.h(c,$CLJS.ER),u=$CLJS.M.h(c,$CLJS.J0);e=$CLJS.m(e)?e:"string"===typeof f?$CLJS.p5.h($CLJS.B4,f):$CLJS.p.g(f);$CLJS.F.h(d,$CLJS.e4)&&-1==e.indexOf(" → ")?($CLJS.m(q)?(q=$CLJS.W3(a,q),$CLJS.m(q)?a=(0,$CLJS.Va)($CLJS.vv($CLJS.oJ.g($CLJS.f1.j(a,b,q)),$CLJS.t0,"")):(u=$CLJS.Z5(a,u),a=$CLJS.c1.F(a,b,u,d))):a=null,n=$CLJS.m(a)?
a:$CLJS.m(n)?n:$CLJS.R5(c)):n=null;n=$CLJS.m(n)?[$CLJS.p.g(n)," → ",$CLJS.p.g(e)].join(""):e;return $CLJS.m(g)?(c=$CLJS.dG($CLJS.vv($CLJS.Ai(g),"-"," ")),$CLJS.xa.j?$CLJS.xa.j("%s: %s",n,c):$CLJS.xa.call(null,"%s: %s",n,c)):$CLJS.m(l)?(c=$CLJS.g5(l,c),$CLJS.xa.j?$CLJS.xa.j("%s: %s",n,c):$CLJS.xa.call(null,"%s: %s",n,c)):n});
$CLJS.y1.o(null,$CLJS.ZQ,function(a,b,c,d){$CLJS.J(c,0,null);var e=$CLJS.J(c,1,null),f=$CLJS.Uf(e);e=$CLJS.M.h(f,$CLJS.kS);var g=$CLJS.M.h(f,$CLJS.HQ),l=$CLJS.M.h(f,$CLJS.kR);f=$CLJS.M.h(f,$CLJS.qP);$CLJS.J(c,2,null);c=X5(a,b,c);c=$CLJS.m(g)?$CLJS.U.j(c,$CLJS.d4,g):c;l=$CLJS.m(l)?$CLJS.U.j(c,$CLJS.bk,l):c;e=$CLJS.m(e)?$CLJS.U.j(l,B9,e):l;e=$CLJS.m(f)?$CLJS.U.j(e,$CLJS.ER,f):e;return $CLJS.m(e)?$CLJS.c1.F(a,b,e,d):$CLJS.dI("[Unknown Field]")});
$CLJS.z1.o(null,$CLJS.KW,function(a,b,c){a=$CLJS.Uf(c);return $CLJS.M.h(a,$CLJS.V)});$CLJS.z1.o(null,$CLJS.ZQ,function(a,b,c){$CLJS.J(c,0,null);$CLJS.J(c,1,null);c=X5(a,b,c);return $CLJS.m(c)?$CLJS.A1.j(a,b,c):"unknown_field"});
$CLJS.E1.o(null,$CLJS.KW,function(a,b,c){return $CLJS.pn.l($CLJS.G([function(){var d=$CLJS.yj($CLJS.E1,$CLJS.Uj);return d.j?d.j(a,b,c):d.call(null,a,b,c)}(),$CLJS.F.h($CLJS.E0.g(c),$CLJS.N3)?function(){var d=$CLJS.C4.g(c);return $CLJS.m(d)?(d=$CLJS.$0(a,d),$CLJS.m(d)?new $CLJS.k(null,1,[$CLJS.K0,new $CLJS.k(null,2,[$CLJS.V,$CLJS.V.g(d),$CLJS.oJ,$CLJS.V.g(d)],null)],null):null):null}():null]))});$CLJS.J1.o(null,$CLJS.ZQ,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);$CLJS.J(a,2,null);return $CLJS.kR.g(b)});
$CLJS.J1.o(null,$CLJS.KW,function(a){return $CLJS.C9.g(a)});
$CLJS.I1.o(null,$CLJS.ZQ,function(a,b){$CLJS.J(a,0,null);var c=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);if($CLJS.m(b)){var d=$CLJS.Je($CLJS.cW,b),e=$CLJS.Wm.j(E9,$CLJS.rk,$CLJS.bH)(c);c=$CLJS.U.l(c,$CLJS.kR,b,$CLJS.G([$CLJS.rk,d?$CLJS.ol:e,E9,e]));return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.ZQ,c,a],null)}b=E9.g(c);c=$CLJS.m(b)?$CLJS.Mm.h($CLJS.U.j(c,$CLJS.rk,b),E9):c;c=$CLJS.Mm.h(c,$CLJS.kR);return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.ZQ,c,a],null)});
$CLJS.I1.o(null,$CLJS.KW,function(a,b){return $CLJS.m(b)?$CLJS.U.j(a,$CLJS.C9,b):$CLJS.Mm.h(a,$CLJS.C9)});$CLJS.K1.o(null,$CLJS.ZQ,function(a,b,c){return $CLJS.a6.j(a,b,X5(a,b,c))});
$CLJS.K1.o(null,$CLJS.KW,function(a,b,c){if($CLJS.qi.h($CLJS.E0.g(c),$CLJS.I3)){a=$CLJS.Wm.h($CLJS.rk,$CLJS.bH)(c);b=null==c?null:$CLJS.zP.g(c);if(null==b)var d=null;else try{var e=$CLJS.nK.g($CLJS.bl.g(b));if($CLJS.m(e)){var f=$CLJS.Uf(e),g=$CLJS.M.h(f,iva),l=$CLJS.M.h(f,jva),n=$CLJS.yra.l($CLJS.G([$CLJS.Q5.g(g),$CLJS.Q5.g(l)]));d=isNaN(n)?null:$CLJS.m($CLJS.Yt.h?$CLJS.Yt.h(1,n):$CLJS.Yt.call(null,1,n))?$CLJS.Mt:$CLJS.m($CLJS.Yt.h?$CLJS.Yt.h(31,n):$CLJS.Yt.call(null,31,n))?$CLJS.zv:$CLJS.m($CLJS.Yt.h?
$CLJS.Yt.h(365,n):$CLJS.Yt.call(null,365,n))?$CLJS.dl:$CLJS.rl}else d=null}catch(q){if(q instanceof Error)d=null;else throw q;}e=$CLJS.XH(a,$CLJS.nK)?$CLJS.Goa:$CLJS.XH(a,$CLJS.jJ)?$CLJS.Foa:$CLJS.XH(a,$CLJS.ML)?$CLJS.Eoa:$CLJS.Rg;d=$CLJS.m(d)?$5(e,$CLJS.Uj,d):e;return $CLJS.m($CLJS.C9.g(c))?$5(d,$CLJS.n4,$CLJS.C9.g(c)):d}return $CLJS.Rg});
$CLJS.l5.o(null,$CLJS.ZQ,function(a){var b=null==a?null:$CLJS.KH(a);b=null==b?null:$CLJS.kS.g(b);return null==b?null:$CLJS.U.l(b,$CLJS.Zu,$CLJS.H7,$CLJS.G([$CLJS.q4,function(c,d){return X5(c,d,a)}]))});$CLJS.l5.o(null,$CLJS.KW,function(a){var b=null==a?null:B9.g(a);return null==b?null:$CLJS.U.l(b,$CLJS.Zu,$CLJS.H7,$CLJS.G([$CLJS.q4,$CLJS.gg(a)]))});$CLJS.k5.o(null,$CLJS.ZQ,function(a,b){return $CLJS.lW(a,$CLJS.kW,$CLJS.G([$CLJS.kS,b]))});
$CLJS.k5.o(null,$CLJS.KW,function(a,b){return $CLJS.kW(a,B9,b)});$CLJS.m5.o(null,$CLJS.ZQ,function(a,b,c){return $CLJS.b6.j(a,b,X5(a,b,c))});
$CLJS.m5.o(null,$CLJS.KW,function(a,b,c){b=$CLJS.Uf(c);c=$CLJS.M.h(b,$CLJS.rk);var d=$CLJS.M.h(b,$CLJS.zP),e=$CLJS.M.h(b,$CLJS.Ol);if($CLJS.qi.h($CLJS.E0.g(b),$CLJS.I3)){var f=function(){var n=null==a?null:$CLJS.U3($CLJS.Y0(a));n=null==n?null:$CLJS.$E.g(n);return null==n?null:$CLJS.Je(n,$CLJS.kS)}(),g=$CLJS.rv(d,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.bl,$CLJS.Jl],null)),l=$CLJS.S5(b);return function u(q){return new $CLJS.Cf(null,function(){for(;;){var v=$CLJS.A(q);if(v){if($CLJS.re(v)){var x=$CLJS.$c(v),
z=$CLJS.E(x),B=$CLJS.Ff(z);return function(){for(var I=0;;)if(I<z){var R=$CLJS.ce(x,I),X=B,ba=R;R=T5(R,l)?$CLJS.U.j(ba,$CLJS.n4,!0):ba;X.add(R);I+=1}else return!0}()?$CLJS.If($CLJS.Kf(B),u($CLJS.ad(v))):$CLJS.If($CLJS.Kf(B),null)}var H=$CLJS.C(v);return $CLJS.pf(function(){var I=H;return T5(H,l)?$CLJS.U.j(I,$CLJS.n4,!0):I}(),u($CLJS.Ad(v)))}return null}},null,null)}($CLJS.Fb(function(){if($CLJS.m(f)){var q=$CLJS.wk.g(g);return $CLJS.m(q)?$CLJS.em.g(g):q}return f}())?null:$CLJS.XH(e,$CLJS.sl)?$CLJS.r($CLJS.Tqa):
$CLJS.XH(c,$CLJS.Jl)&&!$CLJS.XH(e,$CLJS.yf)?$CLJS.r($CLJS.Sqa):null)}return $CLJS.Rg});$CLJS.jW.o(null,$CLJS.ZQ,function(a){return a});
$CLJS.jW.o(null,$CLJS.KW,function(a){a=$CLJS.Uf(a);var b=$CLJS.M.h(a,$CLJS.E0);switch(b instanceof $CLJS.N?b.S:null){case "source/aggregations":return b=new $CLJS.k(null,2,[$CLJS.MH,$CLJS.p.g($CLJS.tL()),$CLJS.rk,$CLJS.Wm.h($CLJS.rk,$CLJS.bH)(a)],null),a=$CLJS.z4.g(a),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.hQ,b,a],null);case "source/expressions":return Fra(a);case "source/breakouts":return $CLJS.Je(a,$CLJS.xM)?Fra(a):A$(a);default:return A$(a)}});