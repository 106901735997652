var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.common.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.filter.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var s5,w5,y5,z5;$CLJS.o2=function(a){if("number"===typeof a)return a;switch(a instanceof $CLJS.N?a.S:null){case "current":return 0;case "next":return 1;case "last":return-1;default:return 0}};
$CLJS.p2=function(a,b){a=$CLJS.o2(a);b=$CLJS.m(b)?b:$CLJS.zv;return 0===a?$CLJS.F.h(b,$CLJS.zv)?$CLJS.dI("Today"):$CLJS.cI("This {0}",$CLJS.G([$CLJS.H1.g(b)])):$CLJS.F.h(a,1)?$CLJS.F.h(b,$CLJS.zv)?$CLJS.dI("Tomorrow"):$CLJS.cI("Next {0}",$CLJS.G([$CLJS.H1.g(b)])):$CLJS.F.h(a,-1)?$CLJS.F.h(b,$CLJS.zv)?$CLJS.dI("Yesterday"):$CLJS.cI("Previous {0}",$CLJS.G([$CLJS.H1.g(b)])):0>a?$CLJS.cI("Previous {0} {1}",$CLJS.G([Math.abs(a),$CLJS.H1.h(Math.abs(a),b)])):0<a?$CLJS.cI("Next {0} {1}",$CLJS.G([a,$CLJS.H1.h(a,
b)])):null};s5=new $CLJS.N("metabase.lib.filter","unary","metabase.lib.filter/unary",-336199667);w5=new $CLJS.N("metabase.lib.filter","varargs","metabase.lib.filter/varargs",1417033079);$CLJS.x5=new $CLJS.N(null,"operators","operators",-2064102509);y5=new $CLJS.N("metabase.lib.filter","compound","metabase.lib.filter/compound",1924103780);z5=new $CLJS.N("metabase.lib.filter","binary","metabase.lib.filter/binary",-1921360491);for(var A5=$CLJS.A(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Qj,$CLJS.Hk],null)),B5=null,C5=0,D5=0;;)if(D5<C5){var qqa=B5.$(null,D5);$CLJS.tV(qqa,y5);D5+=1}else{var U5=$CLJS.A(A5);if(U5){var V5=U5;if($CLJS.re(V5)){var W5=$CLJS.$c(V5),rqa=$CLJS.ad(V5),sqa=W5,tqa=$CLJS.E(W5);A5=rqa;B5=sqa;C5=tqa}else{var uqa=$CLJS.C(V5);$CLJS.tV(uqa,y5);A5=$CLJS.D(V5);B5=null;C5=0}D5=0}else break}
for(var c6=$CLJS.A(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.om,$CLJS.YK],null)),d6=null,i6=0,j6=0;;)if(j6<i6){var vqa=d6.$(null,j6);$CLJS.tV(vqa,w5);j6+=1}else{var k6=$CLJS.A(c6);if(k6){var r6=k6;if($CLJS.re(r6)){var I6=$CLJS.$c(r6),wqa=$CLJS.ad(r6),xqa=I6,yqa=$CLJS.E(I6);c6=wqa;d6=xqa;i6=yqa}else{var Eqa=$CLJS.C(r6);$CLJS.tV(Eqa,w5);c6=$CLJS.D(r6);d6=null;i6=0}j6=0}else break}
for(var J6=$CLJS.A(new $CLJS.S(null,8,5,$CLJS.T,[$CLJS.pm,$CLJS.Bm,$CLJS.rm,$CLJS.Am,$CLJS.QJ,$CLJS.IK,$CLJS.fK,$CLJS.RJ],null)),K6=null,L6=0,M6=0;;)if(M6<L6){var Gqa=K6.$(null,M6);$CLJS.tV(Gqa,z5);M6+=1}else{var N6=$CLJS.A(J6);if(N6){var O6=N6;if($CLJS.re(O6)){var P6=$CLJS.$c(O6),Hqa=$CLJS.ad(O6),Iqa=P6,Jqa=$CLJS.E(P6);J6=Hqa;K6=Iqa;L6=Jqa}else{var Kqa=$CLJS.C(O6);$CLJS.tV(Kqa,z5);J6=$CLJS.D(O6);K6=null;L6=0}M6=0}else break}
for(var Q6=$CLJS.A(new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.TI,$CLJS.JJ,$CLJS.fJ,$CLJS.vJ,$CLJS.Zk],null)),U6=null,V6=0,W6=0;;)if(W6<V6){var Lqa=U6.$(null,W6);$CLJS.tV(Lqa,s5);W6+=1}else{var X6=$CLJS.A(Q6);if(X6){var Y6=X6;if($CLJS.re(Y6)){var Z6=$CLJS.$c(Y6),Mqa=$CLJS.ad(Y6),Nqa=Z6,Oqa=$CLJS.E(Z6);Q6=Mqa;U6=Nqa;V6=Oqa}else{var Pqa=$CLJS.C(Y6);$CLJS.tV(Pqa,s5);Q6=$CLJS.D(Y6);U6=null;V6=0}W6=0}else break}
$CLJS.B1.o(null,$CLJS.I_,function(a,b){var c=$CLJS.Vf($CLJS.I_.g($CLJS.W0(a,b)));return $CLJS.m(c)?$CLJS.cI("Filtered by {0}",$CLJS.G([$CLJS.e2($CLJS.dI("and"),function(){return function f(e){return new $CLJS.Cf(null,function(){for(;;){var g=$CLJS.A(e);if(g){if($CLJS.re(g)){var l=$CLJS.$c(g),n=$CLJS.E(l),q=$CLJS.Ff(n);a:for(var u=0;;)if(u<n){var v=$CLJS.ce(l,u);v=$CLJS.c1.F(a,b,v,$CLJS.e4);q.add(v);u+=1}else{l=!0;break a}return l?$CLJS.If($CLJS.Kf(q),f($CLJS.ad(g))):$CLJS.If($CLJS.Kf(q),null)}q=$CLJS.C(g);
return $CLJS.pf($CLJS.c1.F(a,b,q,$CLJS.e4),f($CLJS.Ad(g)))}return null}},null,null)}(c)}())])):null});
$CLJS.y1.o(null,y5,function(a,b,c,d){c=$CLJS.A(c);var e=$CLJS.C(c);c=$CLJS.D(c);$CLJS.C(c);var f=$CLJS.D(c);return $CLJS.e2(function(){var g=e instanceof $CLJS.N?e.S:null;switch(g){case "and":return $CLJS.dI("and");case "or":return $CLJS.dI("or");default:throw Error(["No matching clause: ",$CLJS.p.g(g)].join(""));}}(),function(){return function n(l){return new $CLJS.Cf(null,function(){for(;;){var q=$CLJS.A(l);if(q){if($CLJS.re(q)){var u=$CLJS.$c(q),v=$CLJS.E(u),x=$CLJS.Ff(v);a:for(var z=0;;)if(z<
v){var B=$CLJS.ce(u,z);B=$CLJS.c1.F(a,b,B,d);x.add(B);z+=1}else{u=!0;break a}return u?$CLJS.If($CLJS.Kf(x),n($CLJS.ad(q))):$CLJS.If($CLJS.Kf(x),null)}x=$CLJS.C(q);return $CLJS.pf($CLJS.c1.F(a,b,x,d),n($CLJS.Ad(q)))}return null}},null,null)}(f)}())});
$CLJS.y1.o(null,w5,function(a,b,c,d){var e=$CLJS.A(c);c=$CLJS.C(e);e=$CLJS.D(e);$CLJS.C(e);var f=$CLJS.D(e);e=$CLJS.xg.h(function(g){return $CLJS.c1.F(a,b,g,d)},f);if($CLJS.F.h($CLJS.E(f),2))switch(f=$CLJS.J(e,0,null),e=$CLJS.J(e,1,null),c=c instanceof $CLJS.N?c.S:null,c){case "\x3d":return $CLJS.cI("{0} equals {1}",$CLJS.G([f,e]));case "!\x3d":return $CLJS.cI("{0} does not equal {1}",$CLJS.G([f,e]));default:throw Error(["No matching clause: ",$CLJS.p.g(c)].join(""));}else switch(f=$CLJS.C(e),e=$CLJS.e2($CLJS.dI("or"),
$CLJS.Ad(e)),c=c instanceof $CLJS.N?c.S:null,c){case "\x3d":return $CLJS.cI("{0} equals any of {1}",$CLJS.G([f,e]));case "!\x3d":return $CLJS.cI("{0} does not equal any of {1}",$CLJS.G([f,e]));default:throw Error(["No matching clause: ",$CLJS.p.g(c)].join(""));}});
$CLJS.y1.o(null,z5,function(a,b,c,d){var e=$CLJS.J(c,0,null);$CLJS.J(c,1,null);var f=$CLJS.J(c,2,null);c=$CLJS.J(c,3,null);f=$CLJS.c1.F(a,b,f,d);a=$CLJS.c1.F(a,b,c,d);e=e instanceof $CLJS.N?e.S:null;switch(e){case "\x3c":return $CLJS.cI("{0} is less than {1}",$CLJS.G([f,a]));case "\x3c\x3d":return $CLJS.cI("{0} is less than or equal to {1}",$CLJS.G([f,a]));case "\x3e":return $CLJS.cI("{0} is greater than {1}",$CLJS.G([f,a]));case "\x3e\x3d":return $CLJS.cI("{0} is greater than or equal to {1}",$CLJS.G([f,
a]));case "starts-with":return $CLJS.cI("{0} starts with {1}",$CLJS.G([f,a]));case "ends-with":return $CLJS.cI("{0} ends with {1}",$CLJS.G([f,a]));case "contains":return $CLJS.cI("{0} contains {1}",$CLJS.G([f,a]));case "does-not-contain":return $CLJS.cI("{0} does not contain {1}",$CLJS.G([f,a]));default:throw Error(["No matching clause: ",$CLJS.p.g(e)].join(""));}});
$CLJS.y1.o(null,$CLJS.eJ,function(a,b,c,d){$CLJS.J(c,0,null);$CLJS.J(c,1,null);var e=$CLJS.J(c,2,null),f=$CLJS.J(c,3,null);c=$CLJS.J(c,4,null);return $CLJS.cI("{0} is between {1} and {2}",$CLJS.G([$CLJS.c1.F(a,b,e,d),$CLJS.c1.F(a,b,f,d),$CLJS.c1.F(a,b,c,d)]))});
$CLJS.y1.o(null,$CLJS.pJ,function(a,b,c,d){$CLJS.J(c,0,null);var e=$CLJS.J(c,1,null),f=$CLJS.J(c,2,null),g=$CLJS.J(c,3,null),l=$CLJS.J(c,4,null),n=$CLJS.J(c,5,null),q=$CLJS.J(c,6,null);c=$CLJS.J(c,7,null);return $CLJS.c1.F(a,b,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.Qj,e,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.eJ,e,f,q,l],null),new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.eJ,e,g,n,c],null)],null),d)});
$CLJS.y1.o(null,s5,function(a,b,c,d){var e=$CLJS.J(c,0,null);$CLJS.J(c,1,null);c=$CLJS.J(c,2,null);a=$CLJS.c1.F(a,b,c,d);e=e instanceof $CLJS.N?e.S:null;switch(e){case "is-null":return $CLJS.cI("{0} is empty",$CLJS.G([a]));case "not-null":return $CLJS.cI("{0} is not empty",$CLJS.G([a]));case "is-empty":return $CLJS.cI("{0} is empty",$CLJS.G([a]));case "not-empty":return $CLJS.cI("{0} is not empty",$CLJS.G([a]));case "not":return $CLJS.cI("not {0}",$CLJS.G([a]));default:throw Error(["No matching clause: ",
$CLJS.p.g(e)].join(""));}});$CLJS.y1.o(null,$CLJS.MK,function(a,b,c,d){$CLJS.J(c,0,null);$CLJS.J(c,1,null);var e=$CLJS.J(c,2,null),f=$CLJS.J(c,3,null);c=$CLJS.J(c,4,null);return $CLJS.cI("{0} is within {1}",$CLJS.G([$CLJS.c1.F(a,b,e,d),$CLJS.p2(f,c).toLowerCase()]))});$CLJS.y1.o(null,$CLJS.EY,function(a,b,c){a=$CLJS.Uf(c);return $CLJS.M.h(a,$CLJS.oJ)});
$CLJS.E1.o(null,$CLJS.EY,function(a,b,c){b=$CLJS.Uf(c);a=$CLJS.M.h(b,$CLJS.iW);b=$CLJS.M.h(b,$CLJS.oJ);return new $CLJS.k(null,2,[$CLJS.y4,$CLJS.oG(a),$CLJS.oJ,b],null)});